import { JournalSubCategorySaver } from "../models/journal";
import { IJournalSubCategory } from "../models/journal";
import { Observable } from "rxjs";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";


@Injectable({
    providedIn: "root"
})
export class JournalSubCategoryApi extends ApiService<IJournalSubCategory, JournalSubCategorySaver, JournalSubCategorySaver> {

    getRoute(): string {
        return "JournalSubCategories";
    }

    restore$(id: number): Observable<void> {
        const url = `${super.getUrl()}/${id}/Restore`;
        return this.http.patch<void>(url, null);
    }
}