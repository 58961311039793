import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationResult } from "@azure/msal-browser";
import { AuthenticationService, ConfigurationService } from "@ramudden/services";
import { Observable } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthenticationService,
        private configurationService: ConfigurationService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.url.includes(this.configurationService.configuration.url)) {
            // We only add a token for our own API
            return next.handle(request);
        }
        return this.authService.acquireTokenSilent$().pipe(
            catchError((error) => {
                // Do nothing - error interceptor will handle this
                return next.handle(request) as Observable<any>;
            }),
            switchMap((result: AuthenticationResult) => {
                const requestClone = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${result.accessToken}`,
                    },
                });
                return next.handle(requestClone);
            }),
        );
    }
}
