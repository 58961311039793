import { INetworkStatus, IPowerStatus } from "../device";
import { IMeasuringPointPowerHistory } from "../measuring-point-power-history";

export interface IDeviceStatusSummary {
    deviceCode: string;
    imei: string;
    qrCode: string;
    lastUpdate: Date;

    currentPower: IPowerStatus;
    powerHistory: IMeasuringPointPowerHistory[];

    currentNetwork: INetworkStatus;
}