import { AnalysisType } from "../measuring-point";
import { IMeasuringPointStatusCreator } from "../measuring-point-status";
import { ILocalizedUserMessage } from "../pinned-data";
import { IProjectNavigator } from "../project";
import { AnalysisVehicleType, VehicleColor } from "../vehicle-analysis-type-configuration";
import { IAlertSummary } from "./alert-summary";

export interface IMeasuringPointStatusSummary {
    measuringPointId: number;
    analysisType: AnalysisType;
    code: string;
    description: string;
    from: string;
    to: string;
    heading?: number;
    lastDeviceLinkValidFrom?: Date;
    lastDeviceLinkValidUntil?: Date;
    currentStatus: IMeasuringPointStatusCreator;
    userMessages: ILocalizedUserMessage[];
    projects: IProjectNavigator[];
    alerts: IAlertSummary[];
    mapIconStateId: number;
    licensePlate?: string;
    vehicleIdentificationNumber?: string;
    vehicleType?: AnalysisVehicleType;
    vehicleColor?: VehicleColor;
}