import { Component, ViewChild, ElementRef, Input, NgZone, ChangeDetectionStrategy, OnChanges, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import * as prism from "prismjs";

@Component({
    selector: "app-code-highlight",
    templateUrl: "./code-highlight.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeHighlightComponent implements OnChanges, AfterViewInit {
    @Input() code: string;
    @Input() language = "json";
    @Input() virtualScroll = false;

    private initialized = false;

    codeLines: string[];

    constructor(
        private readonly zone: NgZone,
        private readonly cd: ChangeDetectorRef) {

    }

    ngOnChanges() {
        this.refreshCode();
    }

    ngAfterViewInit() {
        this.initialized = true;
        this.refreshCode();
    }

    private refreshCode() {
        if (!this.initialized || !this.virtualScroll) return;

        const grammar = prism.languages[this.language];
        this.codeLines = prism.highlight(this.code, grammar, this.language).split("\n").map(x => `${x}\n`);
        this.cd.detectChanges();
    }

    @ViewChild("codeToHighlight", { static: false }) set setCodeToHighlight(codeToHighlight: ElementRef<HTMLPreElement>) {
        if (!codeToHighlight) return;

        this.zone.runOutsideAngular(() => {
            prism.highlightAllUnder(codeToHighlight.nativeElement);
        });
    }
}