import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ViewModelEnum } from "@ramudden/data-access/models/domain-data";
import { IGroup, IGroupMeasuringPoint } from "@ramudden/data-access/models/group";
import { ReportDirection } from "@ramudden/data-access/models/report-type";
import { DomainData, DomainDataService, EventService } from "@ramudden/services";
import {
    ColumnType,
    ColumnVisibility,
    CustomTableComponent,
    FilterType,
    TableColumn,
    TableService,
} from "../table/table.component";

@Component({
    selector: "app-group-measuring-points",
    templateUrl: "./group-measuring-points.component.html",
})
export class GroupMeasuringPointsComponent extends CustomTableComponent<IGroupMeasuringPoint> implements OnChanges {
    @Input() group: IGroup;
    @Input() navigation = true;

    private analysisTypes: ViewModelEnum[];
    isAllForwardSelected: boolean;
    isAllReverseSelected: boolean;
    isAllSumSelected: boolean;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly domainDataService: DomainDataService,
        private readonly eventService: EventService,
        private readonly cd: ChangeDetectorRef,
    ) {
        super("group-measuring-points.component", elementRef, tableService);

        this.reorderable = true;
        this.stretchHeight = true;

        this.addColumn(new TableColumn("includeForwardDirection", "", { sortable: false, type: ColumnType.Icon }), -1);
        this.addColumn(new TableColumn("includeReverseDirection", "", { sortable: false, type: ColumnType.Icon }));
        this.addColumn(new TableColumn("includeSum", "", { sortable: false, type: ColumnType.Icon }));

        const analysisTypeIdColumn = new TableColumn("measuringPoint.analysisTypeId", "", {
            filterType: FilterType.MultiSelect,
            sortable: false,
            type: ColumnType.Icon,
        });
        this.addColumn(analysisTypeIdColumn);
        this.addColumn(
            new TableColumn("measuringPoint.location.code", "locations.code", {
                filterType: FilterType.Text,
                sortable: false,
            }),
        );
        this.addColumn(
            new TableColumn("measuringPoint.code", "measuringPoints.code", {
                filterType: FilterType.Text,
                sortable: false,
            }),
        );
        this.addColumn(
            new TableColumn("measuringPoint.description", "general.description", {
                filterType: FilterType.Text,
                sortable: false,
                visibility: ColumnVisibility.HideCompact,
            }),
        );
        this.addColumn(
            new TableColumn("measuringPoint.fromTo", "measuringPoints.fromTo", { filterType: FilterType.None }),
        );

        // const organizationColumn = new TableColumn("measuringPoint.location.owner", "general.organization", { filterType: FilterType.MultiSelect, sortable: true, visibility: ColumnVisibility.HideCompact, width: 300, resizable: false, hidden: !this.globalEventsService.hasMultipleOrganizations(), displayDropdownFilter: true });
        // this.addColumn(organizationColumn);

        // Load Organizations for drop-down filter
        // this.services.mapDataService.subscribeToOrganizations(this.mapDataServiceFilterKey, organizations => {
        //     organizationColumn.filterOptions = this.services.primeComponentService.createDropdownList(organizations, x => x.name, x => x.name, false);
        // });

        this.domainDataService.get(DomainData.AnalysisType).then((analysisTypes) => {
            analysisTypeIdColumn.filterOptions = analysisTypes;
            this.analysisTypes = analysisTypes;
        });

        this.services.mapDataService.subscribeToEditGroupUpdate(this.mapDataServiceKey, (updatedGroup: IGroup) => {
            if (updatedGroup && this.group && updatedGroup.id === this.group.id) {
                this.group = updatedGroup;
                this.reload();
                this.cd.detectChanges();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const groupChange = changes["group"];
        if (groupChange) {
            this.reload(false);
        }

        super.ngOnChanges(changes);
    }

    onTableSet() {
        const onFilterSubscription = this.table.onFilter.subscribe(() => {
            this.updateHeaderCheckboxes();
        });

        this.subscriptionManager.add("onFilterSubscription", onFilterSubscription);
    }

    onSetData() {
        this.updateHeaderCheckboxes();
    }

    reorder() {
        this.table.value.forEach((mp, index) => {
            mp.sortOrder = index + 1;
            this.services.mapDataService.editGroup.measuringPoints.find(
                (x) => x.measuringPoint.code === mp.measuringPoint.code,
            ).sortOrder = index + 1;
        });
    }
    alphabeticalSorting() {
        this.table.sortField = "measuringPoint.code";
        this.table.sortOrder = 1;
        this.table.sortSingle();
        this.reorder();
        this.table.sortField = undefined;
        this.table.sortOrder = 0;
    }

    canLoad(): boolean {
        return !!this.group;
    }

    loadTableRows() {
        const data = this.group.measuringPoints.sortBy((x) => x.sortOrder);
        this.setData(data);
    }

    getMeasuringPointAnalysisTypeTranslation(analysisTypeId: string): string {
        if (!this.analysisTypes) return null;

        const analysisType = this.analysisTypes.find((x) => x.value === analysisTypeId);
        return analysisType ? analysisType.label : "";
    }

    updateInGroup(groupMeasuringPoint: IGroupMeasuringPoint, direction?: string) {
        if (direction === ReportDirection.Forward) this.updateIsAllForwardSelected();
        if (direction === ReportDirection.Reverse) this.updateIsAllReverseSelected();
        if (direction === ReportDirection.Sum) this.updateIsAllSumSelected();

        this.services.mapDataService.updateGroupMeasuringPointsInEditGroup([groupMeasuringPoint]);
    }

    updateHeaderCheckboxes() {
        this.updateIsAllForwardSelected();
        this.updateIsAllReverseSelected();
        this.updateIsAllSumSelected();
    }

    updateIsAllForwardSelected() {
        this.isAllForwardSelected =
            this.getCurrentData().length && !this.getCurrentData().find((x) => !x.includeForwardDirection);
    }

    updateIsAllReverseSelected() {
        this.isAllReverseSelected =
            this.getCurrentData().length && !this.getCurrentData().find((x) => !x.includeReverseDirection);
    }

    updateIsAllSumSelected() {
        this.isAllSumSelected = this.getCurrentData().length && !this.getCurrentData().find((x) => !x.includeSum);
    }

    bulkSelectChanged(direction: string) {
        if (this.eventService.isBulkUpdating()) return;

        this.eventService.setIsBulkUpdating(true);

        if (direction === ReportDirection.Forward) this.handleIsAllForwardChanged();
        if (direction === ReportDirection.Reverse) this.handleIsAllReverseChanged();
        if (direction === ReportDirection.Sum) this.handleIsAllSumChanged();

        this.eventService.setIsBulkUpdating(false);
    }

    handleIsAllForwardChanged() {
        this.getCurrentData().forEach((x) => {
            if (x.includeForwardDirection === this.isAllForwardSelected) return;

            x.includeForwardDirection = this.isAllForwardSelected;
        });

        this.services.mapDataService.updateGroupMeasuringPointsInEditGroup(this.data);
    }

    handleIsAllReverseChanged() {
        this.getCurrentData().forEach((x) => {
            if (x.includeReverseDirection === this.isAllReverseSelected) return;

            x.includeReverseDirection = this.isAllReverseSelected;
        });

        this.services.mapDataService.updateGroupMeasuringPointsInEditGroup(this.data);
    }

    handleIsAllSumChanged() {
        this.getCurrentData().forEach((x) => {
            if (x.includeSum === this.isAllSumSelected) return;
            x.includeSum = this.isAllSumSelected;
        });

        this.services.mapDataService.updateGroupMeasuringPointsInEditGroup(this.data);
    }
}
