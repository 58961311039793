<!-- No extra whitespace / linebreaks, pre is literal -->
<ng-container *ngIf="!virtualScroll; else virtualScrollTemplate">
    <pre *ngIf="code"
         class="code"
         #codeToHighlight><code [ngClass]="'language-' + language">{{code}}</code></pre>
</ng-container>

<ng-template #virtualScrollTemplate>
    <pre
         class="code"><code [ngClass]="'language-' + language"><cdk-virtual-scroll-viewport *ngIf="codeLines" [ngStyle]="{'height': virtualScroll + 'px'}" [itemSize]="19"><ng-container *cdkVirtualFor="let codeLine of codeLines"><span style="all:unset;" [innerHTML]="codeLine | safeHtml"></span></ng-container></cdk-virtual-scroll-viewport></code></pre>
</ng-template>