// Rights are defined in the backend and are used to determine what the user can see and do in the frontend
export enum BackendRights {
    // Alerts
    ViewAlertConfigurationGroup = "viewAlertConfigurationGroup",
    EditAlertConfigurationGroup = "editAlertConfigurationGroup",
    DeleteAlertConfigurationGroup = "deleteAlertConfigurationGroup",
    EditAlertConfiguration = "editAlertConfiguration",
    DeleteAlertConfiguration = "deleteAlertConfiguration",
    ViewAlertConfigurationTemplate = "viewAlertConfigurationTemplate",
    EditAlertConfigurationTemplate = "editAlertConfigurationTemplate",
    DeleteAlertConfigurationTemplate = "deleteAlertConfigurationTemplate",
    ImportAlertConfigurationTemplate = "importAlertConfigurationTemplate",

    // Applications
    ViewApplication = "viewApplication",
    EditApplication = "editApplication",
    DeleteApplication = "deleteApplication",

    // Audittrails
    ViewInstallationAuditTrail = "viewInstallationAuditTrail",
    ViewOrganizationAuditTrail = "viewOrganizationAuditTrail",
    ViewDeviceAuditTrail = "viewDeviceAuditTrail",
    ViewAssignmentAuditTrail = "viewAssignmentAuditTrail",
    ViewProjectAuditTrail = "viewProjectAuditTrail",

    ViewAdminOnlyAuditTrail = "viewAdminOnlyAuditTrail",

    // Attachments
    ViewAttachment = "viewAttachment",
    EditAttachment = "editAttachment",
    DeleteAttachment = "deleteAttachment",

    // Device Commands
    ViewDeviceCommand = "viewDeviceCommand",
    EditDeviceCommand = "editDeviceCommand",

    // Device Display Event
    ViewDeviceDisplayEvent = "viewDeviceDisplayEvent",
    EditDeviceDisplayEvent = "editDeviceDisplayEvent",
    DeleteDeviceDisplayEvent = "deleteDeviceDisplayEvent",

    ViewCctv = "viewCctv",

    // Scenarios
    ViewScenario = "viewScenario",
    EditScenario = "editScenario",
    DeleteScenario = "deleteScenario",

    // Device Links
    ViewDeviceLink = "viewDeviceLink",
    EditDeviceLink = "editDeviceLink",

    // Devices
    ViewDevice = "viewDevice",
    CreateDevice = "createDevice",
    EditDevice = "editDevice",
    DeleteDevice = "deleteDevice",
    ViewDeviceHardwareConfiguration = "viewDeviceHardwareConfiguration",
    EditDeviceHardwareConfiguration = "editDeviceHardwareConfiguration",
    UploadPulsesInDevice = "uploadPulsesInDevice",
    ManageDeviceTechnicalConfiguration = "manageDeviceTechnicalConfiguration",

    // DeviceDepots
    ViewDeviceDepot = "viewDeviceDepot",
    EditDeviceDepot = "editDeviceDepot",
    DeleteDeviceDepot = "deleteDeviceDepot",

    // DeviceStatuses
    ViewDeviceStatus = "viewDeviceStatus",
    EditDeviceStatus = "editDeviceStatus",
    UploadDeviceStatus = "uploadDeviceStatus",

    // FillInData
    ViewFillInData = "viewFillInData",

    // Groups
    ViewGroup = "viewGroup",
    EditGroup = "editGroup",
    DeleteGroup = "deleteGroup",

    // Imported Shared Keys
    ViewImportedSharedKey = "viewImportedSharedKey",
    EditImportedSharedKey = "editImportedSharedKey",
    DeleteImportedSharedKey = "deleteImportedSharedKey",

    // Journals
    ViewDeviceJournal = "viewDeviceJournal",
    ViewInstallationJournal = "viewInstallationJournal",
    ViewAssignmentJournal = "viewAssignmentJournal",
    ViewProjectJournal = "viewProjectJournal",
    ViewOrganizationJournal = "viewOrganizationJournal",

    EditDeviceJournal = "editDeviceJournal",
    EditInstallationJournal = "editInstallationJournal",
    EditAssignmentJournal = "editAssignmentJournal",
    EditProjectJournal = "editProjectJournal",
    EditOrganizationJournal = "editOrganizationJournal",

    DeleteDeviceJournal = "deleteDeviceJournal",
    DeleteInstallationJournal = "deleteInstallationJournal",
    DeleteAssignmentJournal = "deleteAssignmentJournal",
    DeleteProjectJournal = "deleteProjectJournal",
    DeleteOrganizationJournal = "deleteOrganizationJournal",

    ManageAdminJournal = "manageAdminJournal",

    // Locations
    EditLocation = "editLocation",
    DeleteLocation = "deleteLocation",

    // Measuring Point Aggregations
    ViewMeasuringPointAggregation = "viewMeasuringPointAggregation",
    EditMeasuringPointAggregation = "editMeasuringPointAggregation",

    // Measuring Points
    ViewMeasuringPoint = "viewMeasuringPoint",
    EditMeasuringPoint = "editMeasuringPoint",
    DeleteMeasuringPoint = "deleteMeasuringPoint",
    UploadPulsesInMeasuringPoint = "uploadPulsesInMeasuringPoint",

    // MeasuringPointStatuses
    ViewMeasuringPointStatus = "viewMeasuringPointStatus",
    UploadMeasuringPointStatus = "uploadMeasuringPointStatus",

    // Organizations
    ViewOrganization = "viewOrganization",
    CreateOrganization = "createOrganization",
    EditOrganization = "editOrganization",
    DeleteOrganization = "deleteOrganization",

    // Projects
    ViewProject = "viewProject",
    EditProject = "editProject",
    DeleteProject = "deleteProject",

    // Releases
    ViewRelease = "viewRelease",
    EditRelease = "editRelease",

    // Report Favorites
    ViewReportFavorite = "viewReportFavorite",
    EditReportFavorite = "editReportFavorite",
    DeleteReportFavorite = "deleteReportFavorite",

    // Report History
    ViewReport = "viewReport",
    RenderReport = "renderReport",
    DownloadReport = "downloadReport",
    ViewReportHistory = "viewReportHistory",

    // Rules
    ViewRule = "viewRule",
    EditRule = "editRule",

    // Shared Keys
    ViewSharedKey = "viewSharedKey",
    EditSharedKey = "editSharedKey",
    DeleteSharedKey = "deleteSharedKey",

    // Uploads
    ViewUpload = "viewUpload",
    ViewUploadAnalysis = "viewUploadAnalysis",
    ViewUploadPulses = "viewUploadPulses",
    DeleteUpload = "deleteUpload",
    ViewBulkUpload = "viewBulkUpload",

    // Vehicles
    ViewVehicle = "viewVehicle",
    ViewVehicleDayOverviews = "viewVehicleDayOverviews",
    DeleteVehicle = "deleteVehicle",

    // Vms Images
    ViewVmsImages = "viewVmsImages",
    EditVmsImages = "editVmsImages",
    DeleteVmsImages = "deleteVmsImages",

    // Symbols
    ViewSymbols = "viewSymbols",
    EditSymbols = "editSymbols",
    DeleteSymbols = "deleteSymbols",

    // SymbolSets
    ViewSymbolSets = "viewSymbolSets",
    EditSymbolSets = "editSymbolSets",
    DeleteSymbolSets = "deleteSymbolSets",

    // Expenses
    ViewExpenses = "viewExpenses",
    EditExpenses = "editExpenses",
    DeleteExpenses = "deleteExpenses",

    ViewCredit = "viewCredit",
    EditCredit = "editCredit",
    DeleteCredit = "deleteCredit",

    ViewAnalytics = "viewAnalytics",

    // Assignments
    ViewAssignment = "viewAssignment",
    CreateAssignment = "createAssignment",
    EditAssignment = "editAssignment",
    DeleteAssignment = "deleteAssignment",

    // Worklists
    ViewWorklistItems = "viewWorklistItems",

    // Tasks
    ViewTask = "viewTask",
    EditTask = "editTask",
    DeleteTask = "deleteTask",

    // Parking bans
    ViewParkingBan = "viewParkingBan",
    EditParkingBan = "editParkingBan",
    DeleteParkingBan = "deleteParkingBan",

    // Parking ban exceptions
    ViewParkingBanException = "viewParkingBanException",
    EditParkingBanException = "editParkingBanException",
    DeleteParkingBanException = "deleteParkingBanException",

    // Planning
    ViewPlanning = "viewPlanning",
    EditPlanning = "editPlanning",
    DeletePlanning = "deletePlanning",

    // Workers
    ViewWorker = "viewWorker",
    EditWorker = "editWorker",
    DeleteWorker = "deleteWorker",

    // SafetyQuestions
    ViewSafetyQuestion = "viewSafetyQuestion",
    EditSafetyQuestion = "editSafetyQuestion",
    DeleteSafetyQuestion = "deleteSafetyQuestion",
    PostAnsweredSafetyQuestions = "postAnsweredSafetyQuestions",

    // Users
    ViewUser = "viewUser",
    EditUser = "editUser",
    DeleteUser = "deleteUser",

}