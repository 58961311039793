<div *ngIf="visible"
     class="m-galleria">

    <div class="m-galleria-actions p-16">
        <app-icon-button icon="download"
                         class="m-btn m-btn-square"
                         fill="white"
                         (click)="download()">
        </app-icon-button>

        <!-- <app-icon-button icon="share"
                         class="m-btn m-btn-square"
                         fill="white"
                         (click)="copyToClipboard()">
        </app-icon-button> -->

        <app-icon-button icon="close"
                         class="m-btn m-btn-square"
                         fill="white"
                         (click)="close()">
        </app-icon-button>
    </div>

    <div class="m-galleria-body-wrapper flex align-center">
        <div class="p-16">
            <app-icon-button icon="gallery-previous"
                             class="m-btn m-btn-square"
                             fill="white"
                             (click)="previous()"
                             *ngIf="hasPrevious">
            </app-icon-button>
        </div>

        <div class="m-galleria-body"
             *ngIf="currentImage">

            <h3 class="m-galleria-title p-16 text-center">{{currentImage.title}}</h3>

            <div class="m-galleria-content">
                <img *ngIf="currentImage.previewer === 0"
                     [src]="currentImage.source"
                     [alt]="currentImage.alt" />

                <pdf-viewer *ngIf="currentImage.previewer === 1"
                            [src]="currentImage.source"
                            [show-all]="true"></pdf-viewer>

                <video *ngIf="currentImage.previewer === 2"
                       preload="metadata"
                       controls>
                    <source [src]="currentImage.source"
                            type="video/mp4">
                </video>
            </div>
        </div>

        <div class="p-16">
            <app-icon-button icon="gallery-next"
                             class="m-btn m-btn-square"
                             fill="white"
                             (click)="next()"
                             *ngIf="hasNext">
            </app-icon-button>
        </div>
    </div>

    <div class="m-galleria-navigator p-16"
         *ngIf="navigator">
        <ng-container *ngFor="let image of navigatorImages">
            <div class="m-galleria-navigator-item flex flex-column"
                 (click)="navigateToImage(image)">
                <img *ngIf="image"
                     [src]="image.previewImageSrc"
                     [alt]="image.alt" />

                <span class="m-galleria-navigator-title mt-8">{{image.title}}</span>
            </div>
        </ng-container>
    </div>
</div>