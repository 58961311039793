import { Component, Input, TemplateRef } from "@angular/core";

@Component({
    selector: "app-show-more",
    templateUrl: "./show-more.component.html"
})
export class ShowMoreComponent {
    @Input() template: TemplateRef<any>;
    @Input() list: Array<any>;
    @Input() limit = 3;

    showAll = false;

    toggle() {
        this.showAll = !this.showAll;
    }
}