import { environment } from "@env/environment";

class TimerResult {
    key: string;
    count: number;
    total: number;
    min: number;
    max: number;
    avg: number;
}

export class TimerUtils {
    static times: { [key: string]: number[] } = {};

    static time(key: string, func: () => any) {
        if (environment.production) {
            func();
            return;
        }

        const now = new Date().getTime();
        func();
        const end = new Date().getTime();
        TimerUtils.addTime(key, end - now);
    }

    static print(clear = true) {
        if (environment.production) return;

        const records = new Array<TimerResult>();
        for (const key in TimerUtils.times) {
            if (!TimerUtils.times.hasOwnProperty(key)) continue;

            const times = TimerUtils.times[key].sort();
            const min = times.takeFirstOrDefault();
            const max = times.takeLastOrDefault();
            const total = times.sum();
            const count = times.length;
            const avg = total / count;

            records.push({
                key: key,
                count: count,
                total: total,
                min: min,
                max: max,
                avg: avg,
            });
        }

        console.table(records);

        if (clear) {
            TimerUtils.times = {};
        }
    }

    static addTime(key: string, time: number) {
        if (!TimerUtils.times[key]) {
            TimerUtils.times[key] = new Array<number>();
        }

        TimerUtils.times[key].push(time);
    }
}
