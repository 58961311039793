<div *ngIf="value && value > 0"
     class="icon"
     tooltipEvent="hover"
     [tooltipPosition]="tooltipPosition"
     [ngClass]="divClass"
     [ngStyle]="divStyle"
     [pTooltip]="tooltip"
     pBadge
     [value]="value"
     [tooltipDisabled]="!tooltip || tooltipDisabled">
    <svg *ngIf="href"
         [ngClass]="ngClass"
         [ngStyle]="style">
        <use [attr.href]="href"></use>
    </svg>
</div>

<div *ngIf="!value || (value && value == 0)"
     class="icon"
     tooltipEvent="hover"
     [tooltipPosition]="tooltipPosition"
     [ngClass]="divClass"
     [ngStyle]="divStyle"
     [pTooltip]="tooltip"
     [tooltipDisabled]="!tooltip || tooltipDisabled">
    <svg *ngIf="href"
         [ngClass]="ngClass"
         [ngStyle]="style">
        <use [attr.href]="href"></use>
    </svg>
</div>