<ng-container [formGroup]="parentFormGroup">

    <ng-template #editingAttachmentTemplate>
        <h3>{{"journal.rotateImage" | translate}}</h3>

        <div *ngIf="editAttachmentFormGroup"
             class="gallery-edit-image">

            <a (click)="stopEditingAttachmentImage()"
               class="m-btn m-btn-back"
               title="Back">
                {{"general.return" | translate}}
            </a>

            <div class="row">
                <div class="col-11 text-center gallery-edit-image-title">
                    <h2>{{editAttachmentFormGroup.get('name').value}}</h2>
                </div>

                <div class="col-11 text-center">
                    <img [attr.src]="editAttachmentFormGroup.get('preview').value | safeHtml" />
                </div>

                <div class="col-1">
                    <app-icon-button icon="rotate-right"
                                     (click)="rotatePhoto()"></app-icon-button>

                    <app-icon-button icon="rotate-left"
                                     (click)="rotatePhoto(false)"></app-icon-button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-container *ngIf="!editAttachmentFormGroup; else editingAttachmentTemplate">

        <div class="m-form-group"
             *ngIf="!readonly">
            <label for="fileInput"
                   class="p-inputtext m-input-file m-input-group-w-icon">

                <span class="label ml-16">{{'general.chooseFileOrDragItHere' | translate}}</span>

                <app-svg-icon icon="upload">
                </app-svg-icon>

                <input type="file"
                       id="fileInput"
                       #file
                       multiple
                       (change)="addAttachmentFormsFromInput(file)" />
            </label>
        </div>

        <!-- Requires overflow -->
        <div formGroupName="attachments"
             appFileDragAndDrop
             (fileDropped)="onFileDropped($event)"
             [ngClass]="['file-drop-zone', (attachmentForms.length === 0 ? 'drop-image' : '')]"
             style="position:relative">
            <div class="file-drop-zone-overlay file-drop-zone-overlay-image">

            </div>
            <div class="row mt-16"
                 *ngFor="let attachmentForm of attachmentForms.controls; let attachmentIndex = index"
                 formGroupName="{{attachmentIndex}}">

                <div class="col-4">
                    <div class="m-attachment-image-wrapper">
                        <div class="m-attachment-image-actions">
                            <app-icon-button *ngIf="!readonly"
                                             icon="delete"
                                             text="form.delete"
                                             (click)="deleteAttachment(attachmentIndex)">
                            </app-icon-button>

                            <ng-container *ngIf="attachmentForm.get('url').value">
                                <app-icon-button icon="download"
                                                 text="general.download"
                                                 (click)="downloadAttachment(attachmentForm)">
                                </app-icon-button>
                            </ng-container>
                        </div>

                        <img [attr.src]="(attachmentForm.get('preview').value | safeHtml)"
                             (click)="editAttachmentImage(attachmentForm)"
                             [ngStyle]="{'cursor': attachmentForm.get('isPreviewable').value ? 'pointer' : 'unset'}" />
                    </div>
                </div>

                <div class="col-8">
                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': attachmentForm.get('typeId')?.showErrors }">
                        <label class="col-4"
                               for="typeId{{attachmentIndex}}">
                            {{"general.type" | translate}}
                        </label>

                        <div class="col-8">
                            <p-dropdown id="typeId{{attachmentIndex}}"
                                        appAutofocus
                                        formControlName="typeId"
                                        [options]="attachmentTypes"
                                        [style]="{'width':'100%'}"
                                        [autoDisplayFirst]="false"
                                        appendTo="body"
                                        placeholder="{{'attachments.selectType' | translate }}"
                                        (onChange)="markAttachmentAsEdited(attachmentForm)"
                                        #typeInput></p-dropdown>

                            <app-form-validation [control]="attachmentForm.get('typeId')"
                                                 [input]="typeInput">
                            </app-form-validation>
                        </div>
                    </div>

                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': attachmentForm.get('name')?.showErrors }">

                        <label class="col-4"
                               for="name{{attachmentIndex}}">
                            {{"general.name" | translate}}
                        </label>

                        <div class="col-8">
                            <input type="text"
                                   id="name{{attachmentIndex}}"
                                   class="p-inputtext"
                                   formControlName="name"
                                   (change)="markAttachmentAsEdited(attachmentForm)"
                                   #nameInput />

                            <app-form-validation [control]="attachmentForm.get('name')"
                                                 [input]="nameInput">
                            </app-form-validation>
                        </div>
                    </div>

                    <div class="m-form-group row"
                         [ngClass]="{ 'has-error': attachmentForm.get('description').showErrors }">

                        <label class="col-4"
                               for="description{{attachmentIndex}}">
                            {{"general.description" | translate}}
                        </label>

                        <div class="col-8">
                            <input type="text"
                                   id="description{{attachmentIndex}}"
                                   class="p-inputtext"
                                   formControlName="description"
                                   (onChange)="markAttachmentAsEdited(attachmentForm)"
                                   #descriptionInput />

                            <app-form-validation [control]="attachmentForm.get('description')"
                                                 [input]="descriptionInput">
                            </app-form-validation>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>