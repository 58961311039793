import { Injectable } from "@angular/core";
import { ProjectTreeUtils } from "@ramudden/core/utils";
import { IProject } from "@ramudden/data-access/models/project";
import { IOrganization } from "@ramudden/data-access/models/user";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ProjectsService {
    private _selectedOrganizationId: number;
    get selectedOrganizationId(): number {
        return this._selectedOrganizationId;
    }
    set selectedOrganizationId(value: number) {
        this._selectedOrganizationId = value;
    }

    private _selectedProjectId: number;
    get selectedProjectId(): number {
        return this._selectedProjectId;
    }
    set selectedProjectId(value: number) {
        this._selectedProjectId = value;
    }

    private _showOnlyActiveProjects: boolean;
    get showOnlyActiveProjects(): boolean {
        return this._showOnlyActiveProjects;
    }
    set showOnlyActiveProjects(value: boolean) {
        this._showOnlyActiveProjects = value;
    }

    private _showOnlyMeasuringPointsProjects: boolean;
    get showOnlyMeasuringPointsProjects(): boolean {
        return this._showOnlyMeasuringPointsProjects;
    }
    set showOnlyMeasuringPointsProjects(value: boolean) {
        this._showOnlyMeasuringPointsProjects = value;
    }

    private _showOnlyAssignmentProjects: boolean;
    get showOnlyAssignmentProjects(): boolean {
        return this._showOnlyAssignmentProjects;
    }
    set showOnlyAssignmentProjects(value: boolean) {
        this._showOnlyAssignmentProjects = value;
    }

    public projectChangeSubject$: Subject<IProject> = new Subject<IProject>();

    notifyProjectChanged(project: IProject) {
        this.projectChangeSubject$.next(project);
    }

    public organizationChangeSubject$: Subject<IOrganization> = new Subject<IOrganization>();

    notifyOrganizationChanged(organization: IOrganization) {
        this.organizationChangeSubject$.next(organization);
    }

    filterActiveProjects(projects: IProject[]): IProject[] {
        if (!projects || projects.length === 0) return [];

        return projects.filter((x) => ProjectTreeUtils.isActiveProject(x));
    }
}
