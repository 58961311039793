import { Pipe, PipeTransform } from "@angular/core";
import { IMessage } from "@ramudden/data-access/models/message";
import { NotificationCategory } from "@ramudden/data-access/models/notification";
import { DomainDataService } from "@ramudden/services";

@Pipe({ name: "translateMessage" })
export class TranslateMessagePipe implements PipeTransform {
    constructor(private readonly domainDataService: DomainDataService) {}

    transform(message: IMessage, category: NotificationCategory = NotificationCategory.General): string {
        let template = this.domainDataService.translateEnum("messageTemplate", message.messageTemplateId);
        if (!template) {
            // domaindata is not loaded... Very strange
            return template;
        }

        if (category === NotificationCategory.Alert) {
            template = template.replace(`{infoAlerts}`, message.parameters.infoAlerts.toString());
            template = template.replace(`{warningAlerts}`, message.parameters.warningAlerts.toString());
            template = template.replace(`{errorAlerts}`, message.parameters.errorAlerts.toString());
        } else {
            for (const key in message.parameters.values) {
                template = template.replace(`{${key}}`, message.parameters.values[key]);
            }
        }
        return template;
    }
}
