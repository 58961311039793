<!-- https://www.primefaces.org/primeng/#/tooltip -->
<button [ngClass]="_styleClass"
        [ngStyle]="style"
        (click)="handleClick($event)"
        [id]="buttonId"
        #anchor>

    <ng-template #labelTemplate>
        <span *ngIf="!label"
              class="sr-only">
            {{text | translate}}
        </span>

        <span *ngIf="label"
              style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
            {{label | translate}}
        </span>
    </ng-template>

    <ng-container *ngIf="labelPosition === 'left'">
        <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
    </ng-container>

    <app-loading-spinner *ngIf="showSpinner">
    </app-loading-spinner>

    <app-svg-icon *ngIf="icon"
                  tooltip="{{text | translate}}"
                  [tooltipDisabled]="disabled"
                  [tooltipPosition]="tooltipPosition"
                  [icon]="icon"
                  [fill]="fill"
                  [ngClass]="iconClass"
                  [ngStyle]="iconStyle"
                  [divClass]="_iconDivClass"
                  [divStyle]="iconDivStyle">
    </app-svg-icon>

    <ng-container *ngIf="labelPosition === 'right'">
        <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
    </ng-container>
</button>
