import { Pipe, PipeTransform } from "@angular/core";
import { SelectItem } from "primeng/api";

@Pipe({
    name: "shortenMinutesString"
})
export class ShortenMinutesStringPipe implements PipeTransform {

    transform(entryString: SelectItem, language: string): string {
        if (entryString.label.contains("minutes")) {
            return entryString.label.replace("minutes", "min");
        } else if (entryString.label.contains("minuten")) {
            return entryString.label.replace("minuten", "min");
        }

        return entryString.label;
    }
}
