import { Component, Input, OnChanges, SimpleChanges, OnDestroy, AfterViewInit, EventEmitter, Output, ViewChild, ElementRef, NgZone } from "@angular/core";
import { ChartConfiguration, Chart, registerables } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-moment";


// This is required because when copying chart image background will be transparent by default (won't look nicely when pasted)
const customPluginToColorCanvasBackground = {
    id: "custom_canvas_background_color",
    beforeDraw: (chart) => {
        if (chart.config.options.backgroundColor) { // only if backgroundColor is set so won't have background on every chart in app
            const ctx = chart.canvas.getContext("2d");
            ctx.save();
            ctx.globalCompositeOperation = "destination-over";
            ctx.fillStyle = chart.config.options.backgroundColor;
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    }
};

Chart.register(...registerables, zoomPlugin, customPluginToColorCanvasBackground);


export class ChartClickEvent {
    constructor(
        readonly originalEvent: MouseEvent,
        readonly element: {},
        readonly dataset: {}[]
    ) { }
}

@Component({
    selector: "app-chart",
    templateUrl: "./signco-chart.component.html"
})
export class ChartComponent implements OnChanges, OnDestroy, AfterViewInit {
    canvas: HTMLCanvasElement;
    @ViewChild("canvas", { static: false }) set setCanvas(canvas: ElementRef<HTMLCanvasElement>) {
        if (!canvas) {
            this.canvas = null;
            this.clear();
            return;
        }

        this.canvas = canvas.nativeElement;
        this.initChart();
    }

    @Input() configuration: ChartConfiguration;
    @Input() width: string;
    @Input() height: string;
    @Input() responsive = true;
    @Input() showLoadingSpinner = true;

    @Output() chartClick = new EventEmitter<ChartClickEvent>();

    chart: Chart;
    canPan: boolean;

    constructor(private readonly zone: NgZone) {
    }

    ngAfterViewInit() {
        this.initChart();
    }

    ngOnChanges(changes: SimpleChanges) {
        const configurationChange = changes["configuration"];
        if (configurationChange) {
            this.initChart();
        }
    }

    private initChart() {
        if (!this.canvas || !this.configuration) return;

        this.clear();

        if (!this.configuration.options) {
            this.configuration.options = {};
        }

        this.configuration.options.responsive = this.responsive;

        // allows chart to resize in responsive mode
        if (this.configuration.options.responsive && (this.height || this.width)) {
            this.configuration.options.maintainAspectRatio = false;
        }

        const panOptions = this.configuration.options &&
            this.configuration.options.plugins &&
            this.configuration.options.plugins.zoom &&
            this.configuration.options.plugins.zoom.pan;

        setTimeout(() => {
            this.canPan = panOptions && panOptions.enabled;
        });

        this.zone.runOutsideAngular(() => {
            this.chart = new Chart(this.canvas, this.configuration);
        });
    }

    refresh() {
        // Don't update chart if ctx hasn't been instantiated yet.
        if (!this.chart || !this.chart.ctx) return;

        this.zone.runOutsideAngular(() => {
            this.chart.render();
        });
    }

    update() {
        this.zone.runOutsideAngular(() => {
            this.chart.update();
        });
    }

    clear() {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
    }

    ngOnDestroy() {
        this.clear();
    }

    handleCanvasClick(event: MouseEvent) {
        if (!this.chart) return;

        const element = this.chart.getElementsAtEventForMode(event, "nearest", { intersect: true }, false);
        const dataset = this.chart.getElementsAtEventForMode(event, "dataset", { intersect: true }, false);

        this.chartClick.emit(
            new ChartClickEvent(
                event,
                element ? element[0] : null,
                dataset
            ));
    }
}
