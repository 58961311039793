<div (click)="stop($event)"
     id="{{column.table.key + column.field + 'Filter'}}"
     *ngIf="column && column.filterType > 0"
     [ngClass]="{'is-outside-table': isOutsideTable}">

    <app-svg-icon *ngIf="column.filterType !== 6 && !isOutsideTable"
                  id="{{column.table.key + column.field + 'FilterIcon'}}"
                  icon="filter"
                  (click)="toggleExpanded($event)">
    </app-svg-icon>

    <div *ngIf="column.filterType === 1"
         class="m-column-search">
        <input type="text"
               id="{{column.table.key + column.field + 'FilterInput'}}"
               class="p-inputtext"
               [(ngModel)]="currentFilter"
               placeholder="{{column.header | translate}}"
               (focus)="expanded = true;"
               (blur)="expanded = false;"
               (appDebounce)="filter($event, 'contains')"
               [debounceTime]="400"
               (keydown)="validateKeypress($event)"
               (keyup)="$event.stop()"
               #textInput>
    </div>

    <div *ngIf="column.filterType === 2 && expanded"
         class="m-column-slider"
         [ngStyle]="expanded ? {'display': 'block'} : {}">

        <div class="m-slider-label">
            <input type="number"
                   id="{{column.table.key + column.field + 'FilterSliderInput'}}"
                   placeholder="{{column.header | translate}}"
                   class="hide-spinner"
                   [ngModel]="currentFilter"
                   (appDebounce)="filterNumber($event)"
                   [debounceTime]="400"
                   (click)="stop($event)">

            <app-svg-icon icon="close"
                          id="{{column.field + 'ClearFilter'}}"
                          (click)="clearFilter()"
                          [ngStyle]="{'cursor': 'pointer'}"
                          *ngIf="currentFilter">
            </app-svg-icon>
        </div>

        <p-slider [(ngModel)]="currentFilter"
                  id="{{column.table.key + column.field + 'FilterSlider'}}"
                  [min]="column.filterMin || 0"
                  [max]="column.filterMax || 100000"
                  [step]="column.step || 1"
                  (appDebounce)="filterNumber($event)"
                  [debounceTime]="400">
        </p-slider>
    </div>

    <p-calendar *ngIf="column.filterType === 3"
                id="{{column.table.key + column.field + 'FilterCalendar'}}"
                class="m-column-calendar"
                appendTo="body"
                [(ngModel)]="currentFilter"
                [selectionMode]="column.filterMatchMode === 'inside' ? 'range' : 'single'"
                autoWidth="false"
                readonlyInput="true"
                showButtonBar="true"
                placeholder="{{column.header | translate}}"
                [showIcon]="true"
                [hourFormat]="column.table.calendarSettings.hourFormat"
                [dateFormat]="column.table.calendarSettings.dateFormat"
                [monthNavigator]="false"
                [yearNavigator]="false"
                [yearRange]="primeComponentService.calendarYearRange"
                (click)="stop($event)"
                (onSelect)="filterDate(currentFilter)"
                (onClearClick)="clearFilter()">
    </p-calendar>

    <p-dropdown *ngIf="column.filterType === 4 && column.filterOptions"
                id="{{column.table.key + column.field + 'FilterDropdown'}}"
                class="m-column-dropdown"
                appendTo="body"
                placeholder="{{column.header | translate}}"
                [autoDisplayFirst]="false"
                [options]="column.filterOptions"
                [filter]="column.displayDropdownFilter"
                [(ngModel)]="currentFilter"
                [style]="{'width':'100%'}"
                [showClear]="isOutsideTable"
                (onChange)="filter($event.value, 'equals')"
                (onHide)="close()"
                (click)="toggleExpanded(); stop($event);">
    </p-dropdown>

    <p-multiSelect *ngIf="column.filterType === 5 && column.filterOptions"
                   id="{{column.table.key + column.field + 'FilterMultiSelect'}}"
                   class="m-column-multiselect"
                   appendTo="body"
                   placeholder="{{column.header | translate}}"
                   [options]="column.filterOptions"
                   [showHeader]="column.displayDropdownFilter"
                   [filter]="column.displayDropdownFilter"
                   [showToggleAll]="false"
                   [(ngModel)]="currentFilter"
                   (onChange)="filter($event.value, 'in')"
                   (onPanelShow)="open($event)"
                   (onPanelHide)="close($event)"
                   (click)="closeIfTargetIsNotListItem($event)">
    </p-multiSelect>

    <p-triStateCheckbox *ngIf="column.filterType === 6 &&column.type === 5"
                        id="{{column.table.key + column.field + 'FilterCheckbox'}}"
                        (click)="stop($event)"
                        (onChange)="filterBoolean($event.value)"
                        [label]="isOutsideTable ? (column.header | translate) : ''"
                        [(ngModel)]="currentFilter"></p-triStateCheckbox>

    <p-checkbox *ngIf="column.filterType === 6 && column.type === 6"
                id="{{column.table.key + column.field + 'FilterCheckbox'}}"
                name="{{column.table.key + column.field + 'FilterCheckbox'}}"
                (click)="stop($event)"
                [binary]="true"
                [label]="isOutsideTable ? (column.header | translate) : ''"
                (onChange)="filterBoolean($event.checked)"
                [(ngModel)]="currentFilter"></p-checkbox>


</div>