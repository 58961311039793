import { IDatePeriodSet, DatePeriodSetCreator, DatePeriodCreator, IDatePeriod } from "../models/date-period-set";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class DatePeriodSetApi extends ApiService<IDatePeriodSet, DatePeriodSetCreator, DatePeriodSetCreator> {
    getRoute(): string {
        return "DatePeriodSets";
    }
}