import { Injectable } from "@angular/core";
import { ICctvImage } from "../models/cctv-image";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root"
})
export class CctvImageApi extends ApiService<ICctvImage, ICctvImage, ICctvImage> {
    getRoute(): string {
        return "CctvImages";
    }
}