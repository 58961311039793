<div class="p-16">
    <div class="m-vms-animation-wrapper">
        <ng-container *ngIf="viewModels != null && viewModels.length > 1 && currentIndex != null">
            <button (click)="moveLeft()"
                    class="m-vms-animation-button m-vms-animation-prev">
                <app-svg-icon icon="chevron-left"
                              class="m-vms-animation-button-icon mr-2"
                              divClass="icon-16">
                </app-svg-icon>
            </button>
        </ng-container>

        <div class="m-vms-animation-image-wrapper">
            <ng-container *ngFor="let viewModel of viewModels; index as i">
                <div [hidden]="currentIndex !== i">
                    <img class="m-vms-animation-image"
                         *ngIf="viewModel?.uri"
                         [style]="imageStyle"
                         [src]="viewModel.uri">

                    <img class="m-vms-animation-image"
                         *ngIf="viewModel?.imagePreview"
                         [style]="imageStyle"
                         [src]="viewModel.imagePreview | safeHtml">
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="viewModels != null && viewModels.length > 1 && currentIndex != null">
            <button (click)="moveRight()"
                    class="m-vms-animation-button m-vms-animation-next">
                <app-svg-icon icon="chevron-right"
                              class="m-vms-animation-button-icon ml-2"
                              divClass="icon-16">
                </app-svg-icon>
            </button>
        </ng-container>
    </div>

    <ng-container *ngIf="viewModels != null && viewModels.length > 1 && currentIndex != null">
        <div class="m-center mt-16">
            {{currentIndex + 1}}/{{viewModels.length}} - {{viewModels[currentIndex].displayTimeMs}} ms
        </div>
    </ng-container>
</div>