// Rights are defined in the backend and are used to determine what the user can see and do in the frontend
// canSee[Screen] = Can see the screen. Used in auth.guard to determine if the user can navigate to the screen

import { BackendRights } from "./backend-rights";
import { IRole, Roles } from "./user";

// canSee[Screen] doesn't exist in the backend; it's mapped based on backend rights
export class Rights {

    private _backendRights: BackendRights[];


    public get backendRights(): BackendRights[] {
        return this._backendRights;
    }

    public hasBackendRight(backendRight: BackendRights): boolean {
        return this._backendRights && this._backendRights.includes(backendRight);
    }

    constructor(backendRights: BackendRights[] = []) {
        this._backendRights = backendRights;
    }

}