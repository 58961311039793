<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{"general.preview" | translate}}
    </p-header>

    <app-vms-animation></app-vms-animation>

    <p-footer>
        <input type="button"
               class="m-btn m-btn-primary"
               value="{{'form.close' | translate}}"
               (click)="close()">
    </p-footer>
</p-dialog>