import { IServiceModelBase } from "./servicemodelbase";
import { IUserSummary } from "./user";
import { IAssignment } from "./assignment";
import { IAttachment } from "./attachment";
import { IProject } from "./project";
import { IDevice } from "./device";
import { IMeasuringPointNavigator } from "./measuring-point";

export interface IJournal extends IServiceModelBase {
    id: number;
    createDate: Date;
    timestamp: Date;
    lastModificationDateTime?: Date;
    lastModifiedBy?: IUserSummary;

    url: string;
    category: IJournalCategory;
    subCategory: IJournalSubCategory;
    remarks: string;
    adminRemarks: string;
    includeInReport: boolean;
    device: IDevice;
    measuringPoint: IMeasuringPointNavigator;
    organizationId: number;
    project?: IProject;
    assignment?: IAssignment;
    creator: IUserSummary;
    isAdminOnly: boolean;

    attachments: IAttachment[];
}

export interface IJournalCategory extends IServiceModelBase {
    codeStringResourceId: string;
    description: string;
    contextTypeId: string;
    isMaas: boolean;

    subCategories: IJournalSubCategory[];
}

export interface IJournalSubCategory extends IServiceModelBase {
    category: IJournalCategory;
    codeStringResourceId: string;
    description: string;
}

// Type used for both creating and updating a category (-> saving)
export class JournalCategorySaver {
    codeStringResource: { [key: string]: string };
    description: string;
    contextTypeId: string;
    isMaas: boolean;
}

export class JournalSubCategorySaver extends JournalCategorySaver {
    id: number;
    codeStringResource: { [key: string]: string };
    categoryId: number;
    isObsolete?: boolean;
}

export class JournalCreator {
    timestamp: Date;
    categoryId: number;
    subCategoryId: number;
    remarks: string;
    adminRemarks: string;
    isAdminOnly: boolean;
    deviceId?: number;
    measuringPointId?: number;
    organizationId?: number;
    projectId?: number;
}

export class JournalUpdater extends JournalCreator {
    constructor(existingJournal: IJournal = null) {
        super();

        this.id = existingJournal.id;
        this.timestamp = existingJournal.timestamp;
        this.categoryId = existingJournal.category?.id;
        this.subCategoryId = existingJournal.subCategory?.id;
        this.remarks = existingJournal.remarks;
        this.adminRemarks = existingJournal.adminRemarks;
        this.isAdminOnly = existingJournal.isAdminOnly;
        this.deviceId = existingJournal.device ? existingJournal.device.id : null;
        this.measuringPointId = existingJournal.measuringPoint ? existingJournal.measuringPoint.id : null;
        this.organizationId = existingJournal.organizationId;
        this.projectId = existingJournal.project ? existingJournal.project.id : null;
    }

    id: number;
}