<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingUser ? "manageUser.edit" : "manageUser.create") | translate}}
    </p-header>

    <form *ngIf="manageUserForm"
          class="p-16"
          [formGroup]="manageUserForm"
          novalidate
          id="editUserDialog">

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageUserForm.get('firstName').showErrors }">

            <label class="col-2"
                   for="firstName">
                {{"general.firstName" | translate}}
            </label>

            <div class="col-10">
                <input type="text"
                       class="p-inputtext"
                       id="firstName"
                       formControlName="firstName"
                       #firstNameInput />

                <app-form-validation [control]="manageUserForm.get('firstName')"
                                     [input]="firstNameInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageUserForm.get('lastName').showErrors }">

            <label class="col-2"
                   for="lastName">
                {{"general.lastName" | translate}}
            </label>

            <div class="col-10">
                <input type="text"
                       id="lastName"
                       class="p-inputtext"
                       formControlName="lastName"
                       #lastNameInput />

                <app-form-validation [control]="manageUserForm.get('lastName')"
                                     [input]="lastNameInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageUserForm.get('email').showErrors }">

            <label class="col-2"
                   for="email">
                {{"general.email" | translate}}
            </label>

            <div class="col-10">
                <input type="text"
                       id="email"
                       class="p-inputtext"
                       formControlName="email"
                       #emailInput />

                <app-form-validation [control]="manageUserForm.get('email')"
                                     [input]="emailInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             *ngIf="manageUserForm.get('sendDefaultEmail').enabled"
             [ngClass]="{ 'has-error': manageUserForm.get('sendDefaultEmail').showErrors }">

            <label class="col-2"
                   for="sendDefaultEmail">
                {{"manageUser.defaultEmail" | translate}}
            </label>

            <div class="col-10">
                <p-inputSwitch id="sendDefaultEmail"
                               formControlName="sendDefaultEmail"
                               #sendDefaultEmailInput>
                </p-inputSwitch>

                <app-form-validation [control]="manageUserForm.get('sendDefaultEmail')"
                                     [input]="sendDefaultEmailInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageUserForm.get('languageId').showErrors }">

            <label class="col-2"
                   for="language">
                {{"general.language" | translate}}
            </label>

            <div class="col-10">
                <p-dropdown formControlName="languageId"
                            appendTo="body"
                            id="languageId"
                            placeholder="{{'general.language' | translate}}"
                            [panelStyle]="{'min-width':'100%'}"
                            [options]="languages"
                            [filter]="true"
                            [resetFilterOnHide]="true"
                            #languageIdInput>
                </p-dropdown>

                <app-form-validation [control]="manageUserForm.get('languageId')"
                                     [input]="languageIdInput">
                </app-form-validation>
            </div>
        </div>
        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageUserForm.get('phoneNumber').showErrors }">

            <label class="col-2"
                   for="phoneNumber">
                {{"general.phoneNumber" | translate}}
            </label>

            <div class="col-10">
                <input type="text"
                       id="phoneNumber"
                       class="p-inputtext"
                       formControlName="phoneNumber"
                       #phoneNumberInput />

                <app-form-validation [control]="manageUserForm.get('phoneNumber')"
                                     [input]="phoneNumberInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageUserForm.get('gsm').showErrors }">

            <label class="col-2"
                   for="gsm">
                {{"users.gsm" | translate}}
            </label>

            <div class="col-10">
                <input type="text"
                       id="gsm"
                       class="p-inputtext"
                       formControlName="gsm"
                       #gsmInput />

                <app-form-validation [control]="manageUserForm.get('gsm')"
                                     [input]="gsmInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': manageUserForm.get('organizations').showErrors }">

            <label class="col-2"
                   for="organizations">
                {{"general.organizations" | translate}}
            </label>

            <div class="col-10">
                <p-multiSelect formControlName="organizations"
                               appendTo="body"
                               id="organizations"
                               placeholder="{{'general.organizations' | translate}}"
                               [style]="{'width':'100%'}"
                               [options]="organizations"
                               [filter]="true"
                               [showHeader]="true"
                               [showToggleAll]="false"
                               #selectedOrganizationsInput>
                </p-multiSelect>


                <app-form-validation [control]="manageUserForm.get('organizations')"
                                     [input]="selectedOrganizationsInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [formGroup]="rolesForm">

            <label class="col-2"
                   for="roles">
                {{"general.roles" | translate}}
            </label>

            <div class="col-10">
                <!--loop over roleWithCategory-->
                <div *ngFor="let item of rolesByCategory | keyvalue : returnZero; index as i"
                     [ngClass]="{'mt-8': i > 0}">
                    <h4>{{item.key | translateEnum:"roleCategory" }}</h4>

                    <label class="m-input-checkbox-block mt-8"
                           *ngFor="let role of item.value">
                        <input formControlName="{{role.roleId}}"
                               id="{{role.roleId}}"
                               type="checkbox">

                        <span>
                            {{getTranslatableRoleName(role.roleId) | translate}}<br>
                            <span class="m-text-light">{{ getDescriptionText(role.roleId) | translate}}</span>
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button"
                id="cancelButton">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit"
                id="saveButton">
            <app-loading-spinner *ngIf="submitting"
                                 id="manage_user_spinner">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>