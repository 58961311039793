import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AssignmentCreator, AssignmentSubtaskSearchParameters, IAssignment, IAssignmentSubtask } from "../models/assignment";
import { ApiService, HttpOptions } from "./api";

@Injectable({
    providedIn: "root"
})
export class AssignmentApi extends ApiService<IAssignment, AssignmentCreator, AssignmentCreator> {

    getRoute(): string {
        return "Assignments";
    }

    getSubtasksSummary$(params: AssignmentSubtaskSearchParameters): Observable<IAssignmentSubtask[]> {
        const url = `${super.getUrl()}/subtasks`;
        return this.http.get<IAssignmentSubtask[]>(url, { params: params } as HttpOptions);
    }

}