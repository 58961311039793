import { Component, ElementRef, Input } from "@angular/core";
import {
    ColumnType,
    CustomTableComponent,
    FilterType,
    TableColumn,
    TableService,
} from "@portal/shared/components/table/table.component";
import { IGroup } from "@ramudden/data-access/models/group";
import { IGroupsFilter, IMeasuringPointsFilter } from "@ramudden/data-access/models/report-type";
import { ServiceRequestOptions } from "@ramudden/data-access/models/search";
import { IMeasuringPointSummary } from "@ramudden/data-access/models/web";
import { GroupApi } from "@ramudden/data-access/resource/group.api";
import { MapSelectionService } from "@ramudden/services";
import { forkJoin } from "rxjs";

@Component({
    selector: "app-select-groups",
    templateUrl: "./select-groups.component.html",
})
export class SelectGroupsComponent extends CustomTableComponent<IGroup> {
    @Input() unlinkCommand = false;
    @Input() deleteCommand = true;
    @Input() hideDirectionFilters = false;

    isDirty = false;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly selectionService: MapSelectionService,
        private readonly groupApi: GroupApi,
    ) {
        super("select-groups.component", elementRef, tableService);

        this.selectionMode = null;
        this.paginator = false;
        this.footer = false;
        this.filter = false;
        this.sortable = false;

        this.addColumn(new TableColumn("expand", "", { type: ColumnType.Checkbox }));
        this.addColumn(new TableColumn("code", "groups.code", { filterType: FilterType.Text, sortable: true }));

        this.selectionService.subscribeToGroups(
            this.subscriptionManager,
            (x) => {
                this.addData(x);
                this.isDirty = true;
            },
            (x) => {
                this.removeData(x);
                this.isDirty = true;
            },
        );

        this.registerCommand({
            text: "reports.unlinkGroup",
            icon: "measuring-points",
            click: (group) => this.unlink(group),
            validFunc: () => this.unlinkCommand,
        });

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (group) => {
                this.removeData([group]);
                this.isDirty = true;
            },
            validFunc: () => this.deleteCommand,
        });
    }
    unlink(group: IGroup) {
        this.removeData([group]);

        const newMeasuringPoints = group.measuringPoints.map((x) => {
            x.measuringPoint.includeForwardDirection = x.includeForwardDirection;
            x.measuringPoint.includeReverseDirection = x.includeReverseDirection;
            x.measuringPoint.includeSum = x.includeSum;

            return x.measuringPoint;
        });
        this.selectionService.addMeasuringPoints(newMeasuringPoints);
    }

    onSetData() {
        this.selectionService.setGroups(this.data);
    }

    //#region Reporting

    async addFromFilter(groupFilters: IGroupsFilter) {
        if (groupFilters) {
            this.addIds(groupFilters.ids);
        }
    }

    async addFromMeasuringPointsFilter(measuringPointsFilter: IMeasuringPointsFilter) {
        if (measuringPointsFilter) {
            this.addIds(measuringPointsFilter.groupIds);
        }
    }

    private async addIds(groupIds: number[]) {
        if (!groupIds || !groupIds.length) return;

        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("group", "measuringPoints");

        const groups = (await forkJoin(
            groupIds.map((x) => this.groupApi.get$(x, null, serviceRequestOptions).toPromise()),
        ).toPromise()) as IGroup[];

        this.addData(groups);
    }

    getMeasuringPoints(): IMeasuringPointSummary[] {
        return this.data.selectMany<IGroup, IMeasuringPointSummary>((g) =>
            g.measuringPoints.map((mpg) => mpg.measuringPoint),
        );
    }

    //#endregion Reporting
}
