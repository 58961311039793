import { Injectable } from "@angular/core";
import { IJournal, JournalCreator } from "@ramudden/data-access/models/journal";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class JournalApiService extends ApiService<IJournal, JournalCreator, JournalCreator> {
    getRoute() {
        return "Journal/{?type}/{?id}";
    }
}
