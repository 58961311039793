import { ViewModelEnum } from "./domain-data";


export interface IVehicleRowCategoryData {
    forwardCount: number;
    reverseCount: number;
}

export interface IVehicleRow {
    date: Date;
    isError: boolean;
    isException: boolean;
    exceptionReason: string;
    isPredicted: boolean;
    data: { [key: string]: IVehicleRowCategoryData };
    isDirty: boolean;
    isSaving: boolean;
    journals: number;
    audits: number;
}

export class VehicleMeasurementDataDeleter {
    constructor(
        public begin: Date,
        public end: Date) {
    }
}

export class VehicleDayOverviewVehicleCategory {
    viewModelEnum: ViewModelEnum;
    value: number;
    percentage: number;
    isHighest: boolean;
}

export enum VehicleDayOverviewEventType {
    Journal = "journal",
    Audit = "audit"
}

export interface IVehicleDayOverviewEvent {
    dateTime: Date;
    type: VehicleDayOverviewEventType;
}

export class VehicleDayOverviewHour {
    total: number;
    carMeteo: number;
    bikeMeteo: number;
    vehicleCategory: {
        [key: string]: number;
    };
}

export class VehicleDayOverview {
    date: Date;
    dateString: string; // Used for dataKey in grid, can't compare dates properly
    isError: boolean;
    isException: boolean;
    averageVehicleLength: number;
    exceptionReason: string;
    isPredicted: boolean;
    isSpecialDay: boolean;
    datePeriodName: string;
    datePeriodType: string;
    isDirty: boolean;
    t16: number;
    t16AverageDayDeviationPercentage: number;
    t24: number;
    hourValues: VehicleDayOverviewHour[];
    hasData: boolean;
    maxValue: number;

    shouldBePredicted = false;
    shouldBeReverted = false;
    initialIsPredicted: boolean;
    initialIsError: boolean;
    initialIsException: boolean;

    vehicleCategory: {
        [key: string]: number;
    };
    vehicleCategories: VehicleDayOverviewVehicleCategory[];
    events: IVehicleDayOverviewEvent[];
    eventsGroupedBy24Hours: number[];
}
