import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";


export class LegendRow {
    color: string;
    translationKey: string;
}

@Component({
    selector: "app-legend",
    templateUrl: "./legend.component.html",
})

export class LegendComponent implements OnInit {
    @Input() configuration: LegendRow[];
    @Input() direction: string;

    constructor() {

    }

    ngOnInit(): void {
    }
}
