import { ValidatorFn, AbstractControl } from "@angular/forms";

export class AllOrNothingRequiredValidator {
    static loopingOverControls: boolean;

    static create(controls: AbstractControl[], includeDisabled = false): ValidatorFn {
        return (c: AbstractControl) => {

            const relevantControls = (includeDisabled ? controls.filter(x => x.value) : controls.filter(x => x.enabled && x.value));

            if (!this.loopingOverControls) {
                this.loopingOverControls = true;
                relevantControls.filter(x => x !== c).forEach(x => x.updateValueAndValidity());
                this.loopingOverControls = false;
            }

            // Nothing or all filled in => no error
            if (relevantControls.length === 0 || relevantControls.length === controls.length) return null;

            // Else only show "Required" on empty controls
            return !c.value ? { required: true } : null;
        };
    }
}