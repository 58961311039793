import { SelectItem } from "primeng/api";
import { IMapIcon } from "./map-icon";

export interface IDomainData {
    enums: ILocalizedEnums;
    relations: { [key: string]: { [key: string]: { [key: string]: string[] } } };
    modelTranslations: { [key: string]: { [key: string]: string } };
    mapIcons: IMapIcon[];
}

export interface ILocalizedEnums {
    [key: string]: ILocalizedEnum;
}

export interface ILocalizedEnum {
    values: ILocalizedStrings;
}

export interface ILocalizedStrings {
    [key: string]: ILocalizedString;
}

export interface ILocalizedString {
    translations: ILocalizedStringTranslation;
}

export interface ILocalizedStringTranslation {
    [language: string]: string;
}

export class ViewModelEnum implements SelectItem {

    jsonValue: string;
    relations: ViewModelEnum[];
    disabled?: boolean;

    constructor(
        public type: string,
        public value: string,
        public label: string, // value & label are both used by PrimeNG for dropdowns
        public language: string) {

        this.jsonValue = value.toPascalCase();
    }
}

export class LocalizedModelViewModel implements SelectItem {
    constructor(
        public value: any,
        public label: string, // value & label are both used by PrimeNG for dropdowns
        public stringResourceId: string,
        public language: string) {
    }
}