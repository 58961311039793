import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class LocaleService {

    constructor(
        private readonly translateService: TranslateService) {
    }

    getLocale(): string {
        const curLang = this.translateService.currentLang;

        const curLocale =
            curLang === "nl" ? "nl-BE" :
                curLang === "de" ? "de-DE" :
                    "en-GB";

        return curLocale;
    }

}