import { IMessage } from "./message";
import { IServiceModelBase } from "./servicemodelbase";

export interface INotification extends IServiceModelBase {
    seenOn: Date;
    createDate: Date;
    levelId: NotificationLevel;
    message: IMessage;
    isSticky: boolean;
    categoryId: NotificationCategory;
}

export enum NotificationCategory {
    Alert = "alert",
    General = "general",
}

export enum NotificationLevel {
    Information = "information",
    Warning = "warning",
    Error = "error"
};

export enum NotificationType {
    Email = "email",
    Sms = "sms"
}