import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export class EqualsValidator {
    static to(equalsControlValue: UntypedFormControl): ValidatorFn {
        //@ts-ignore
        return (c: UntypedFormControl) => {
            const valid = c.value === equalsControlValue.value;
            if (valid) return null;

            return { isEqual: { valid: false } };
        };
    }

    static validateGroup(c: UntypedFormGroup): ValidationErrors | null {
        let valid = true;

        let previousValue = null;
        for (const controlName in c.controls) {
            if (!c.controls.hasOwnProperty(controlName)) continue;

            const control = c.controls[controlName];

            if (!previousValue) {
                previousValue = control.value;
            }

            valid = valid && previousValue === control.value;
        }

        if (valid) return null;
        return { isEqual: { valid: false } };
    }
}
