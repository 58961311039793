import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from "file-saver";
import html2canvas, { Options } from "html2canvas";
import { ToastService } from "./toast.service";

@Injectable({
    providedIn: "root"
})
export class ScreenshotService {

    constructor(
        private readonly toastService: ToastService,
        private readonly translateService: TranslateService
    ) {

    }

    // Created image(of passed Div) will be downloaded through browser
    public takeScreenshotAndDownloadImage(divToBeScreeshoted: HTMLDivElement, fileName: string, options: Partial<Options>,
        errorToBeTranslated = "general.screenshotNotTaken"): void {
        html2canvas(divToBeScreeshoted, options)
            .then((canvas: HTMLCanvasElement) => {
                canvas.toBlob((blob: Blob) => {
                    saveAs(blob, fileName);
                });
            }).catch((error) => {
                this.toastService.error(this.translateService.instant(errorToBeTranslated));
            });
    }
}
