import { Subscription, Observable, timer, from } from "rxjs";
import { switchMap, repeat } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { ApiServiceBase } from "./api";
import { Injectable } from "@angular/core";
import { IProgress } from "../models/progress";

@Injectable({
    providedIn: "root"
})
export class ProgressApi extends ApiServiceBase {

    getRoute(): string {
        return "Progress";
    }

    get$(id: string): Observable<IProgress> {
        const url = `${this.getUrl()}/${id}`;
        return this.http.get<IProgress>(url);
    }

    cancel$(id: string): Observable<void> {
        const url = `${this.getUrl()}/${id}/cancel`;
        return this.http.post<void>(url, null);
    }

    public async pollProgress(progressId: string, progressCallback?: (progress: IProgress) => void): Promise<IProgress> {
        return new Promise<IProgress>((resolve, reject) => {

            let pollInterval = 1000;
            let subscription: Subscription;
            const startTime = new Date();

            const closeSubscription = () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };

            const onProgress = (progress: IProgress) => {
                progress.progress = Math.round(progress.progress * 100);

                const curTime = new Date();
                const elapsedSeconds = (curTime.getTime() - startTime.getTime()) / 1000;

                if (elapsedSeconds >= 60) {
                    pollInterval = 5000;
                } else if (elapsedSeconds >= 10) {
                    pollInterval = 3000;
                } else if (elapsedSeconds >= 5) {
                    pollInterval = 2000;
                }

                if (progressCallback) {
                    progressCallback(progress);
                }

                if (progress.isDone) {
                    closeSubscription();
                    resolve(progress);
                }

                if (progress.isCanceled) {
                    closeSubscription();
                    reject();
                }

                if (progress.hasError) {
                    closeSubscription();
                    reject(progress);
                }
            };

            const onError = (e: HttpErrorResponse) => {
                if (e.status === 404) {
                    resolve(null);
                } else {
                    reject(e);
                }

                closeSubscription();
            };

            subscription = from("a") // 1 character to get the observable moving :-D
                .pipe(
                    switchMap(() => {
                        return timer(pollInterval);
                    }),
                    switchMap(() => {
                        return this.get$(progressId);
                    }),
                    repeat()
                ).subscribe(onProgress, onError);
        });
    }
}