import { Directive, inject, input } from "@angular/core";
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS } from "@angular/forms";
import { ITextFieldConfiguration } from "@ramudden/data-access/models/totem-display-configuration";
import { MeasuringPointApi } from "@ramudden/data-access/resource/measuring-point.api";
import { catchError, map, Observable, of } from "rxjs";

@Directive({
    selector: "[appTextFieldValidator]",
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: TextFieldValidatorDirective,
            multi: true,
        },
    ],
})
export class TextFieldValidatorDirective implements AsyncValidator {
    textFieldConfig = input<ITextFieldConfiguration>();
    private readonly measuringPointsApi = inject(MeasuringPointApi);

    validate(control: AbstractControl): Observable<{ [key: string]: any } | null> {
        if (!control.value) return of(null);

        return this.measuringPointsApi.previewTotemDisplay$(control.value).pipe(
            map((response) => {
                if (response.text === null) {
                    return of({ isServiceModel: "isServiceModel" });
                }
                const formattedText = response.text;
                const formattedLines = formattedText.split("\n").filter((line) => line.length > 0);

                if (formattedLines.length > this.getMaxLineCount()) {
                    return of({ maxLines: { max: this.getMaxLineCount() } });
                }

                for (const line of formattedLines) {
                    if (line.length > this.getMaxLineLength()) {
                        return { maxLineLength: { max: this.getMaxLineLength() } };
                    }
                }
                return null;
            }),
            catchError(() => of({ isServiceModel: "isServiceModel" })),
        );
    }

    private getMaxLineCount(): number {
        return this.textFieldConfig() ? this.textFieldConfig().lineCount : 3; // Qlite displays always have 3 lines
    }

    private getMaxLineLength(): number {
        return this.textFieldConfig() ? this.textFieldConfig().lineLength : 12; // Qlite displays always have 12 characters
    }
}
