import { IAttachmentType, AttachmentTypeCreator } from "../models/attachment";
import { ExistsResult, ExistsValue } from "../models/search";
import { ApiService } from "./api";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class AttachmentTypeApi extends ApiService<IAttachmentType, AttachmentTypeCreator, AttachmentTypeCreator> {
    getRoute(): string {
        return "AttachmentTypes";
    }

    exists$(existsValue: ExistsValue): Observable<ExistsResult> {
        const url = `${super.getUrl()}/Exists`;
        return this.http.get<ExistsResult>(url, { params: { code: existsValue.value } });
    }
}