<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{ (existingJournal ? ( readonly ? "journal.title" : "journal.journalDialogEdit") : "journal.journalDialogHeader") | translate:translationObject }}
    </p-header>

    <form *ngIf="manageJournalEntryForm"
          class="p-16"
          [formGroup]="manageJournalEntryForm"
          autocomplete="off"
          novalidate>

        <div class="row">
            <div class="col-6">

                <div class="m-form-group row">
                    <label class="col-4"
                           for="categoryId">
                        {{"journal.category" | translate}}
                    </label>

                    <div class="col-8">
                        <p-dropdown id="categoryId"
                                    [options]="categories"
                                    formControlName="categoryId"
                                    placeholder="{{'journal.category' | translate}}"
                                    appendTo="body"
                                    [autoDisplayFirst]="false"
                                    (onChange)="setSelectedCategory($event.value)"
                                    #categoryIdInput>
                        </p-dropdown>

                        <app-form-validation [control]="manageJournalEntryForm.get('categoryId')"
                                             [input]="categoryIdInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': manageJournalEntryForm.get('timestamp').showErrors }">

                    <label class="col-4"
                           for="timestamp">
                        {{"journal.timestamp" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar id="timestamp"
                                    formControlName="timestamp"
                                    [style]="{'width':'100%'}"
                                    autoWidth="false"
                                    appendTo="body"
                                    [showIcon]="true"
                                    [hourFormat]="calendarSettings.hourFormat"
                                    [dateFormat]="calendarSettings.dateFormat"
                                    [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                    [showTime]="true"
                                    [monthNavigator]="false"
                                    [yearNavigator]="false"
                                    [yearRange]="primeComponentService.calendarYearRange"
                                    #timestampInput></p-calendar>

                        <app-form-validation [control]="manageJournalEntryForm.get('timestamp')"
                                             [input]="timestampInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row"
                     *ngIf="canSetSubCategory">
                    <label class="col-4"
                           for="subCategoryId">
                        {{"journal.subCategory" | translate}}
                    </label>

                    <div class="col-8">
                        <p-dropdown id="subCategoryId"
                                    [options]="subCategories"
                                    formControlName="subCategoryId"
                                    placeholder="{{'journal.subCategory' | translate}}"
                                    appendTo="body"
                                    [autoDisplayFirst]="false"
                                    #subCategoryIdInput>
                        </p-dropdown>

                        <app-form-validation [control]="manageJournalEntryForm.get('subCategoryId')"
                                             [input]="subCategoryIdInput">
                        </app-form-validation>
                    </div>
                </div>

                <div *ngIf="canSetProject"
                     class="m-form-group row"
                     [ngClass]="{ 'has-error': manageJournalEntryForm.get('projectId').showErrors }">

                    <label class="col-4"
                           for="projectId">
                        {{"general.project" | translate}}
                    </label>

                    <div class="col-8">
                        <p-dropdown id="projectId"
                                    [options]="projects"
                                    [style]="{'width':'100%'}"
                                    formControlName="projectId"
                                    [filter]="true"
                                    placeholder="{{'general.project' | translate}}"
                                    appendTo="body"
                                    [showClear]="true"
                                    [resetFilterOnHide]="true"
                                    [autoDisplayFirst]="false"
                                    #projectIdInput>

                            <ng-template let-project
                                         pTemplate="item">
                                <span [ngClass]="project.styleClass">{{project.label}}</span>
                            </ng-template>
                        </p-dropdown>

                        <app-form-validation [control]="manageJournalEntryForm.get('projectId')"
                                             [input]="projectIdInput">
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row">
                    <label class="col-4"
                           for="remarks">
                        {{"journal.remarks" | translate}}
                    </label>

                    <div class="col-8">
                        <textarea rows="5"
                                  placeholder="{{'journal.remarks' | translate}}"
                                  id="remarks"
                                  class="p-inputtextarea"
                                  formControlName="remarks"
                                  #remarksInput></textarea>
                    </div>
                </div>

                <div class="m-form-group row"
                     *ngIf="manageJournalEntryForm.get('adminRemarks')">

                    <span class="col-4"
                          for="adminRemarks">
                        {{"journal.adminRemarks" | translate}}
                    </span>

                    <div class="col-8">
                        <textarea rows="5"
                                  placeholder="{{'journal.adminRemarks' | translate}}"
                                  id="adminRemarks"
                                  class="p-inputtextarea"
                                  formControlName="adminRemarks"
                                  #adminRemarksInput></textarea>
                    </div>
                </div>

                <div class="m-form-group row"
                     *ngIf="manageJournalEntryForm.get('isAdminOnly')">

                    <span class="col-4">
                        {{"auditTrails.isAdminOnly" | translate}}
                    </span>

                    <div class="col-8">
                        <p-inputSwitch formControlName="isAdminOnly"></p-inputSwitch>
                    </div>
                </div>

            </div>

            <div class="col-6">
                <app-attachment-form [parentFormGroup]="manageJournalEntryForm"
                                     [context]="existingJournal"
                                     [readonly]="readonly"></app-attachment-form>
            </div>
        </div>
    </form>

    <p-footer>
        <ng-container>
            <button *ngIf="!readonly"
                    class="m-btn m-btn-link"
                    [disabled]="submitting"
                    (click)="close()"
                    type="button"
                    id="close_add_log_entry_dialog_button">
                {{"form.cancel" | translate}}
            </button>

            <button [disabled]="submitting"
                    class="m-btn m-btn-primary"
                    (click)="submit()"
                    type="submit">
                <app-loading-spinner *ngIf="submitting">
                </app-loading-spinner>
                {{(readonly ? "form.close" : "form.save") | translate}}
            </button>
        </ng-container>
    </p-footer>

</p-dialog>