import { BatteryDischargeTableCreator, BatteryDischargeTableUpdater, IBatteryDischargeTable } from "../models/battery-discharge-table";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class BatteryDischargeTableApi extends ApiService<IBatteryDischargeTable, BatteryDischargeTableCreator, BatteryDischargeTableUpdater> {
    getRoute(): string {
        return "BatteryDischargeTables";
    }
}