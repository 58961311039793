import { Injectable } from "@angular/core";
import { ITask, TaskCreator, TaskUpdater } from "../models/task";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root"
})
export class TaskApi extends ApiService<ITask, TaskCreator, TaskUpdater> {

    getRoute(): string {
        return "Tasks";
    }
}