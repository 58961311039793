import { IBikeParameterSet, BikeParameterSetCreator } from "../models/bike-parameter-set";
import { ExistsResult, ExistsValue } from "../models/search";
import { ApiService } from "./api";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class BikeParameterSetApi extends ApiService<IBikeParameterSet, BikeParameterSetCreator, BikeParameterSetCreator> {
    getRoute(): string {
        return "BikeParameterSets";
    }
}