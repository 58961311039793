import "@ramudden/core/extensions";
import "hammerjs";
import "prismjs";
import "prismjs/components/prism-csharp";
import "prismjs/components/prism-json";

import { ApplicationRef, enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { enableDebugTools } from "@angular/platform-browser";
import { environment } from "@env/environment";
import { AppModule } from "apps/portal/src/app/app.module";

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((moduleRef) => {
        if (!environment.production) {
            const applicationRef = moduleRef.injector.get(ApplicationRef);
            const componentRef = applicationRef.components[0];

            // allows to run `ng.profiler.timeChangeDetection();`
            enableDebugTools(componentRef);
        }
    })
    .catch((err) => console.error(err));
