import { ToastrService, IndividualConfig, ActiveToast } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class ToastService {

    activeErrors: Array<ActiveToast<any>> = [];
    suppressErrors: boolean;

    private readonly defaultOptions: Partial<IndividualConfig> = {
        positionClass: "toast-top-center",
        enableHtml: true
    };

    private readonly infoOptions: Partial<IndividualConfig> = {
        closeButton: true,
        disableTimeOut: true
    };

    private readonly warningOptions: Partial<IndividualConfig> = {
        closeButton: true,
        disableTimeOut: true
    };

    private readonly errorOptions: Partial<IndividualConfig> = {
        closeButton: true,
        disableTimeOut: true
    };

    constructor(
        private readonly toastr: ToastrService,
        private readonly translate: TranslateService) {
    }

    clearErrors() {
        for (const activeError of this.activeErrors) {
            if (!activeError) continue;

            this.toastr.remove(activeError.toastId);
        }

        this.activeErrors = [];
    }

    saveSuccess() {
        this.success("form.saved");
    }

    success(body: string = null, title: string = null, options: Partial<IndividualConfig> = null) {
        if (!body) {
            body = "form.success";
        }

        options = Object.assign({}, this.defaultOptions, options);

        body = this.translate.instant(body);
        title = !title ? null : this.translate.instant(title);

        setTimeout(() => {
            this.toastr.success(body, title, options);
            this.clearErrors();
        });
    }

    info(body: string, title: string = null, options: Partial<IndividualConfig> = null) {
        options = Object.assign({}, this.defaultOptions, this.infoOptions, options);

        body = this.translate.instant(body);
        title = !title ? null : this.translate.instant(title);

        setTimeout(() => {
            this.toastr.info(body, title, options);
        });
    }

    warning(body: string, title: string = null, options: Partial<IndividualConfig> = null) {
        options = Object.assign({}, this.defaultOptions, this.warningOptions, options);

        body = this.translate.instant(body);
        title = !title ? null : this.translate.instant(title);

        setTimeout(() => {
            this.toastr.warning(body, title, options);
        });
    }

    error(body: string, title: string = null, options: Partial<IndividualConfig> = null) {
        if (this.suppressErrors) return;

        body = this.translate.instant(body);
        body = body.replaceAll("\r\n", "<br>");
        title = !title ? null : this.translate.instant(title);
        options = Object.assign({}, this.defaultOptions, this.errorOptions, options);

        setTimeout(() => {

            const substringedBody = body.substring(0, 20);
            const existingError = this.activeErrors.find(x => x.message.substring(0, 20) === substringedBody);
            if (existingError) {
                this.toastr.remove(existingError.toastId);
                this.activeErrors = this.activeErrors.remove(existingError);
            }

            const errorToast = this.toastr.error(body, title, options);
            this.activeErrors.push(errorToast);
        });
    }
}