import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "app-fuzzy-search-input",
    templateUrl: "./fuzzy-search-input.component.html"
})
export class FuzzySearchInputComponent implements OnInit {

    @Input() placeholder: string;
    @Output() fuzzySearchStart = new EventEmitter<string>();

    searchText: string;

    constructor() { }

    ngOnInit() {
    }

    filter(value: string) {
        this.searchText = value;
        this.fuzzySearchStart.next(value);
    }

    clearFilter() {
        this.filter("");
    }
}
