import { ChangeDetectorRef, inject, OnDestroy, Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DomainDataService } from "@ramudden/services";
import { Subscription } from "rxjs";

@Pipe({ name: "translateEnumList", pure: false })
export class TranslateEnumListPipe implements PipeTransform, OnDestroy {
    onLangChange: Subscription | undefined;
    lastDomain: string | null = null;
    lastKeys: string[] | null = null;
    result = "";

    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly domainDataService = inject(DomainDataService);
    private readonly translateService = inject(TranslateService);

    updateValue(keys: string[], domain: string): void {
        const result = keys.map((key) => this.domainDataService.translateEnum(domain, key)).join(", ");
        if (result) {
            this.result = result;
            this.lastKeys = keys;
            this.changeDetectorRef.markForCheck();
        }
    }

    transform(value: string[], domain: string): string {
        if (!value || value.length === 0) {
            return "";
        }
        if (this.lastKeys != null && value.join(",") === this.lastKeys.join(",") && domain === this.lastDomain) {
            return this.result;
        }
        this.lastDomain = domain;
        this.lastKeys = value;

        this.updateValue(value, domain);
        this.dispose();

        if (!this.onLangChange) {
            this.onLangChange = this.translateService.onLangChange.subscribe(() => {
                if (this.lastKeys) {
                    this.lastKeys = null;
                    this.updateValue(value, domain);
                }
            });
        }

        return this.result;
    }

    private dispose() {
        if (this.onLangChange) {
            this.onLangChange.unsubscribe();
            this.onLangChange = undefined;
        }
    }

    ngOnDestroy() {
        this.dispose();
    }
}
