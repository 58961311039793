import { Component, OnDestroy } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { BackendRights } from "@ramudden/data-access/models/backend-rights";
import { SigncoFormGroup } from "@ramudden/data-access/models/form";
import { Rights } from "@ramudden/data-access/models/rights";
import { IUploadDetailsDialogData } from "@ramudden/data-access/models/upload";
import { UploadApi } from "@ramudden/data-access/resource/upload.api";
import { DownloadedFile, GlobalEventsService } from "@ramudden/services";
import { DialogComponentBase } from "../dialog/dialog.component";

@Component({
    selector: "app-upload-details-dialog",
    templateUrl: "./upload-details.dialog.html",
})
export class UploadDetailsDialogComponent extends DialogComponentBase implements OnDestroy {
    upload: IUploadDetailsDialogData;
    dialogForm: SigncoFormGroup;
    downloadingAnalysis = false;
    callback: () => void;
    canDownloadAnalysisFile = false;
    canDownloadPulsesFile = false;

    constructor(
        private readonly globalEventsService: GlobalEventsService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly uploadApi: UploadApi,
    ) {
        super();
        // rights subscription
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.canDownloadAnalysisFile = rights?.hasBackendRight(BackendRights.ViewUploadAnalysis);
            this.canDownloadPulsesFile = rights?.hasBackendRight(BackendRights.ViewUploadPulses);
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);
    }

    override ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    protected override onOpen() {
        this.dialogForm = this.formBuilder.group({}) as SigncoFormGroup;
    }

    protected override canOpen(): boolean {
        return !!this.upload;
    }

    open(upload: IUploadDetailsDialogData, callback?: () => void) {
        this.upload = upload;
        this.callback = callback;
        this.openDialog();
    }

    downloadAnalysis() {
        if (!this.canDownloadAnalysisFile) return;

        this.downloadingAnalysis = true;

        const onDownload = (file: DownloadedFile) => {
            this.downloadingAnalysis = false;
            file.save();
        };

        const onError = () => {
            this.downloadingAnalysis = false;
        };

        this.uploadApi.downloadAnalysis$(this.upload).then(onDownload, onError);
    }

    protected override onClose() {
        if (this.callback) {
            this.callback();
        }

        this.dialogForm = null;
    }
}
