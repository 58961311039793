export {};
declare global {
    interface Event {
        stop(): void;
    }
}

Event.prototype.stop = function () {
    const event = this as Event;
    if (!event) return;

    event.cancelBubble = true;

    if (event.preventDefault) {
        event.preventDefault();
    }

    if (event.stopImmediatePropagation) {
        event.stopImmediatePropagation();
    }

    if (event.stopPropagation) {
        event.stopPropagation();
    }
};
