import { DialogComponentBase } from "./dialog.component";
import { Component } from "@angular/core";

export class GeneralDialogOptions {
    body: string;
    title?: string;
    icon?: string;
    image?: string;
    accept?: () => void;
    reject?: () => void;
    acceptVisible?: boolean;
    rejectVisible?: boolean;
    doNotShowAgain?: boolean;
    doNotShowAgainVisible?: boolean;
    acceptLabel?: string;
    rejectLabel?: string;
}

@Component({
    selector: "app-general-dialog",
    templateUrl: "./general-dialog.component.html"
})
export class GeneralDialogComponent extends DialogComponentBase {
    private readonly defaultOptions = {
        body: "",
        title: "general.info",
        icon: "info",
        image: null,
        doNotShowAgain: false,
        accept: () => { },
        reject: () => { },
        acceptVisible: true,
        rejectVisible: true,
        acceptLabel: "form.ok",
        rejectLabel: "form.cancel"
    } as GeneralDialogOptions;

    options: GeneralDialogOptions = this.defaultOptions;

    constructor() {
        super();
    }

    show(options: GeneralDialogOptions) {
        options = Object.assign({}, this.defaultOptions, options);

        this.options = options;
        this.visible = true;
    }

    accept() {
        if (this.options.accept) {
            this.options.accept();
        }

        this.close();
    }

    reject() {
        if (this.options.reject) {
            this.options.reject();
        }

        this.close();
    }
}