import { IReportingParameters } from "./report-type";
import { IServiceModelBase } from "./servicemodelbase";
import { Duration } from "moment";

export enum ReportStatus {
    Queued = "queued",
    Rendering = "rendering",
    Complete = "complete",
    Failed = "failed",
    Canceled = "canceled"
}

export interface IReportFavoriteMailSchedule extends IServiceModelBase {
    dayOfWeekId: string;
    dayOfMonthId: string;
    email: string;
}

export interface IReportFavorite extends IServiceModelBase {
    name: string;
    parameters: IReportingParameters;
    typeId: string;
    mailSchedules: IReportFavoriteMailSchedule[];
}

export class ReportFavoriteCreator {
    name: string;
    parameters: IReportingParameters;
    mailSchedules: IReportFavoriteMailSchedule[];
}

export class ReportFavoriteUpdater extends ReportFavoriteCreator {
    constructor(existing: IReportFavorite) {
        super();

        this.id = existing.id;
        this.name = existing.name;
        this.parameters = existing.parameters;
        this.mailSchedules = existing.mailSchedules;
    }

    id: number;
}

export interface IReportHistory extends IServiceModelBase {
    id: number;
    fileSize: number;
    duration: Duration;
    typeId: string;
    parameters: IReportingParameters;
    statusId: string;
    organizationId: number;
    favorite?: IReportFavorite;
    errorMessage?: string;
}