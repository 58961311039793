import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SelectItem } from "primeng/api";
import { createPopper } from "@popperjs/core";

export class TwoLevelSelectValue {
    option1: any;
    option2: any;
}

export class TwoLevelSelectOption implements SelectItem {
    label?: string;
    value: any;
    styleClass?: string;
    icon?: string;
    title?: string;
    disabled?: boolean;

    relations: SelectItem[];
}

@Component({
    selector: "app-two-level-select",
    templateUrl: "./two-level-select.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: TwoLevelSelectComponent
        }
    ]
})
export class TwoLevelSelectComponent implements ControlValueAccessor, OnDestroy {

    // Requires that elements on first level have fullfilied relations property representing associated SelectItems on second level
    @Input() configuration: TwoLevelSelectOption[];
    @Input() firstLevelOptions: SelectItem[];
    @Input() secondLevelOptions: SelectItem[];

    @Input() closeDropDownAfterFirstLevelChange = true;
    @Input() closeDropDownAfterSecondLevelChange = true;

    @Input() wordBetweenOptionsTranslation: string;
    @Input() ngStyle?: any;
    @Input() ngStyleHeader?: any;
    @Input() ngStyleExpandablePart?: any;

    @Output() onChange = new EventEmitter<TwoLevelSelectValue>();

    selectedFirstLevel: SelectItem;
    selectedSecondLevel: SelectItem;

    expanded = false;
    touched = false;
    disabled = false;
    private _popper: any;

    value: TwoLevelSelectValue;
    onChangeHandler: (value: any) => {};
    onTouchedHandler: () => {};

    @ViewChild("dropDown", { static: true }) dropDown: ElementRef<HTMLDivElement>;
    @ViewChild("dropDownPart", { static: true }) dropDownPart: ElementRef<HTMLDivElement>;

    constructor(
        private renderer: Renderer2
    ) {

    }
    ngOnDestroy(): void {
        if (this.expanded) {
            this._popper && this._popper.destroy();
            this.renderer.setStyle(this.dropDownPart.nativeElement, "display", "none");
        }
    }
    public handleFirstLevelSelect(selectedElement: SelectItem) {
        const configurationOption = this.configuration.find(x => x.value === selectedElement.value);

        if (!this.configuration || !configurationOption.relations) {
            return;
        }

        this.markAsTouched();
        if (this.selectedFirstLevel && this.selectedFirstLevel === selectedElement) {
            return;
        }

        this.selectedFirstLevel = selectedElement;
        this.filterSecondLevelOptions(configurationOption);

        if (this.onChangeHandler) {
            const value = { option1: this.selectedFirstLevel?.value, option2: this.selectedSecondLevel?.value } as TwoLevelSelectValue;
            this.onChangeHandler(value);
            this.onChange.emit(value);
        }

        if (this.closeDropDownAfterFirstLevelChange && this.expanded) {
            this.toggleExpanded();
        }
    }

    public handleSecondLevelSelect(selectedElement: SelectItem) {
        if ((<SelectItem>selectedElement).disabled) {
            return;
        }
        this.markAsTouched();

        if (selectedElement === this.selectedSecondLevel) {
            return;
        }

        this.selectedSecondLevel = selectedElement;

        if (this.onChangeHandler) {
            const value = { option1: this.selectedFirstLevel?.value, option2: this.selectedSecondLevel?.value } as TwoLevelSelectValue;
            this.onChangeHandler(value);
            this.onChange.emit(value);
        }

        if (this.closeDropDownAfterSecondLevelChange && this.expanded) {
            this.toggleExpanded();
        }
    }


    public toggleExpanded() {
        if (this.expanded) {
            this._popper && this._popper.destroy();

        } else {
            this._popper = createPopper(this.dropDown.nativeElement, this.dropDownPart.nativeElement, {
                placement: "bottom-start",
            });
        }

        this.renderer.setStyle(this.dropDownPart.nativeElement, "display", this.expanded ? "none" : "block");
        this.expanded = !this.expanded;
    }

    /// #region Form

    writeValue(obj: any): void {
        if (!(obj instanceof TwoLevelSelectValue)) {
            return;
        }

        this.value = (<TwoLevelSelectValue>obj);
        this.selectedFirstLevel = this.firstLevelOptions?.find(x => x.value === (<TwoLevelSelectValue>obj).option1);
        this.selectedSecondLevel = this.secondLevelOptions?.find(x => x.value === (<TwoLevelSelectValue>obj).option2);

        this.filterSecondLevelOptions(this.configuration.find(x => x.value === (<TwoLevelSelectValue>obj).option1));
    }
    registerOnChange(fn: any): void {
        this.onChangeHandler = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouchedHandler = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;

        if (this.disabled && this.expanded) {
            this.toggleExpanded();
        }
    }


    private markAsTouched() {
        if (!this.touched) {

            if (this.onTouchedHandler) {
                this.onTouchedHandler();
            }

            this.touched = true;
        }
    }

    /// #endregion Form

    // After selecting first level to filter second level or when setting value of component if wrong combination is configured to select appropriate second level
    // Called after setting selectedFirstLevel
    private filterSecondLevelOptions(selectedFirstLevel: TwoLevelSelectOption) {
        if (!selectedFirstLevel || !selectedFirstLevel.relations || selectedFirstLevel.relations.length === 0) {
            return;
        }

        for (const availableSecondLevelOption of selectedFirstLevel.relations) {
            (<SelectItem>availableSecondLevelOption).disabled = false;
        }

        const otherSecondLevelOptions = this.secondLevelOptions.filter(x => !selectedFirstLevel.relations.contains(x));
        for (const otherSecondLevelOption of otherSecondLevelOptions) {
            (<SelectItem>otherSecondLevelOption).disabled = true;
        }

        if (!selectedFirstLevel.relations.contains(this.selectedSecondLevel)) {
            this.selectedSecondLevel = selectedFirstLevel.relations.takeFirstOrDefault();
        }
    }
}