import { AssignmentStatus, IAssignmentPriority } from "../assignment";

export interface IAssignmentSummary {
    id: number;
    organizationId: number;
    locationId: number;
    name: string;
    description: string;
    projectName: string;
    statusId: AssignmentStatus;
    checkInAtWorkEnabled: boolean;
    parentAssignment: IAssignmentSummary;
    priority: IAssignmentPriority;
}
