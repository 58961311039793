import { AlarmSeverity } from "./alarm";
import { AlertLevel } from "./alert";
import { ArrowBoardDirection, ArrowBoardPosition, DeviceType, TrafficLightState } from "./device";
import { AnalysisType } from "./measuring-point";
import { IServiceModelBase } from "./servicemodelbase";


export enum MapIconSize {
    Normal = 40,
    Large = 50
}
export enum MapIconType {
    AnalysisType = "analysisType",
    DeviceType = "deviceType",
    Assignment = "assignment",
    Organization = "organization",
    Depot = "depot",
    CustomIcon = "customIcon",
    TrafficLight = "trafficLight",
    ArrowBoard = "arrowBoard"
}

export enum MapIconActivityState {
    Active = "active",
    Inactive = "inactive"
}

export interface IMapIcon extends IServiceModelBase {
    id: number;

    code: string; // used by UI
    codeStringResourceId: string;

    description: string;
    labelColor: string;
    filename: string;
    states: IMapIconState[];
    size: number

    mapIconTypeId: MapIconType;
    analysisTypeId?: AnalysisType;
    deviceTypeId?: DeviceType;
    trafficLightStateId?: TrafficLightState;
    arrowBoardPositionId?: ArrowBoardPosition;
    arrowBoardDirectionId?: ArrowBoardDirection;
}

export interface IMapIconState {
    id: number;
    mapIconId: number;
    alarmSeverityId?: AlarmSeverity;
    alertLevelId?: AlertLevel;
    activityStateId?: MapIconActivityState;
    default: IIconData;
    selected: IIconData;
}

export interface IIconData {
    url: string;
    width: number;
    height: number;
    anchorX: number;
    anchorY: number;
    labelColor: string;
}

export class MapIconCreator {
    code: string;
    description: string;
    labelColor: string;
    filename: string;
    size: number;

    mapIconTypeId: MapIconType;

    constructor(mapIcon: IMapIcon) {
        this.description = mapIcon.description;
        this.labelColor = mapIcon.labelColor;
        this.filename = mapIcon.filename;
        this.mapIconTypeId = mapIcon.mapIconTypeId;
        this.size = mapIcon.size;

    }
}