import { ValidatorFn, AbstractControl } from "@angular/forms";

export class MultiNoDuplicateValidator {
    static runningMultiValidity = false;

    static create(controls: AbstractControl[], includeDisabled = false, includeNullValues = true): ValidatorFn {
        return (c: AbstractControl) => {
            let relevantControls = controls.filter(x => x.parent && x !== c);

            if (!includeDisabled) {
                relevantControls = relevantControls.filter(x => x.enabled);
            }

            if (!this.runningMultiValidity) {

                this.runningMultiValidity = true;

                for (const control of relevantControls) {
                    control.updateValueAndValidity({ emitEvent: true, onlySelf: true });
                    control.markAsTouched();
                }

                this.runningMultiValidity = false;
            }

            const isControlWithDuplicateValue = !!relevantControls.find(x => (includeNullValues || !!x.value) && x.value === c.value);
            return isControlWithDuplicateValue ? { duplicate: true } : null;
        };
    }
}