<table>
    <tr *ngFor="let overview of overviews; trackBy overviewTrackByFn">
        <td style="width: 40px">
            <app-svg-icon [icon]="overview.journal ? 'history' : 'log'"
                          fill="orange"
                          [tooltip]="(overview.journal ? 'journal.title' : 'auditTrails.title') | translate">
            </app-svg-icon>
        </td>

        <td style="width: 130px">
            {{ overview.timestamp | momentTime:true }}
        </td>

        <td style="width: 150px">
            <app-navigator [measuringPoint]="overview.measuringPoint"
                           [device]="overview.device"
                           [enabled]="overview.measuringPoint?.id !== measuringPoint?.id"></app-navigator>
        </td>

        <td>
            <ng-template #auditTrailTemplate>
                <app-domain-data-field [enumKey]="overview.auditTrail.eventType"
                                       domainDataType="auditTrailEventType">
                </app-domain-data-field>
            </ng-template>

            <ng-container *ngIf="overview.journal; else auditTrailTemplate">
                <span pTooltip="{{ overview.journal.category.description }}">
                    {{ overview.journal.category.code }}
                </span>

                <span pTooltip="{{ overview.journal.subCategory.description }}">
                    {{ overview.journal.subCategory.code }}
                </span>
            </ng-container>
        </td>
    </tr>
</table>