<div class="resource-selector"
     [hidden]="hidden">
    <nav class="m-tabs-picker resource-selector__header"
         *ngIf="measuringPointsSelection || groupsSelection || projectSelection || organizationsSelection || devicesSelection || scenariosSelection">
        <ul>
            <li *ngIf="measuringPointsSelection">
                <a (click)="setSelectionMode(0)"
                   [ngClass]="{'is-active': selectionMode == 0}"
                   [style.color]="isMeasuringPointsInError(containingFormSubmitted) ? 'red' : ''"
                   id="measuring_points_context">
                    {{ (multipleMeasuringPointsSelection ? "general.measuringPoints" : "general.measuringPoint") | translate }} ({{ currentlySelectedMeasuringPoints }})
                </a>
            </li>
            <li *ngIf="devicesSelection">
                <a (click)="setSelectionMode(4)"
                   [ngClass]="{'is-active': selectionMode == 4}"
                   [style.color]="isDevicesInError(containingFormSubmitted) ? 'red' : ''"
                   id="devices_context">
                    {{ (multipleDevicesSelection ? "general.devices" : "general.device") | translate }} ({{ currentlySelectedDevices }})
                </a>
            </li>
            <li *ngIf="groupsSelection">
                <a (click)="setSelectionMode(1)"
                   [ngClass]="{'is-active': selectionMode == 1}"
                   [style.color]="isGroupsInError(containingFormSubmitted) ? 'red' : ''"
                   id="groups_context">
                    {{ (multipleGroupsSelection ? "general.groups" : "general.group") | translate }} ({{ currentlySelectedGroups }})
                </a>
            </li>
            <li *ngIf="projectSelection">
                <a (click)="setSelectionMode(2)"
                   [ngClass]="{'is-active': selectionMode == 2}"
                   [style.color]="isProjectsInError(containingFormSubmitted) ? 'red' : ''"
                   id="projects_context">
                    {{ (multipleProjectsSelection ? "projects.title" : "general.project") | translate }} ({{ currentlySelectedProjects }})
                </a>
            </li>
            <li *ngIf="organizationsSelection">
                <a (click)="setSelectionMode(3)"
                   [style.color]="isOrganizationsInError(containingFormSubmitted) ? 'red' : ''"
                   [ngClass]="{'is-active': selectionMode == 3}"
                   id="organizations_context">
                    {{ (organizationsSelection ? "organizations.title" : "general.organization") | translate }} ({{ currentlySelectedOrganizations }})
                </a>
            </li>
            <li *ngIf="scenariosSelection">
                <a (click)="setSelectionMode(5)"
                   [ngClass]="{'is-active': selectionMode == 5}"
                   [style.color]="isScenariosInError(containingFormSubmitted) ? 'red' : ''"
                   id="scenarios_context">
                    {{ (multipleScenariosSelection ? "general.scenarios" : "general.scenario") | translate }} ({{ currentlySelectedScenarios }})
                </a>
            </li>
        </ul>

        <app-icon-button *ngIf="!isMapOpen"
                         icon="add"
                         id="buttonAddResource"
                         [text]="'general.add' | translate"
                         [styleClass]="isMapOpen ? 'm-btn m-btn-square hover' : 'm-btn m-btn-square'"
                         [fill]="isInputInError(containingFormSubmitted) ? 'red' : ''"
                         (click)="updateMapDetail()">
        </app-icon-button>
    </nav>

    <div class="resource-selector__content">
        <app-select-measuring-points [footer]="false"
                                     [emptyMessage]="false"
                                     [reorderable]="true"
                                     [hidden]="!measuringPointsSelection || selectionMode != 0"
                                     [scrollHeight]="'flex'"
                                     [deleteCommand]="deleteCommand"
                                     [selectionMode]="multipleMeasuringPointsSelection ? 'multiple' : 'single'"
                                     [hideDirectionFilters]="!measuringPointsWithDirection"
                                     [allowedDirections]="allowedDirections">
        </app-select-measuring-points>

        <app-select-groups [footer]="false"
                           [emptyMessage]="false"
                           [unlinkCommand]="true"
                           [hidden]="!groupsSelection || selectionMode != 1"
                           [scrollHeight]="'flex'"
                           [deleteCommand]="deleteCommand"
                           [selectionMode]="multipleGroupsSelection ? 'multiple' : 'single'"
                           [hideDirectionFilters]="!measuringPointsWithDirection">
        </app-select-groups>

        <app-select-projects [footer]="false"
                             [emptyMessage]="false"
                             [reorderable]="true"
                             [hidden]="!projectSelection || selectionMode != 2"
                             [scrollHeight]="'flex'"
                             [deleteCommand]="deleteCommand"
                             [selectionMode]="multipleProjectsSelection ? 'multiple' : 'single'">
        </app-select-projects>

        <app-select-organizations [footer]="false"
                                  [scrollHeight]="'flex'"
                                  [emptyMessage]="false"
                                  [hidden]="!organizationsSelection || selectionMode != 3"
                                  [deleteCommand]="deleteCommand"
                                  [selectionMode]="multipleOrganizationsSelection ? 'multiple' : 'single'">
        </app-select-organizations>

        <app-select-devices [footer]="false"
                            [scrollHeight]="'flex'"
                            [emptyMessage]="false"
                            [hidden]="!devicesSelection || selectionMode != 4"
                            [deleteCommand]="deleteCommand"
                            [selectionMode]="multipleDevicesSelection ? 'multiple' : 'single'">
        </app-select-devices>

        <app-select-scenarios [footer]="false"
                              [scrollHeight]="'flex'"
                              [emptyMessage]="false"
                              [hidden]="!scenariosSelection || selectionMode != 5"
                              [deleteCommand]="deleteCommand"
                              [selectionMode]="multipleScenariosSelection ? 'multiple' : 'single'">
        </app-select-scenarios>
    </div>
</div>
