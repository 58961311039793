import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService, GlobalEventsService } from "@ramudden/services";
import { Observable, catchError, filter, first, map, switchMap } from "rxjs";

@Injectable()
export class AdminGuard {
    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authenticationService.acquireTokenSilent$().pipe(
            switchMap((_e) => {
                return this.handleActivate(route, state).pipe(
                    map((result) => {
                        return result;
                    }),
                );
            }),
            catchError((_error) => {
                // console.error("acquireTokenSilent$ failed", error);
                return this.handleActivate(route, state).pipe(
                    map((result) => {
                        return result;
                    }),
                );
            }),
        );
    }

    private handleActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
        return this.globalEventsService.isAuthorized$.pipe(
            filter((isAuthorized) => isAuthorized !== null),
            first(),
            map((isAuthorized) => {
                if (!isAuthorized) {
                    console.error("unable to authorize user, redirecting to root.");
                    this.router.navigate(["/"]);
                    return false;
                }

                const authorizationInfo = this.globalEventsService.getAuthorizationInfo();
                if (authorizationInfo && authorizationInfo.isDomainAdministrator) {
                    return true;
                }

                console.error("Not authorized to visit this page");
                this.router.navigate(["/"]);
                return false;
            }),
        );
    }
}
