import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { environment } from "@env/environment";
import { TranslateService } from "@ngx-translate/core";
import { SubscriptionManager } from "@ramudden/core/utils";
import {
    CameraMoveDirection,
    ICameraMetaInfo,
    ICameraMoveOptions,
    ICameraPreset,
} from "@ramudden/data-access/models/camera";
import { ICctvImage } from "@ramudden/data-access/models/cctv-image";
import { ILiveData } from "@ramudden/data-access/models/pinned-data";
import { FilterDescriptor, FilterOperator, SearchParameters } from "@ramudden/data-access/models/search";
import { CameraApi } from "@ramudden/data-access/resource/camera.api";
import { CctvImageApi } from "@ramudden/data-access/resource/cctv-image.api";
import { AuthenticationService, PrimeComponentService } from "@ramudden/services";
import { AngularResizableDirective } from "angular2-draggable";
import { SelectItem } from "primeng/api";
import { firstValueFrom, interval, Subject } from "rxjs";
import { filter } from "rxjs/operators";

class StatusImage {
    constructor(
        readonly timestamp: Date,
        readonly src: string,
    ) {}
}

@Component({
    selector: "app-camera-controls",
    templateUrl: "./camera-controls.component.html",
})
export class CameraControlsComponent implements OnChanges, OnDestroy {
    @ViewChild(AngularResizableDirective) resizableDirective: AngularResizableDirective;

    @Input() liveData: ILiveData; // used so SignalR event can refresh preview
    @Input() deviceId: number;
    @Input() measuringPointId: number;

    @Input() frames = 1;
    @Input() refreshDelayInMs = 1000 * 60 * 5; // 5 minutes
    @Input() loopDelayInMs = 1000;
    @Input() showControls = true;
    @Input() timeoutInMs = 1000 * 60 * 6; // 6 minutes
    @Input() editPresets = false;

    info: ICameraMetaInfo;

    livestreamUrl: string;

    images: StatusImage[];
    image: StatusImage;
    imageNotPresentMessageVisible = false;
    refreshingImages: boolean;
    timeoutHandle: NodeJS.Timeout;
    hasError: boolean;

    selectedPreset: ICameraPreset;
    presets: SelectItem[];
    moving = false;

    minCameraZoom: number;
    maxCameraZoom: number;
    cameraZoom: number;
    refreshing = true;

    historyImages: ICctvImage[];
    galleryImage: ICctvImage;
    galleryImageLoading: boolean;
    currentGalleryImageIndex: number;
    private refreshFinishedSubject$ = new Subject<boolean>();

    private readonly subscriptionManager = new SubscriptionManager();

    get livestream(): boolean {
        return this.info?.supportsLivestream;
    }

    get isLiveTile(): boolean {
        return !!this.measuringPointId;
    }

    constructor(
        readonly translateService: TranslateService,
        private readonly router: Router,
        private readonly cd: ChangeDetectorRef,
        private readonly authenticationService: AuthenticationService,
        private readonly primeComponentService: PrimeComponentService,
        private readonly cctvImageApi: CctvImageApi,
        private readonly cameraApi: CameraApi,
    ) {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                // Needed for when navigating away from device-camera
                // If we also set info=null, the img is removed before livestreamUrl is cleared
                // which also won't kill the stream
                this.livestreamUrl = "";
                this.cd.detectChanges();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        const contextChange = changes["deviceId"] || changes["measuringPointId"];
        if (contextChange) {
            this.clear();
            this.loadCameraInfo();
            this.updateTimeout();
        } else if (changes["liveData"]) {
            if (
                !this.liveData ||
                !this.info ||
                !this.info.isDekimoCctv ||
                !this.refreshing ||
                !changes["liveData"].previousValue
            )
                return;

            // this is only relevant for dekimo devices
            this.loadLatestDekimoImage();
        }
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    loopImage() {
        if (!this.images) {
            this.image = null;
            return;
        }

        let newIndex = !this.image ? 0 : this.images.indexOf(this.image) + 1;

        if (newIndex >= this.images.length) {
            newIndex = 0;
        }

        const hadImage = !!this.image;
        this.image = this.images[newIndex];
        this.cd.detectChanges();

        if (!hadImage) {
            this.updateResize();
        }
    }

    async refreshIfToggled() {
        if (this.refreshing) {
            this.historyImages = null;
            this.currentGalleryImageIndex = null;
            this.galleryImage = null;

            if (this.info.isDekimoCctv) {
                // for dekimo device we simply load the latest image
                this.loadLatestDekimoImage();
                return;
            }

            this.updateTimeout();
            this.refreshCameraImage(true, true);
            return;
        }

        // we need to be careful to fetch images only when all requests are finished (for collecting images in refreshing mode)
        const callback = () => {
            this.stopCamera();
            this.loadHistoryImages();
            this.cd.detectChanges();
        };

        this.image = null;
        this.cd.detectChanges();

        if (!this.refreshingImages) {
            // currently not refreshing images so we're going simply to execute callback
            callback();
        } else {
            // refresh currently in progress so we wait for it to finish
            // to notice that checkbox value has changed
            const subscription = this.refreshFinishedSubject$.subscribe(() => {
                callback();
                subscription.unsubscribe();
            });
        }
    }

    async refreshCameraImage(override = false, showInstantly = false, frameDelayInMs = 1000) {
        if (this.livestream) return;
        this.hasError = false;

        if (!this.deviceId && !this.measuringPointId) {
            this.stopCamera();
            return;
        }

        if (this.refreshingImages && !override) return;

        this.startSubscriptions();

        const oldImages = this.images;

        const images = new Array<StatusImage>();
        this.refreshingImages = true;

        // If no images present, instantly fill
        showInstantly = !this.images || showInstantly;

        const frames = Math.min(this.frames, this.refreshDelayInMs / this.loopDelayInMs);

        for (let i = 0; i < frames; i++) {
            try {
                if (!this.refreshing) {
                    this.refreshingImages = false;
                    this.refreshFinishedSubject$.next(true);
                    return;
                }

                const requestTime = new Date();
                const downloadedFile = await (this.measuringPointId
                    ? this.cameraApi.getImageForMeasuringPoint(this.measuringPointId)
                    : this.cameraApi.getImageForDevice(this.deviceId));

                images.push(new StatusImage(requestTime, downloadedFile.toResourceUrl()));

                if (showInstantly && images.length === 1) {
                    this.images = images;
                    this.loopImage();
                    this.cd.detectChanges();
                }

                if (frameDelayInMs > 0 && i < frames - 1) {
                    const requestDelay = new Date().getTime() - requestTime.getTime();
                    const delayToWait = frameDelayInMs - requestDelay;

                    if (delayToWait > 0) {
                        await new Promise((res) => setTimeout(res, delayToWait));
                    }
                }
            } catch {
                this.stopCamera();
                this.clear();
                this.hasError = true;
                break;
            }
        }

        if (oldImages === this.images) {
            this.images = images;
        }

        this.refreshingImages = false;
        this.refreshFinishedSubject$.next(true);
    }

    clearPresetIfExisting() {
        if (this.selectedPreset && this.selectedPreset.id) {
            this.selectedPreset = null;
        }
    }

    addPreset() {
        const newPreset = {} as ICameraPreset;
        const newPresetItem = this.primeComponentService.createDropdownItem(
            newPreset,
            (x) => x,
            (x) => this.translateService.instant("words.new"),
        );
        this.presets.push(newPresetItem);
        this.selectedPreset = newPreset;
    }

    deletePreset(preset: ICameraPreset) {
        if (preset.id) {
            this.manipulateCamera({
                removeserverpresetno: preset.id,
            });
        }

        this.presets = this.presets.filter((x) => x.value !== preset);

        if (this.selectedPreset === preset) {
            this.selectedPreset = null;
        }
    }

    async savePreset() {
        if (!this.selectedPreset || !this.selectedPreset.name) return;

        if (this.selectedPreset.id) {
            await this.manipulateCamera({
                setserverpresetno: this.selectedPreset.id,
            });
        } else {
            await this.manipulateCamera({
                setserverpresetname: this.selectedPreset.name,
            });
        }

        await this.loadCameraInfo();
    }

    async loadPreset() {
        if (!this.selectedPreset) return;

        await this.manipulateCamera({
            gotoserverpresetno: this.selectedPreset.id,
        });
    }

    private generateLivestreamUrl() {
        if (!this.livestream) {
            this.livestreamUrl = null;
            this.subscriptionManager.remove("livestream-loop");
            return;
        }

        firstValueFrom(this.authenticationService.acquireTokenSilent$()).then((result) => {
            this.livestreamUrl =
                (this.measuringPointId
                    ? this.cameraApi.getLivestreamRouteForMeasuringPoint(this.measuringPointId, result.accessToken)
                    : this.cameraApi.getLivestreamRouteForDevice(this.deviceId, result.accessToken)) +
                "&ts=" +
                Date.now();
        });
    }

    async loadCameraInfo() {
        if (!this.deviceId && !this.measuringPointId) return;

        if (!environment.production) {
            this.showControls = false;
        }

        const onError = () => {
            this.hasError = true;
        };

        const onSuccess = (info: ICameraMetaInfo) => {
            if (!info) return;

            this.info = info;

            if (this.info.isDekimoCctv) {
                this.loadLatestDekimoImage();
                return;
            }

            if (this.info.supportsLivestream) {
                this.refreshing = true;
                this.showControls = false;

                this.generateLivestreamUrl();

                this.updateResize();

                // Refresh URL every so often, because
                // - If server goes offline and online, it will continue after the change
                // - It will close the previous stream (else it becomes huge and takes in a lot of memory)
                // - Doesn't inconvenience end-user (they don't see this happening)
                const livestreamLoopSubscription = interval(1000 * 60).subscribe(() => {
                    this.generateLivestreamUrl();
                });
                this.subscriptionManager.add("livestream-loop", livestreamLoopSubscription);
                return;
            }

            if (info.refreshDelayInSeconds) {
                this.refreshDelayInMs = info.refreshDelayInSeconds * 1000;
                // this.loopDelayInMs = info.refreshDelayInSeconds * 1000;
            }

            this.hasError = false;
            this.minCameraZoom = info.minZoom;
            this.maxCameraZoom = info.maxZoom;
            this.cameraZoom = info.zoom;
            this.presets = this.primeComponentService.createDropdownList(
                info.presets,
                (x) => x,
                (x) => `${x.id} - ${x.name}`,
                false,
            );

            this.clear();
            this.refreshCameraImage();

            this.cd.detectChanges();
        };

        if (this.measuringPointId) {
            this.cameraApi.getInfoForMeasuringPoint$(this.measuringPointId).subscribe(onSuccess, onError);
        } else {
            this.cameraApi.getInfoForDevice$(this.deviceId).subscribe(onSuccess, onError);
        }
    }

    onPresetSelect() {
        this.clearNewPresets();
        this.loadPreset();
    }

    clearNewPresets() {
        this.presets = this.presets.filter((x) => !!x.value);
    }

    async zoom() {
        this.clearPresetIfExisting();

        await this.manipulateCamera({
            zoom: this.cameraZoom,
        });
    }

    async move(direction: CameraMoveDirection) {
        this.clearPresetIfExisting();

        await this.manipulateCamera({
            move: direction,
        });

        if (direction === CameraMoveDirection.Home) {
            await this.loadCameraInfo();
        }
    }

    async manipulateCamera(options: ICameraMoveOptions): Promise<object> {
        if (!environment.production) return;
        if (!this.deviceId && !this.measuringPointId) return;
        if (this.moving) return;

        this.updateTimeout();

        this.moving = true;

        const onSuccess = () => {
            this.moving = false;

            if (!this.livestream) {
                this.refreshCameraImage(true, true, 1000);

                setTimeout(() => {
                    this.refreshCameraImage(true, true);
                }, 5000);
            }
        };

        const onError = () => {
            this.moving = false;
        };

        (this.measuringPointId
            ? this.cameraApi.moveMeasuringPoint$(this.measuringPointId, options)
            : this.cameraApi.moveDevice$(this.deviceId, options)
        ).subscribe(onSuccess, onError);
    }

    updateTimeout() {
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
        }

        this.timeoutHandle = setTimeout(() => {
            this.stopCamera();
            this.timeoutHandle = null;
        }, this.timeoutInMs);
    }

    private updateResize() {
        setTimeout(() => {
            if (!this.resizableDirective) return;
            // resizableDirective sets its currSize on ngAfterViewInit
            // Without an image, height is 0.
            // We force-reinit after loading (and showing) an image
            this.resizableDirective.ngAfterViewInit();
        }, 100);
    }

    private startSubscriptions() {
        if (this.subscriptionManager.has("camera-poll") || this.livestream) return;

        this.refreshing = true;

        const cameraPollSubscription = interval(this.refreshDelayInMs).subscribe(() => {
            this.refreshCameraImage();
        });
        this.subscriptionManager.add("camera-poll", cameraPollSubscription);

        const imageLoopSubscription = interval(this.loopDelayInMs).subscribe(() => {
            this.loopImage();
        });
        this.subscriptionManager.add("image-loop", imageLoopSubscription);
        this.loopImage();
    }

    private stopCamera() {
        this.refreshing = false;
        this.refreshingImages = false;
        this.subscriptionManager.remove("camera-poll");
        this.subscriptionManager.remove("image-loop");
    }

    private clear() {
        this.images = null;
        this.image = null;
        this.stopCamera();
    }

    private loadHistoryImages() {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        if (this.measuringPointId)
            searchParameters.filter.push({
                field: "measuringPointId",
                value: this.measuringPointId,
                operator: FilterOperator.equals,
            } as FilterDescriptor);
        if (this.deviceId)
            searchParameters.filter.push({
                field: "deviceId",
                value: this.deviceId,
                operator: FilterOperator.equals,
            } as FilterDescriptor);

        this.cctvImageApi.search$(searchParameters).subscribe((result) => {
            this.historyImages = result.data;

            if (this.historyImages && this.historyImages.length > 0) {
                this.currentGalleryImageIndex = 0;
                this.galleryImage = this.historyImages[this.currentGalleryImageIndex];
            } else {
                this.currentGalleryImageIndex = null;
            }

            this.cd.detectChanges();
        });
    }

    toNextImage() {
        this.galleryImageLoading = true;
        this.currentGalleryImageIndex--;
        this.galleryImage = this.historyImages[this.currentGalleryImageIndex];
        this.cd.detectChanges();
    }

    toPreviousImage() {
        this.galleryImageLoading = true;
        this.currentGalleryImageIndex++;
        this.galleryImage = this.historyImages[this.currentGalleryImageIndex];
        this.cd.detectChanges();
    }

    private loadLatestDekimoImage() {
        // we fetch latest image, SignalR will notify us when there are new images
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];
        searchParameters.take = 1;
        this.showControls = false;

        if (this.measuringPointId)
            searchParameters.filter.push({
                field: "measuringPointId",
                value: this.measuringPointId,
                operator: FilterOperator.equals,
            } as FilterDescriptor);
        if (this.deviceId)
            searchParameters.filter.push({
                field: "deviceId",
                value: this.deviceId,
                operator: FilterOperator.equals,
            } as FilterDescriptor);

        this.refreshing = true;
        this.cctvImageApi.search$(searchParameters).subscribe((result) => {
            if (!result || result.data.length === 0) {
                this.imageNotPresentMessageVisible = true;
                return;
            }

            this.imageNotPresentMessageVisible = false;
            this.image = {
                src: result.data[0].url,
                timestamp: result.data[0].timestamp,
            } as StatusImage;

            this.cd.detectChanges();
        });

        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
        }
    }

    galleryImageLoaded() {
        this.galleryImageLoading = false;
        this.updateResize(); // hack so resize can work ok
        this.cd.detectChanges();
    }
}
