import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@env/environment";
import { ToastService } from "./toast.service";

@Injectable({
    providedIn: "root",
})
export class ErrorService {
    private _serviceOfflineRedirectUrl: string;
    redirectToStatusOnOffline = true;

    constructor(
        private readonly router: Router,
        private readonly toastService: ToastService,
    ) {}

    clearErrors() {
        this.toastService.clearErrors();
    }

    redirectToPreviousAvailablePage() {
        if (this._serviceOfflineRedirectUrl) {
            this.router.navigate([`${this._serviceOfflineRedirectUrl}`]);
        } else {
            this.router.navigate(["/"]);
        }
    }

    getPreviousAvailablePageAsRawWebsiteUrl(): string {
        let websiteUrl = `${window.location.protocol}//${window.location.hostname}`;

        // If not default port (http / https), add port
        if (window.location.port !== "80" && window.location.port !== "443") {
            websiteUrl += `:${window.location.port}`;
        }

        if (this._serviceOfflineRedirectUrl) {
            websiteUrl += this._serviceOfflineRedirectUrl;
        }

        return websiteUrl;
    }

    async handleError(response: Response | HttpResponse<any>) {
        if (!response) return;

        // Service unavailable
        if (response.status === 503 || response.status === 0) {
            if (this.router.url !== "/status") {
                this._serviceOfflineRedirectUrl = this.router.url;

                if (this.redirectToStatusOnOffline) {
                    if (!environment.production) {
                        console.log("Going to status page", response);
                    }

                    setTimeout(() => {
                        this.router.navigate(["/status"]);
                    });
                }
            }

            return;
        }

        if (response.status === 404) return;

        const error = await this.getErrorString(response);
        if (!error) return;

        this.toastService.error(error);
    }

    async getErrorString(response: any): Promise<string> {
        let error = response.statusText || response.message || response;

        try {
            let respError = (response as any).error;
            if (respError) {
                if (typeof respError === "string") {
                    error = respError;

                    respError = JSON.parse(respError);
                }

                if (respError.Message === "") respError.Message = "errors.general";

                if (respError.Message && typeof respError.Message === "string") {
                    error = respError.Message;
                }
            } else if (response.json) {
                const bodyJson = await response.json();
                if (bodyJson) {
                    if (bodyJson.Message && typeof bodyJson.Message === "string") {
                        error = bodyJson.Message;
                    } else if (typeof bodyJson === "string") {
                        error = bodyJson;
                    }
                }
            }

            if (respError.type === "application/json") {
                const reader = new FileReader();

                reader.onloadend = () => {
                    error =
                        reader.result instanceof ArrayBuffer
                            ? "Result was an ArrayBuffer"
                            : JSON.parse(reader.result as string).Message;
                    this.toastService.error(error || "errors.general");
                };

                reader.readAsText(respError);
                return null;
            }
        } catch (ex) {}

        return error;
    }
}
