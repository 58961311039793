import { IScenario, ScenarioCreator, ScenarioUpdater } from "../models/scenario";
import { SearchParameters } from "../models/search";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ScenarioApi extends ApiService<IScenario, ScenarioCreator, ScenarioUpdater> {

    getRoute(): string {
        return "Scenarios";
    }

    getToolbox$(scenarioId: number): Observable<string> {
        const url = `${super.getUrl()}/${scenarioId}/Toolbox`;

        return this.http.get<string>(url, { responseType: "text" as any });
    }

    getBlocks$(scenarioId: number): Observable<string> {
        const url = `${super.getUrl()}/${scenarioId}/Blocks`;

        return this.http.get<string>(url, { responseType: "text" as any });
    }

    getToolboxForMeasuringPoints$(measuringPointIds: number[]): Observable<string> {
        const url = `${super.getUrl()}/Toolbox`;

        const searchParameters = new SearchParameters();
        searchParameters.queryParams = {
            measuringPointIds: measuringPointIds.join(",")
        };

        const options = this.createOptions(null, searchParameters);
        options.responseType = "text";

        return this.http.get<string>(url, options);
    }

    getBlocksForMeasuringPoints$(measuringPointIds: number[]): Observable<string> {
        const url = `${super.getUrl()}/Blocks`;

        const searchParameters = new SearchParameters();
        searchParameters.queryParams = {
            measuringPointIds: measuringPointIds.join(",")
        };

        const options = this.createOptions(null, searchParameters);
        options.responseType = "text";

        return this.http.get<string>(url, options);
    }
}