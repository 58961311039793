import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class TouchService {
    private readonly longTouchTime = 500;
    private touchStartTime: Date;

    isTouchDevice(): boolean {
        return !!document.documentElement.ontouchstart || navigator.maxTouchPoints > 0;
    }

    notifyMouseDown() {
        this.touchStartTime = new Date();
    }

    /**
     * On a touch device, a long touch is used to mimic a right-click
     */
    isLongTouch(): boolean {
        if (!this.isTouchDevice()) return false;
        const result = this.touchStartTime && (new Date().getTime() - this.touchStartTime.getTime()) > this.longTouchTime;
        this.touchStartTime = null;
        return result;
    }
}