export enum MeasuringPointLinkStatus {
    Linked = "linked",
    NotLinked = "notLinked",
}

export enum FilterType {
    Organization = "organizations",
    Project = "projects",
    Type = "types",
    Status = "status"
}
