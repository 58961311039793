<div style="background-color: #eee;">
    <div *ngIf="!isEditing">
        <pre style="margin: 0; padding:2px;">{{model | json}}</pre>
        <button class="m-btn m-btn-secondary"
                style="padding-left: 0; background-color: transparent;"
                (click)="editObject()">
            <app-svg-icon icon="edit"></app-svg-icon>
            {{'form.edit' | translate}}
        </button>
    </div>
    <div *ngIf="isEditing"
         style="display:grid;">
        <textarea [(ngModel)]="jsonData"
                  (input)="onInputChange($event.target.value)"
                  style="resize:none; background-color: transparent; font-family: monospace; line-height: 1.5; font-size: 0.8125rem; border:none; outline:none;"
                  [rows]="getLineCount()"></textarea>
        <div *ngIf="!isValidJson"
             style="color: red;">Invalid JSON</div>
        <button *ngIf="isValidJson"
                class="m-btn m-btn-primary"
                style="width: max-content;"
                (click)="updateObject()">
            <app-svg-icon icon="checkmark"></app-svg-icon>
            {{'form.ok' | translate}}
        </button>
    </div>
</div>