import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { isDuration } from "moment";
import moment from "moment";

export class DurationUtils {
    static fromMilliseconds(milliseconds: number): moment.Duration | null {
        if (Number.isNaN(milliseconds)) return null;

        return moment.duration().add(milliseconds, "milliseconds");
    }

    static fromSeconds(seconds: number): moment.Duration | null {
        if (Number.isNaN(seconds)) return null;

        return moment.duration().add(seconds, "seconds");
    }

    static toMilliseconds(d: moment.Duration): number | void{
        if (!d) return;
        return d.asMilliseconds();
    }

    static toMinutes(d: moment.Duration): number {
        if (d.asMinutes) return d.asMinutes();

        return moment.duration(d).asMinutes();
    }

    static fromDate(date: Date): moment.Duration | null {
        if (!date || !(date instanceof Date)) return null;

        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const milliseconds = date.getMilliseconds();

        return moment
            .duration()
            .add(hours, "hours")
            .add(minutes, "minutes")
            .add(seconds, "seconds")
            .add(milliseconds, "milliseconds");
    }

    static toDate(duration: moment.Duration): Date | null {
        if (!duration) return null;

        const date = new Date();
        date.setHours(duration.hours());
        date.setMinutes(duration.minutes());
        date.setSeconds(duration.seconds());
        date.setMilliseconds(duration.milliseconds());
        return date;
    }

    static toString(duration: moment.Duration | Date | null, format = "HH:mm:ss.SSS"): string | Date | null {
        if (duration instanceof Date) {
            duration = DurationUtils.fromDate(duration);
        }

        if (!isDuration(duration)) return duration;
        const utc = moment.utc(duration.asMilliseconds());
        const formatted = utc.format(format);

        return formatted;
    }

    static isValid(input: string): boolean {
        const parts = input.split(":");
        if (parts.length != 3) return false;

        // Typescript has weird number parsing which makes validation much more complex than it should be.
        // First we check if the general form is correct (hh:mm:ss or )
        const match = /^\d+:\d+:\d+$/.test(input);
        if (!match) return false;

        const hour = parseInt(parts[0]);
        if (hour >= 24) return false;

        const minute = parseInt(parts[1]);
        if (minute >= 60) return false;

        const second = parseInt(parts[2]);
        if (second >= 60) return false;

        return true;
    }

    static durationValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value) return null;

            if (!this.isValid(value)) return { invalidDuration: true };
            return null;
        };
    }
}
