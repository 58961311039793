import { IServiceModelBase } from "./servicemodelbase";

export interface IDatePeriodSet extends IServiceModelBase {
    name: string;
    periods: IDatePeriod[];
}

export interface IDatePeriod extends IServiceModelBase {
    name: string;
    typeId: string;
    dates: Date[];
}

export class DatePeriodSetCreator {
    id: number;
    name: string;
    periods = new Array<DatePeriodCreator>();
}

export class DatePeriodCreator {
    id: number;
    name: string;
    typeId: string;
    dates: string[];
    datePeriodSetId: number;
}