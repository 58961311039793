import { Injectable } from "@angular/core";
import { IParkingBan, ParkingBanCreator, ParkingBanUpdater } from "../models/parking-ban";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root"
})
export class ParkingBanApi extends ApiService<IParkingBan, ParkingBanCreator, ParkingBanUpdater> {

    getRoute(): string {
        return "ParkingBans";
    }
}