import { Injectable } from "@angular/core";
import {
    GeneralDialogComponent,
    GeneralDialogOptions,
} from "@portal/shared/components/dialog/general-dialog.component";
import { DownloadedFile } from "./download-file.service";

@Injectable({
    providedIn: "root",
})
export class ModalService {
    private dialog: GeneralDialogComponent;

    image(title: string, image: string | DownloadedFile, onOk: () => void = null) {
        const options = {
            title: title,
            image: image instanceof DownloadedFile ? image.toResourceUrl() : image,
            rejectVisible: false,
        } as GeneralDialogOptions;

        this.open(options);
    }

    info(body: string, title: string = "general.info", onOk: (doNotShowAgain: boolean) => void = null) {
        const options = {
            body: body,
            title: title,
            icon: "info",
            rejectVisible: false,
            doNotShowAgain: false,
            doNotShowAgainVisible: true,
            accept: () => {
                if (onOk) {
                    onOk(this.dialog.options.doNotShowAgain);
                }
            },
        } as GeneralDialogOptions;

        this.open(options);
    }

    alert(body: string, title: string = "general.warning", onOk: () => void = null) {
        const options = {
            body: body,
            title: title,
            icon: "alert",
            rejectVisible: false,
            accept: onOk,
        } as GeneralDialogOptions;

        this.open(options);
    }

    error(body: string, title: string = "general.error") {
        const options = {
            body: body,
            title: title,
            icon: "error",
            rejectVisible: false,
        };

        this.open(options);
    }

    confirm(body: string, onOk: () => void, onCancel: () => void = null, icon: "info" | "alert" = "info") {
        const options = {
            body: body,
            title: "general.info",
            accept: onOk,
            icon: icon,
            reject: onCancel,
        } as GeneralDialogOptions;

        this.open(options);
    }

    confirmAsync(body: string, icon: "info" | "alert" = "info"): Promise<boolean> {
        return new Promise((resolve) => {
            const onOk = () => {
                resolve(true);
            };
            const onCancel = () => {
                resolve(false);
            };

            this.confirm(body, onOk.bind(this), onCancel.bind(this), icon);
        });
    }

    delete(body: string, onDelete: () => void, onCancel: () => void = null) {
        const options = {
            body: body,
            title: "form.delete",
            icon: "delete",
            accept: onDelete,
            reject: onCancel,
            acceptLabel: "form.delete",
        } as GeneralDialogOptions;

        this.open(options);
    }

    open(options: GeneralDialogOptions) {
        if (!this.dialog) {
            console.error(`Attemped to show dialog ${options.title} before component was registered`);
            return;
        }

        options.doNotShowAgain = false;
        this.dialog.show(options);
    }

    registerDialog(dialog: GeneralDialogComponent) {
        this.dialog = dialog;
    }
}
