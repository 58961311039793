import { Component, ElementRef, Input, inject } from "@angular/core";

@Component({
    selector: "app-loading-spinner",
    templateUrl: "./loading-spinner.component.html"
})
export class LoadingSpinnerComponent {
    @Input() tooltip: string;
    @Input() divStyle?: any;
    @Input() id?: string = undefined;

    private ele: ElementRef<HTMLElement> = inject(ElementRef<HTMLElement>);

    constructor() {
    }
}
