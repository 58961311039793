import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MeasuringPointLinkStatus } from "@ramudden/data-access/models/map-filter";

@Pipe({ name: "translateMeasuringPointStatus", pure: false })
export class TranslateMeasuringPointStatusPipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}

    transform(option: any): MeasuringPointLinkStatus {
        return this.getMeasuringPointStatus(option);
    }

    private getMeasuringPointStatus(option: any): MeasuringPointLinkStatus {
        return this.translateService.instant(`mapFilters.${option}`);
    }
}
