import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from "@angular/forms";

export class SigncoFormUtils {
    static getFormArray(formGroup: UntypedFormGroup, path: Array<string | number> | string): SigncoFormArray {
        return formGroup.get(path) as SigncoFormArray;
    }
}

export class SigncoFormGroup extends UntypedFormGroup {
    submitted: boolean;
    validating: boolean;

    get(path: Array<string | number> | string): SigncoFormControl {
        return super.get(path) as SigncoFormControl;
    }
}

export class SigncoFormControl extends UntypedFormControl {
    errorMessages: string[];
    showErrors: boolean;
    hidden: boolean;
}

export class SigncoFormArray extends UntypedFormArray {
    showErrors: boolean;
}