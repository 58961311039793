<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()"
          [style]="autoWidth ? {'width':'auto'} : {}">

    <p-header>
        {{title | translate}}
    </p-header>

    <ng-container *ngIf="data">

        <ng-container *ngIf="type === 0">
            {{data | translate}}
        </ng-container>

        <app-code-highlight *ngIf="type === 1 || type === 2"
                            [code]="data | json"
                            [language]="type === 1 ? 'csharp' : 'json'"></app-code-highlight>

        <div *ngIf="type === 3"
             [innerHTML]="data | translateModel:translateService.currentLang | safeHtml"></div>

    </ng-container>
</p-dialog>