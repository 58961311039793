import { Injectable } from "@angular/core";
import { RealtimeConnection } from "./realtime-connection";

import { INewNotificationAddedArguments } from "@ramudden/data-access/models/new-notification-added-arguments";
import { Observable, Subject, Subscription } from "rxjs";
import { SignalRService } from "./signalR.service";

@Injectable({
    providedIn: "root",
})
export class NotificationRealtimeService {
    private connection: RealtimeConnection;
    private readonly newNotificationAddedSubject = new Subject<INewNotificationAddedArguments>();
    private readonly allNotificationsSeenSubject = new Subject<void>();

    constructor(private readonly signalRService: SignalRService) {}

    subscribeToNewNotifications(callback?: (res: INewNotificationAddedArguments) => void): Subscription {
        const onSuccess = (data: INewNotificationAddedArguments) => {
            callback(data);
        };

        const onError = () => {};
        const subscription = this.onNewNotificationAdded().subscribe(onSuccess, onError);

        this.subscribeToSignalR();
        return subscription;
    }

    subscribeToAllNotificationsSeen(callback?: () => void): Subscription {
        const onSuccess = () => {
            callback();
        };

        const onError = () => {};
        const subscription = this.onAllNotificationsSeen().subscribe(onSuccess, onError);

        this.subscribeToSignalR();
        return subscription;
    }

    unsubscribe() {
        // Note that we don't unsubscribe and resubscribe while the user is navigating between the different panels. We keep the subscription as long as the browser is connected
    }

    private onAllNotificationsSeen(): Observable<void> {
        return this.allNotificationsSeenSubject.asObservable();
    }

    private onNewNotificationAdded(): Observable<INewNotificationAddedArguments> {
        return this.newNotificationAddedSubject.asObservable();
    }

    private subscribeToSignalR(): void {
        if (this.connection) return;

        // ContextId is not supported for locations, we pass 0
        // Later we might pass the project-id to only get updates about the projects we're looking at
        this.connection = this.signalRService.CreateConnection(0, "/v1/realtime/Notification");

        this.connection.onRegisterEvents = (c) => {
            c.connection.on("newNotificationAdded", (id: number, args: INewNotificationAddedArguments) => {
                this.newNotificationAddedSubject.next(args);
            });
            c.connection.on("allNotificationsSeen", () => {
                this.allNotificationsSeenSubject.next();
            });
        };

        this.connection.onDetachEvents = (c) => {
            c.connection.off("newNotificationAdded");
            c.connection.off("allNotificationsSeen");
        };

        this.signalRService.startConnection(this.connection);
    }
}
