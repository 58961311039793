export interface ISearchResult<T> {
    total: number;
    startIndex: number;
    itemsCount: number;
    data: T[];
}

export interface IListResult<T> {
    itemsCount: number;
    data: T[];
}

export class SortDirection {
    static ascending = "Asc";
    static descending = "Desc";
}

export class SortDescriptor {
    constructor(
        public dir: SortDirection,
        public field: string) {
    }
}

export class FilterOperator {
    static equalTo = "Eq"; // equal to
    static notEqualTo = "Neq"; // not equal to
    static isNull = "IsNull"; // is equal to null
    static isNotNull = "IsNotNull"; // is not equal to null
    static lessThan = "Lt"; // less than
    static lessThanOrEqualTo = "Lte"; // less than or equal to
    static greaterThan = "Gt"; // greater than
    static greaterThanOrEqualTo = "Gte"; // greater than or equal to
    static startsWith = "StartsWith"; // only for string fields
    static endsWith = "EndsWith"; // only for string fields
    static contains = "Contains"; // only for string fields
    static doesNotContain = "DoesNotContain"; // only for string fields
    static isEmpty = "IsEmpty"; // only for string fields
    static isNotEmpty = "IsNotEmpty"; // only for string fields
    static inside = "Inside"; // only for dates
    static in = "In"; // for arrays

    // primeNG, "startsWith", "contains", "endsWidth", "equals", "notEquals", "in", "gt", "gte", "lt", "lte".
    static equals = FilterOperator.equalTo;
    static notEquals = FilterOperator.notEqualTo;
    // static in = FilterOperator.contains;

    [s: string]: string;
    equalTo = FilterOperator.equalTo;
    notEqualTo = FilterOperator.notEqualTo;
    isNull = FilterOperator.isNull;
    isNotNull = FilterOperator.isNotNull;
    lessThan = FilterOperator.lessThan;
    lessThanOrEqualTo = FilterOperator.lessThanOrEqualTo;
    greaterThan = FilterOperator.greaterThan;
    greaterThanOrEqualTo = FilterOperator.greaterThanOrEqualTo;
    startsWith = FilterOperator.startsWith;
    endsWith = FilterOperator.endsWith;
    contains = FilterOperator.contains;
    doesNotContain = FilterOperator.doesNotContain;
    isEmpty = FilterOperator.isEmpty;
    isNotEmpty = FilterOperator.isNotEmpty;
    inside = FilterOperator.inside;
    in = FilterOperator.in;

    equals = FilterOperator.equals;
    notEquals = FilterOperator.notEquals;
}

export class FilterDescriptor {
    public value: string | Date | boolean | number | string[] | Date[];

    constructor(
        public field: string = "",
        value: string | number | Date | boolean | string[] | Date[] = "",
        public operator: string | FilterOperator = FilterOperator.equals) {

        if (typeof value === "boolean") {
            value = value.toString();
        }

        this.value = value;
    }
}

export class SearchParameters {
    skip?: number;
    take?: number;
    sort?: SortDescriptor[];
    filter?: FilterDescriptor[];
    search?: string;
    includeObsolete?: boolean;
    queryParams?: { [key: string]: any };
}

export class ExistsValue {
    value: string;
    ownerId?: number;
    parentOrganizationId?: number;
    id?: number;

    constructor(value: string) {
        this.value = value;
    }
}

export class ExistsResult {
    result: boolean;
}

export class Includes {
    constructor(
        public model: string,
        public property: string) {

    }
}

export class ServiceRequestIncludes {

    private includes = [];

    includeAll = false;

    add(model: string, property: string): this {
        this.includes.push(new Includes(model, property));

        return this;
    }

    getHeaders(): { key: string, value: string }[] {
        const headers = [];

        if (this.includeAll) {
            headers.push({ key: "include-all", value: "true" });
            return headers;
        }

        headers.push({ key: "include-all", value: "false" });

        if (this.includes.length > 0) {
            const models = this.includes.map(include => include.model);
            const distinctModels = models.filter((value, index) => models.indexOf(value) === index);

            distinctModels.forEach(model => {
                const properties = this.includes.filter(include => include.model === model).map(include => include.property).join();
                headers.push({ key: `include-${model}`, value: properties });
            });
        }

        return headers;
    }
}

export class ServiceRequestOptions {
    includes = new ServiceRequestIncludes();

    getHeaders() {
        const headers = this.includes.getHeaders();

        return headers;
    }
}
