import { MeasuringPointExceptionalDayUpdater } from "./measuring-point-exceptional-day";
import { IServiceModelBase } from "./servicemodelbase";
import { IUserSummary } from "./user";
import { Duration } from "moment";
import { IDevice } from "./device";
import { VehicleSubType } from "./vehicle";
import { AnalysisType, IMeasuringPointNavigator } from "./measuring-point";

export enum ProcessStatus {
    Unprocessed = "unprocessed",
    Success = "success",
    Error = "error"
}

export interface IUpload extends IServiceModelBase {
    id: number;
    createDate: Date;
    url: string;
    filename: string;
    fileSize: number;
    statusId: ProcessStatus;
    processMessage: string;
    firstData: Date;
    lastData: Date;
    pulseCount: number;
    pulseConversionRate: number;
    analyzerConfiguration: AnalyzerConfiguration;
    measuringPoint: IMeasuringPointNavigator;
    vehicleCount: number;
    tube1PulseRatio: number;
    skippedPulseRatio: number;
}

export class UploadCreator {
    measuringPointId: string;
}

export class IFilterConfiguration {
    excludeLight: boolean;
    excludeMedium: boolean;
    excludeHeavy: boolean;
    excludeBike: boolean;
    excludeMoped: boolean;
    excludePedestrian: boolean;

    excludeCarForward: boolean;
    excludeCarReverse: boolean;
    excludeBikeForward: boolean;
    excludeBikeReverse: boolean;
}

export interface ITubeXPosConfiguration {
    tube3: string;
    tube4: string;
    tube3XOffsetCm: number;
    tube3YOffsetCm: number;
    tube3AngleDegree: number;
    tube4XOffsetCm: number;
    tube4YOffsetCm: number;
    tube4AngleDegree: number;
    drivingLaneWidthCm: number;
}

export enum DevicePosition {
    Left = "left",
    Right = "right"
}

export interface IBikeXPosConfiguration {
    tube3: string;
    devicePosition: DevicePosition;
    drivingLaneWidthCm: number;
    tube2LengthCm: number;
    tube3LengthCm: number;
    tube3XOffsetCm: number;
    tube3YOffset1Cm: number;
    tube3YOffset2Cm: number;
    tube3OffsetInMilliseconds: number;
}

export interface IGpioPins {
    inputId: string;
    pinId: number;
    inverse: boolean;
}

export interface IExternalInputToGpioLink {
    simulationInterval: Duration;
    pins: IGpioPins[];
}

export interface IPinState {
    pinId: number;
    state: boolean;
}

export interface IModeToPinStates {
    modeId: string;
    pinStates: IPinState[];
}

export interface IModeToGpioLink {
    modes: IModeToPinStates[];
}


export class AnalyzerConfiguration {
    tubeConfiguration: TubeConfiguration;
    carConfiguration: CarConfiguration;
    bikeConfiguration: BikeConfiguration;
    carAndBikeConfiguration: CarAndBikeConfiguration;
    pedestrianConfiguration: PedestrianConfiguration;
    carXPosConfiguration: CarXPosConfiguration;
    cameraDetectionConfiguration: CameraDetectionConfiguration;
    filterConfiguration: IFilterConfiguration;
    collectRConfiguration: CollectRConfiguration;
    tubeXPosConfiguration: ITubeXPosConfiguration;
    bikeXPosConfiguration: IBikeXPosConfiguration;
    externalInputToGpioLink: IExternalInputToGpioLink;
    modeToGpioLink: IModeToGpioLink;
    dekimoDisplayLink: DekimoDisplayLink;
    trafficLightLink: TrafficLightLink;
    type: AnalysisType;
    canDetectCars: boolean;
    canDetectBikes: boolean;
}

export class CarXPosConfiguration {
    wheelWidthCm: { [key: string]: number };
}

export class TubeConfiguration {
    tube1: string;
    tube2: string;
    tubeDistanceCm: number;
    tube1OffsetInMilliseconds: number;
    tube2OffsetInMilliseconds: number;
    tube1OffsetOnlyWhenNotClipped: boolean;
    tube2OffsetOnlyWhenNotClipped: boolean;
}

export class BikeConfiguration {
    hasCars: boolean;
    isRealtime: boolean;
    analyzeForwardDirection: boolean;
    analyzeReverseDirection: boolean;
}

export class CarConfiguration {
    legalSpeed: number;
    analyzeForwardDirection: boolean;
    analyzeReverseDirection: boolean;
}

export class CarAndBikeConfiguration {
    hasCars: boolean;
    hasBikes: boolean;
    hasSignificantPedestrians: boolean;
    hasSignificantTrucks: boolean;
    legalSpeed: number;
}

export class PedestrianConfiguration {
    analyzeForwardDirection: boolean;
    analyzeReverseDirection: boolean;
}

export class CameraDetectionConfiguration {
    filter: string;
    analyzeForwardDirection: boolean;
    analyzeReverseDirection: boolean;
    reverseDirection: boolean;
}

export class CollectRConfiguration {
    mappingEnabled: boolean;
    mapping: { [classId: number]: VehicleSubType };
}

export class DekimoDisplayLink {
    fieldLinks: TotemFieldLink[];
}

export class TotemFieldLink {
    totemFieldId: number;
    displaySide: DisplaySide;
    startTile: number;
}

export enum DisplaySide {
    Front = "front",
    Back = "back"
}

export class TrafficLightLink {
    Id: string;
}

export interface IUploadDetailsDialogData extends IUpload {
    createDate: Date;
    url: string;
    filename: string;
    fileSize: number;
    statusId: ProcessStatus;
    processMessage: string;
    firstData: Date;
    lastData: Date;
    analyzerConfiguration: AnalyzerConfiguration;
    measuringPoint: IMeasuringPointNavigator;
    vehicleCount: number;

    creator: IUserSummary;
    device: IDevice;
    fileFormat: string;
    firstVehicle: Date;
    lastVehicle: Date;
    status: string;
    measuringPointId: string;
}
export class UploadParameters {
    analyzerConfiguration: AnalyzerConfiguration;
    exceptionalDates: MeasuringPointExceptionalDayUpdater[];
}

export class UploadResult {
    readonly success: boolean;

    constructor(
        readonly filename: string,
        readonly error: string = null,
        readonly warning: string = null) {

        this.success = !this.error && !this.warning;
    }
}

export class IUploadDailyMetric {
    date: Date;
    deviceId: number;
    measuringPointId: number;
    pulseCount: number;
    skippedPulseCount: number;
    tube1Count: number;
    tube2Count: number;
    pulsesUsedInDetection: number;
    tube1Ratio: number;
    pulseConversionRate: number;
    skippedPulseRatio: number;
}