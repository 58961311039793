<div class="m-column-filter-clear"
     title=" {{column.filterComponent.currentFilterString}}"
     *ngIf="column?.filterComponent?.currentFilterString && column.filterType !== 6"
     (click)="column.filterComponent.open($event)">

    <span class="m-column-filter-clear-label">
        {{column.filterComponent.currentFilterString}}
    </span>

    <app-svg-icon icon="close"
                  (click)="column.filterComponent.clearFilter($event)">
    </app-svg-icon>
</div>