<div class="m-input-group">
    <input type="text"
           [(ngModel)]="searchText"
           (focus)="true"
           class="p-inputtext"
           (appDebounce)="filter($event)"
           [debounceTime]="200"
           placeholder="{{placeholder | translate}}"
           #textInput>

    <div class="m-input-group-addon">
        <i class="fa fa-search"
           *ngIf="!searchText"
           aria-hidden="true"></i>

        <button type="button"
                class="m-btn m-btn-square"
                style="min-height: unset;"
                (click)="clearFilter()"
                *ngIf="searchText">
            <i class="fa fa-times"
               aria-hidden="true"></i>
        </button>
    </div>
</div>