export class ICameraConfiguration {
    url: string;
    username: string;
    password: string;
    refreshDelayInSeconds?: number;
    supportsLivestream?: boolean;
    deviceId?: number;
}

export enum CameraMoveDirection {
    Home = "home",
    Up = "up",
    Down = "down",
    Left = "left",
    Right = "right",
    UpLeft = "upleft",
    UpRight = "upright",
    DownLeft = "downleft",
    DownRight = "downright",
    Stop = "stop"
}

export class ICameraMoveOptions {
    move?: CameraMoveDirection;
    pan?: number;
    tilt?: number;
    zoom?: number;
    focus?: number;
    rpan?: number;
    rtilt?: number;
    rzoom?: number;
    rfocus?: number;
    gotoserverpresetname?: string;
    gotoserverpresetno?: number;
    setserverpresetname?: string;
    setserverpresetno?: number;
    removeserverpresetname?: string;
    removeserverpresetno?: number;
}

export class ICameraMetaInfo {
    isDekimoCctv: boolean;
    supportsLivestream?: boolean;
    refreshDelayInSeconds?: number;
    minZoom: number;
    maxZoom: number;
    zoom: number;
    presets: ICameraPreset[];
}

export class ICameraPreset {
    id: number;
    name: string;
}