import { ICoordinate } from "../location";
import { ProjectStatus } from "../project";

export interface IProjectSummary {
    id: number;
    organizationId: number;
    name: string;
    from?: Date;
    until?: Date;
    currentStatus?: ProjectStatus;
    measuringPoints: IProjectSummaryMeasuringPoint[];
}

export interface IProjectSummaryMeasuringPoint {
    id: number;
    coordinate: ICoordinate;
    analysisTypeId: string;
}
