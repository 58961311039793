<div class="journal">
    <div class="journal__header px-16 py-8">
        <h3>{{"table.filters" | translate}}</h3>

        <div class="flex flex-wrap align-center gap-8">
            <app-table-filter *ngIf="showProjectFilter()"
                              [column]="projectColumn"
                              [table]="table"
                              [isOutsideTable]="true"></app-table-filter>

            <app-table-filter [column]="categoriesColumn"
                              [table]="table"
                              [isOutsideTable]="true"></app-table-filter>

            <app-table-filter [column]="timestampColumn"
                              [table]="table"
                              [isOutsideTable]="true"></app-table-filter>

            <app-table-filter *ngIf="project"
                              [column]="includeAllColumn"
                              [table]="table"
                              [isOutsideTable]="true"></app-table-filter>
        </div>

        <div class="m-btn-group m-btn-group-right">
            <button class="m-btn m-btn-secondary"
                    (click)="addNew()"
                    *ngIf="createCommand && !readonly"
                    id="add_log_button">
                <app-svg-icon icon="add">
                </app-svg-icon>
                {{'journal.add' | translate}}
            </button>
        </div>
    </div>

    <div class="journal__body"
         appFileDragAndDrop
         (fileDropped)="createCommand && !readonly && onFileDropped($event)">

        @if(data.length === 0) {
        <div class="journal__drop">
            <img class="journal__drop__image"
                 src="../../../../../assets/icons/drop.svg">
            <span class="journal__drop__label">{{"general.dropFilesHere" | translate}}</span>
        </div>
        }

        <p-table [hidden]="data.length === 0"
                 class="journal__table"
                 id="journal_table"
                 [value]="data"
                 [columns]="relevantColumns"
                 [rows]="internalRowCount"
                 [styleClass]="styleClass"
                 [loading]="loading"
                 [totalRecords]="total"
                 [paginator]="paginator"
                 [pageLinks]="pageLinks"
                 [scrollable]="scrollable"
                 scrollHeight="flex"
                 [lazy]="isLazy"
                 (onRowSelect)="onRowSelect($event)"
                 (onRowUnselect)="onRowDeselect($event)"
                 (onLazyLoad)="loadTableRows($event)"
                 [selectionMode]="selectionMode"
                 [metaKeySelection]="true"
                 dataKey="id"
                 sortField="timestamp"
                 [sortOrder]="-1"
                 #tableRef>

            <ng-template pTemplate="emptymessage">
                <tr *ngIf="!loading && emptyMessage"
                    class="m-table-empty-message-row">
                    <td class="m-table-empty-message-cell">
                        <div>
                            <img class="m-table-empty-message-image"
                                 src="../../../../../assets/icons/drop.svg">
                        </div>
                        <div class="mt-8">
                            {{"general.dropFilesHere" | translate}}
                        </div>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="paginatorleft">
                <ng-container *ngIf="total">
                    {{"table.total" | translate}}: {{total}}
                </ng-container>
            </ng-template>

            <ng-template pTemplate="summary"
                         *ngIf="footer">
                <ng-container *ngIf="total && !paginator">
                    {{"table.total" | translate}}: {{total}}
                </ng-container>
            </ng-template>

            <ng-template pTemplate="body"
                         let-journal
                         let-rowIndex="rowIndex"
                         class="test">

                <tr [pSelectableRow]="journal"
                    [pSelectableRowIndex]="rowIndex">
                    <td style="padding: 8px;">
                        <div class="m-card p-16 journal__entry">
                            <div class="journal__entry__info">
                                <div class="row">
                                    <p class="col-4"><strong>{{'general.createdOn' | translate}}</strong></p>
                                    <p *ngIf="journal.timestamp"
                                       class="col-8"
                                       style="margin-top:0">
                                        {{ journal.timestamp | momentDateTime:false:false:translateService.currentLang }}
                                        <span *ngIf="journal.creator">
                                            (<app-navigator [user]="journal.creator"></app-navigator>)
                                        </span>
                                    </p>
                                </div>

                                <div class="row mt-8"
                                     *ngIf="journal.lastModificationDateTime">
                                    <p class="col-4"><strong>{{'general.modifiedOn' | translate}}</strong></p>
                                    <p class="col-8"
                                       style="margin-top:0">
                                        {{ journal.lastModificationDateTime | momentDateTime:false:false:translateService.currentLang }}
                                        <span *ngIf="journal.lastModifiedBy">
                                            (<app-navigator [user]="journal.lastModifiedBy"></app-navigator>)
                                        </span>
                                    </p>
                                </div>

                                <div class="row mt-8"
                                     *ngIf="canSetProject">
                                    <p class="col-4"><strong>{{'journal.project' | translate}}:</strong></p>
                                    <p class="col-8"
                                       style="margin-top:0"
                                       *ngIf="canSetProject">{{ journal.project?.name || ' ' }}</p>
                                </div>

                                <div class="row mt-8"
                                     *ngIf="journal.category || journal.subCategory">
                                    <p class="col-4"><strong>{{'journal.category' | translate}}:</strong></p>
                                    <p class="col-8"
                                       style="margin-top:0">{{ journal.category.codeStringResourceId | translateModel:translateService.currentLang }}<ng-container *ngIf="journal.category && journal.subCategory"> - {{ journal.subCategory.codeStringResourceId | translateModel:translateService.currentLang }}</ng-container>
                                    </p>
                                </div>

                                <p *ngIf="journal.isAdminOnly && canManageAdminJournal"
                                   class="mt-8">
                                    <app-svg-icon icon="key-admin"
                                                  tooltip="Admin only">
                                    </app-svg-icon>
                                </p>
                            </div>


                            <div class="journal__entry__remarks">
                                <p><strong>{{'journal.remarks' | translate}}</strong></p>
                                <p>{{ (journal.remarks ? journal.remarks : '/') }}</p>

                                @if(canManageAdminJournal) {
                                <p><strong>{{'journal.adminRemarks' | translate}}</strong></p>
                                <p>{{ (journal.adminRemarks ? journal.adminRemarks : '/') }}</p>
                                }
                            </div>

                            <div class="journal__entry__actions flex gap-8">
                                <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                                    <app-icon-button [text]="command.text"
                                                     [icon]="command.icon"
                                                     [disabled]="command.disabledFunc()"
                                                     (click)="command.click(journal)"
                                                     *ngIf="command.rowValidFunc(journal)">
                                    </app-icon-button>
                                </ng-container>
                            </div>

                            <app-attachment-overview class="journal__entry__attachments"
                                                     [context]="journal"></app-attachment-overview>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>
</div>

<app-journal-dialog></app-journal-dialog>