import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { camelCase } from "lodash";

@Component({
    selector: "app-icon-button",
    templateUrl: "./icon-button.component.html"
})
export class IconButtonComponent implements OnInit, OnChanges {
    @ViewChild("anchor", { static: true }) anchor: ElementRef<HTMLAnchorElement>;

    @Input() styleClass = "m-btn m-btn-square";
    @Input() style: any;
    @Input() icon?: string;
    @Input() fill?: string;
    @Input() showSpinner = false;
    @Input() text?: string;
    @Input() label?: string;
    @Input() labelPosition: "left" | "right" = "right";
    @Input() disabled = false;
    @Input() iconClass?: string;
    @Input() iconStyle?: any;
    @Input() iconDivClass?: string;
    @Input() iconDivStyle?: any;
    @Input() tooltipPosition: "left" | "top" | "right" | "bottom" = "top";
    @Input() id?: string;

    _iconDivClass: string;
    _styleClass: string;

    @Output() click = new EventEmitter<void>();

    ngOnInit() {
        this.updateDisabledStyles();
    }

    get buttonId() {
        return this.id ? this.id : camelCase(`buttonIcon${this.icon ? this.icon : ""}${this.text ? this.text : ""}`);
    }

    ngOnChanges(changes: SimpleChanges) {
        const disabledChange = changes["disabled"];
        const iconDivClassChange = changes["iconDivClass"];
        const styleClassChange = changes["styleClass"];
        if (disabledChange || iconDivClassChange || styleClassChange) {
            this.updateDisabledStyles();
        }
    }

    private updateDisabledStyles() {
        if (this.disabled) {
            this._iconDivClass = (this.iconDivClass || "") + " disabled";
            this._styleClass = (this.styleClass || "") + " disabled";
        } else {
            this._iconDivClass = this.iconDivClass;
            this._styleClass = this.styleClass;
        }
    }

    handleClick(e: Event) {
        e.stop();
        if (this.disabled) return;
        this.click.emit();
    }
}
