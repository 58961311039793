import { Component } from "@angular/core";
import { PageBodyHeaderComponent } from "./page-body-header/page-body-header.component";
import { NgClass } from "@angular/common";
import { RouterOutlet } from "@angular/router";

@Component({
    selector: "app-page-body",
    standalone: true,
    imports: [
        PageBodyHeaderComponent,
        NgClass,
        RouterOutlet
    ],
    templateUrl: "./page-body.component.html",
    styleUrl: "./page-body.component.scss"
})
export class PageBodyComponent {
}
