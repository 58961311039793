<p-table id="upload_metric_table"
         [value]="data"
         [totalRecords]="totalRecords"
         [lazy]="true"
         (onLazyLoad)="loadTableRows()"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         [loading]="loading"
         [selectionMode]="selectionMode"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         (onPage)="onPage($event)"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         [scrollHeight]="'flex'"
         dataKey="date"
         #tableRef>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th style="max-width: 100px;">
                <!-- Date -->
            </th>

            <th *ngFor="let column of groupHeaderColumns; let index = index; trackBy: columnTrackByFn"
                style="text-align: center;"
                [attr.colspan]="headerColspan">

                <app-navigator [measuringPoint]="device ? measuringPoints[index] : null"
                               [device]="measuringPoint ? devices[index] : null"></app-navigator>

            </th>
        </tr>

        <tr>
            <th *ngFor="let column of relevantColumns; let index = index; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable">

                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="sortable && column.sortable"
                                    [field]="column.field"></p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          class="m-column-filter"
                                          [column]="column"
                                          [table]="table">
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body"
                 let-dailyUploadMetric>
        <tr>
            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngClass]="getCellClass(dailyUploadMetric, column)"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field">

                <ng-container *ngSwitchCase="'date'">
                    {{ dailyUploadMetric.date | momentDate:true:translateService.currentLang }}
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{dailyUploadMetric.fields[column.field] | thousandSeperator}}
                </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>