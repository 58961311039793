import { Component, OnInit, Input, Output, OnChanges } from "@angular/core";
import { Subject } from "rxjs";
import * as d3 from "d3";

@Component({
    selector: "app-round-slider",
    templateUrl: "./round-slider.component.html"
})
export class RoundSliderComponent implements OnInit, OnChanges {
    @Input() width = 120;
    @Input() height = 120;
    @Input() radius = 45;
    @Input() max = 100;
    @Input() thick = 3;
    @Input() min = 0;
    @Input() disabled = false;

    private thumb: any;
    private arc: any;
    private localAngleValue: number;

    private _originalValue = 0;
    private _value = 0;

    get value(): number {
        return this._value;
    }

    @Input() set value(value: number) {
        this._originalValue = value;
        this.updateUI();
    }

    @Output() change = new Subject<number>();
    @Output() changeEnd = new Subject<number>();

    constructor() {
        this.localAngleValue = 0;
        this._value = this.radiansToValue(Math.PI);
    }

    ngOnInit() {
        const component = this;

        // let host = select(this.element.nativeElement);

        const host = d3.selectAll(".m-input-round-slider-wrapper");

        const fixTouchEvent = (event: Event) => {
            const sourceEvent = (event as any)?.sourceEvent as TouchEvent;
            if (!!sourceEvent?.touches) {

                const touch = sourceEvent.touches.item(0);
                (sourceEvent as any).clientX = touch.clientX;
                (sourceEvent as any).clientY = touch.clientY;
            }
        };

        const dragFunc = d3.drag()
            .touchable(true)
            .on("start", function () {
                if (component.disabled) return;

                d3.select(this).classed("dragging", true);
            })
            .on("drag", function (event: Event, d: any) {
                if (component.disabled) return;

                fixTouchEvent(event);
                const coord = d3.pointer(event, this);
                if (Number.isNaN(coord[0]) || Number.isNaN(coord[1])) return;

                const dFromOrigin = Math.sqrt(Math.pow(coord[0], 2) + Math.pow(coord[1], 2));
                let alpha = Math.acos(coord[0] / dFromOrigin);
                alpha = coord[1] < 0 ? -alpha : alpha;

                component.localAngleValue = alpha;
                component._value = component.radiansToValue(alpha);
                component.change.next(component._value);
                component.updateUI();

                d3.select(this)
                    .attr("cx", d.x = component.radius * Math.cos(alpha))
                    .attr("cy", d.y = component.radius * Math.sin(alpha));
            })
            .on("end", function () {
                if (component.disabled) return;

                const coord = d3.pointer(this as any);
                if (Number.isNaN(coord[0]) || Number.isNaN(coord[1])) return;

                const radians = Math.atan2(coord[1], coord[0]);

                let value = component.radiansToValue(radians);
                value = Math.floor(value);

                component._value = value;
                component.changeEnd.next(value);

                d3.select(this).classed("dragging", false);
            });

        const svg = host.append("svg")
            .attr("width", this.width)
            .attr("height", this.height)
            .append("g")
            .attr("transform", `translate(${this.width / 2},${this.height / 2})`);

        const container = svg.append("g");

        // circleContainer
        container.append("circle")
            .attr("r", this.radius - (this.thick / 2))
            .attr("class", "circumference");

        const handle = [{
            x: 0,
            y: this.radius
        }];

        this.arc = d3.arc()
            .innerRadius(this.radius - (this.thick / 2))
            .outerRadius(this.radius + (this.thick / 2))
            .startAngle(0);

        // arcForeground
        container.append("path")
            .datum({ endAngle: 0 })
            .attr("class", "arc")
            .attr("d", this.arc);

        this.thumb = container.append("g")
            .attr("class", "dot")
            .selectAll("circle")
            .data(handle)
            .enter()
            .append("circle")
            .attr("r", 10)
            .attr("cx", (d: any) => d.x)
            .attr("cy", (d: any) => d.y)
            .call(dragFunc);

        this.updateUI();
    }

    ngOnChanges() {
        this.updateUI();
    }

    private updateUI() {
        this._value = Math.min(this.max, Math.max(this.min, Math.round(this._originalValue)));
        this.localAngleValue = this.valueToRadians(Math.round(this._value));

        if (this.localAngleValue === undefined || isNaN(this.localAngleValue)) {
            this.localAngleValue = this.valueToRadians(0);
        }

        if (this._value === undefined || isNaN(this._value)) {
            this._value = 0;
        }

        const xpos = this.radius * Math.cos(this.localAngleValue);
        const ypos = this.radius * Math.sin(this.localAngleValue);

        if (this.thumb) {
            this.thumb
                .attr("cx", xpos)
                .attr("cy", ypos);
        }
    }

    private radiansToValue(radians: number): number {
        let value = radians - (Math.PI / 2);
        value = value * 180 / Math.PI + 180;

        if (value < 0) {
            value += 360;
        }

        return Math.round(value / 360 * this.max);
    }

    private valueToRadians(value: number): number {
        let radiansValue = (value + 180) * 2 * Math.PI / this.max;

        radiansValue = radiansValue + (Math.PI / 2);

        if (radiansValue > Math.PI) {
            radiansValue = -(2 * Math.PI) + radiansValue;
        }

        return radiansValue;
    }
}