import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({ name: "translateFilterType", pure: false })
export class TranslateFilterTypePipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {
    }

    transform(type: string): string {
        return this.getFilterType(type);
    }

    private getFilterType(type: string): any {
        return this.translateService.instant(`mapFilters.${type}`);
    }
}
