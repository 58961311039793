import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "truncate", pure: false })
export class TruncatePipe implements PipeTransform {

    constructor() { }

    transform(value: string, numberOfChars = 25, displayDots = true) {
        if (!value || !numberOfChars || numberOfChars > value.length) { return value; }

        const truncated = value.toString().substring(0, numberOfChars);
        return displayDots ? `${truncated}...` : truncated;
    }
}
