import { IApplication, ApplicationCreator, ApplicationUpdater } from "../models/application";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class ApplicationApi extends ApiService<IApplication, ApplicationCreator, ApplicationUpdater> {

    getRoute(): string {
        return "Applications";
    }
}