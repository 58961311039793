import { Directive, AfterViewInit, ElementRef, Input } from "@angular/core";

@Directive({
    selector: "[appAutofocus]"
})
export class AutofocusDirective implements AfterViewInit {
    @Input() appAutofocus = true;
    @Input() selectTextOnFocus = false;
    @Input() initialFocus = true;

    private primeNgElementsPrefix = "P-";

    private nativeElement: HTMLInputElement | HTMLTextAreaElement;

    constructor(private element: ElementRef<HTMLElement>) {
    }

    ngAfterViewInit() {
        if (this.element) {
            this.nativeElement = this.element.nativeElement as HTMLInputElement | HTMLTextAreaElement;

            if (this.initialFocus) {
                this.focus();
            }

            const config = {
                attributes: true,
                childList: true,
                subtree: true,
                characterData: true
            } as MutationObserverInit;

            const onChange = (mutationRecords: MutationRecord[]) => {
                for (const mutation of mutationRecords) {
                    if (mutation.attributeName === "disabled") {
                        this.focus();
                    }
                }
            };

            const observer = new MutationObserver(onChange);
            observer.observe(this.nativeElement, config);
        }
    }

    private focus() {
        if (!this.nativeElement || this.nativeElement.disabled || this.appAutofocus === false) return;

        setTimeout(() => {
            if (this.nativeElement.type) {
                // native element has a known type (like 'input' or 'textarea' )
                this.nativeElement.focus();
                if (this.selectTextOnFocus) this.nativeElement.select();
            } else {
                if (this.nativeElement.nodeName.toUpperCase().startsWith(this.primeNgElementsPrefix)) {

                    // we're dealing with a primeNg component; set the focus on its input element (in case it exists)
                    const inputElement = this.nativeElement.querySelector("input");
                    if (inputElement) {
                        inputElement.focus();
                        if (this.selectTextOnFocus) inputElement.select();
                    }
                }
            }
        }, 250);
    }
}