<ng-container *ngIf="parentFormGroup && languages"
              [formGroup]="parentFormGroup">
    <ng-container formGroupName="{{formGroupName}}">
        <div *ngFor="let language of languages; let i = index"
             class="m-form-group row"
             [ngClass]="{ 'has-error': parentFormGroup.get(formGroupName).get(language).showErrors }">

            <label class="col-4"
                   for="{{formGroupName}}{{language}}">
                {{controlLabel | translate}} ({{language | translateEnum:"language"}})
            </label>

            <div class="col-8">
                <ng-template #multilineTemplate>
                    <textarea id="{{formGroupName}}{{language}}"
                              class="p-inputtextarea"
                              formControlName="{{language}}"
                              [appAutofocus]="i == 0 && autofocus"
                              #input></textarea>
                </ng-template>

                <input *ngIf="!multiline; else multilineTemplate"
                       type="text"
                       class="p-inputtext"
                       id="{{formGroupName}}{{language}}"
                       formControlName="{{language}}"
                       [appAutofocus]="i == 0 && autofocus"
                       #input />

                <app-form-validation [control]="parentFormGroup.get(formGroupName).get(language)"
                                     [input]="input">
                </app-form-validation>
            </div>
        </div>
    </ng-container>
</ng-container>