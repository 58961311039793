import { Injectable } from "@angular/core";
import { IDeviceSummary } from "@ramudden/data-access/models/device";
import { Observable } from "rxjs";
import { WebApiService } from "./web-api";

@Injectable({
    providedIn: "root",
})
export class DeviceWebApi extends WebApiService<IDeviceSummary> {
    getRoute(): string {
        return "Devices";
    }
    getByQrCode$(qrCode: string): Observable<IDeviceSummary> {
        const url = `${super.getUrl()}/qrCode/${qrCode}`;
        return this.http.get<IDeviceSummary>(url);
    }
}
