<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          [closeIcon]="false"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{"applications.createResult.title" | translate}}
    </p-header>

    <div class="application-create-result-dialog"
         *ngIf="clientCreatedResult">

        <div class="info">
            {{"applications.createResult.info" | translate}}
        </div>

        <h3>{{"applications.clientId" | translate}}</h3>
        <div class="block">
            <a (click)="copyIdToClipboard()">
                <app-svg-icon icon="copy"></app-svg-icon>
                {{clientCreatedResult.id}}
            </a>
        </div>

        <h3>{{"applications.clientSecret" | translate}}</h3>
        <div class="block">
            <a (click)="copySecretToClipboard()">
                <app-svg-icon icon="copy"></app-svg-icon>
                {{clientCreatedResult.secret}}
            </a>
        </div>
    </div>

    <p-footer>
        <button class="m-btn m-btn-primary"
                [disabled]="!copiedSecret"
                (click)="close()"
                type="submit">
            {{"form.close" | translate}}
        </button>
    </p-footer>
</p-dialog>