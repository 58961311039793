import { Component, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { IProject } from "@ramudden/data-access/models/project";
import { IProjectsFilter } from "@ramudden/data-access/models/report-type";
import { MapSelectionService } from "@ramudden/services";
import { CustomTableComponent, FilterType, TableColumn, TableService } from "../table/table.component";

@Component({
    selector: "app-select-projects",
    templateUrl: "./select-projects.component.html",
})
export class SelectProjectsComponent extends CustomTableComponent<IProject> implements OnChanges {
    @Input() requiresInput: boolean;
    @Input() readonly = false;
    @Input() deleteCommand = true;

    useDefaultSort: boolean;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly selectionService: MapSelectionService,
    ) {
        super("select-projects.component", elementRef, tableService);

        this.selectionMode = null;
        this.paginator = false;
        this.footer = false;
        this.filter = false;
        this.sortable = true;
        this.useDefaultSort = true;

        this.addColumn(new TableColumn("name", "general.name", { filterType: FilterType.Text, sortable: false }));

        const addData = (x: IProject[]) => {
            if (this.readonly) return;
            this.addData(x);
        };

        const removeData = (x: IProject[]) => {
            if (this.readonly) return;
            this.removeData(x);
        };

        this.selectionService.subscribeToProjects(this.subscriptionManager, addData, removeData);

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (project) => {
                this.removeData([project]);
                this.selectionService.removeProjects(project);
            },
            validFunc: () => this.deleteCommand,
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const inputChange = changes["requiresInput"];
        if (inputChange) {
            this.loadTableRows();
        }

        super.ngOnChanges(changes);
    }

    //#region Reporting

    async setFromFilter(projectFilters: IProjectsFilter) {
        const projects = new Array<IProject>();

        for (const projectId of projectFilters.ids) {
            const project = await this.services.mapDataService.getProject$((x) => x.id === projectId);
            if (!project) continue;

            projects.push(project);
        }

        this.setData(projects);
    }

    //#endregion Reporting
}
