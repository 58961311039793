import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import {
    CustomTableComponent,
    FilterType,
    TableColumn,
    TableService,
} from "@portal/shared/components/table/table.component";

@Component({
    selector: "app-files",
    templateUrl: "./files.component.html",
})
export class FilesComponent extends CustomTableComponent<File> {
    @ViewChild("fileInput", { static: false }) fileInput: ElementRef<HTMLInputElement>;

    @Input() multiple = true;
    @Input() accept = "";
    @Input() deleteCommand = true;

    @Output() changed = new EventEmitter<void>();

    constructor(elementRef: ElementRef, tableService: TableService) {
        super("files.component", elementRef, tableService);

        this.stretchHeight = true;

        this.selectionMode = null;
        this.paginator = false;

        this.addColumn(new TableColumn("name", "general.file", { filterType: FilterType.Text, sortable: true }));
        this.addColumn(new TableColumn("size", "general.fileSize"));

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (file) => this.removeData([file]),
            validFunc: () => this.deleteCommand,
        });
    }

    openFileDialog() {
        this.fileInput.nativeElement.click();
    }

    setFiles(event: { files: FileList }) {
        if (!event) return;

        const files = this.data ? this.data.clone() : new Array<File>();
        for (let i = 0; i < event.files.length; i++) {
            const file = event.files.item(i);

            if (!(this.data || []).find((x) => x.name === file.name)) {
                files.push(file);
            }
        }

        this.setData(files);
        this.fileInput.nativeElement.value = "";
        this.changed.next();
    }
}
