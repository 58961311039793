import { ILoginNotification, LoginNotificationCreator } from "../models/login-notification";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class LoginNotificationApi extends ApiService<ILoginNotification, LoginNotificationCreator, LoginNotificationCreator> {
    getRoute(): string {
        return "LoginNotifications";
    }
}