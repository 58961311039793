import { Injectable } from "@angular/core";
import { ActivationStart, Router } from "@angular/router";
import { LocationMarker } from "@portal/components/map-advanced/classes";
import { filter } from "rxjs/operators";

import { SubscriptionManager } from "@ramudden/core/utils";
import { IAssignment } from "@ramudden/data-access/models/assignment";
import { IDeviceSummary } from "@ramudden/data-access/models/device";
import { IGroup, IGroupMeasuringPoint } from "@ramudden/data-access/models/group";
import { IProject } from "@ramudden/data-access/models/project";
import { IScenario } from "@ramudden/data-access/models/scenario";
import { IOrganization } from "@ramudden/data-access/models/user";
import { IMeasuringPointSummary } from "@ramudden/data-access/models/web";
import { Subject } from "rxjs";

// tslint:disable:member-ordering
@Injectable({
    providedIn: "root",
})
export class MapSelectionService {
    constructor(private readonly router: Router) {
        // Clear selection on route change
        this.router.events
            .pipe(
                filter((event) => {
                    return event instanceof ActivationStart;
                }),
            )
            .subscribe(() => {
                this.reset();
            });
    }

    //#region MeasuringPoints

    private onMeasuringPointsAddSubject = new Subject<IMeasuringPointSummary[]>();
    private readonly onMeasuringPointsAdd = this.onMeasuringPointsAddSubject.asObservable();

    private onMeasuringPointsRemovedSubject = new Subject<IMeasuringPointSummary[]>();
    private readonly onMeasuringPointsRemoved = this.onMeasuringPointsRemovedSubject.asObservable();

    private selectedMeasuringPoints = new Array<IMeasuringPointSummary>();

    subscribeToMeasuringPoints(
        subscriptionManager: SubscriptionManager,
        onAdd: (x: IMeasuringPointSummary[]) => any,
        onRemove: (x: IMeasuringPointSummary[]) => any,
    ) {
        this.unsubscribeFromMeasuringPoints(subscriptionManager);

        if (onAdd) {
            const selectionAddSubscription = this.onMeasuringPointsAdd.subscribe(onAdd);
            subscriptionManager.add("selectionMeasuringPointsAdd", selectionAddSubscription);
        }

        if (onRemove) {
            const selectionRemoveSubscription = this.onMeasuringPointsRemoved.subscribe(onRemove);
            subscriptionManager.add("selectionMeasuringPointsRemove", selectionRemoveSubscription);
        }
    }

    unsubscribeFromMeasuringPoints(subscriptionManager: SubscriptionManager) {
        subscriptionManager.remove("selectionMeasuringPointsAdd");
        subscriptionManager.remove("selectionMeasuringPointsRemove");
    }

    getSelectedMeasuringPoints(): IMeasuringPointSummary[] {
        return this.selectedMeasuringPoints;
    }

    clearMeasuringPoints() {
        const toRemove = this.selectedMeasuringPoints.clone();
        if (!toRemove.length) return;

        this.selectedMeasuringPoints = [];
        this.onMeasuringPointsRemovedSubject.next(toRemove);
    }

    setMeasuringPoints(selection: IMeasuringPointSummary[]) {
        if (this.selectedMeasuringPoints === selection) return;

        if (!selection || !selection.length) {
            return this.clearMeasuringPoints();
        }

        const selectedIds = selection.filter((x) => !!x).map((x) => x.id);
        const measuringPointsToRemove = this.selectedMeasuringPoints.filter((x) => !selectedIds.contains(x.id));

        const currentSelectedIds = this.selectedMeasuringPoints.map((x) => x.id);
        const measuringPointsToAdd = selection.filter((x) => !currentSelectedIds.contains(x.id));

        this.removeMeasuringPoints(measuringPointsToRemove);
        this.addMeasuringPoints(measuringPointsToAdd);
    }

    addMeasuringPoints(data: IMeasuringPointSummary | IMeasuringPointSummary[]) {
        const measuringPointsToAdd = data
            .toList<IMeasuringPointSummary>()
            .filter((x) => !this.containsMeasuringPoint(x.id));
        if (!measuringPointsToAdd.length) return;

        this.selectedMeasuringPoints = this.selectedMeasuringPoints.concat(measuringPointsToAdd);

        this.onMeasuringPointsAddSubject.next(measuringPointsToAdd);
    }

    removeMeasuringPointId(id: number) {
        const summary = this.selectedMeasuringPoints.find((x) => x.id === id);
        this.removeMeasuringPoints(summary);
    }

    removeMeasuringPoints(data: IMeasuringPointSummary | IMeasuringPointSummary[]) {
        const measuringPointsToRemove = data.toList<IMeasuringPointSummary>();
        if (!measuringPointsToRemove.length) return;

        const measuringPointsToRemoveId = measuringPointsToRemove.map((x) => x.id);

        const removedMeasuringPoints = new Array<IMeasuringPointSummary>();

        for (const selectedMeasuringPoint of this.selectedMeasuringPoints.clone()) {
            if (!measuringPointsToRemoveId.contains(selectedMeasuringPoint.id)) continue;

            removedMeasuringPoints.push(selectedMeasuringPoint);
            this.selectedMeasuringPoints = this.selectedMeasuringPoints.remove(selectedMeasuringPoint);
        }

        if (!removedMeasuringPoints.length) return;

        this.onMeasuringPointsRemovedSubject.next(removedMeasuringPoints);
    }

    containsMeasuringPoint(id: number): boolean {
        return !!this.selectedMeasuringPoints.find((x) => x.id === id);
    }

    containsMeasuringPointLocation(id: number): boolean {
        return !!this.selectedMeasuringPoints.find((x) => x.locationId === id);
    }

    //#endregion MeasuringPoints

    //#region Devices

    private onDevicesAddSubject = new Subject<IDeviceSummary[]>();
    private readonly onDevicesAdd = this.onDevicesAddSubject.asObservable();

    private onDevicesRemovedSubject = new Subject<IDeviceSummary[]>();
    private readonly onDevicesRemoved = this.onDevicesRemovedSubject.asObservable();

    private selectedDevices = new Array<IDeviceSummary>();

    subscribeToDevices(
        subscriptionManager: SubscriptionManager,
        onAdd: (x: IDeviceSummary[]) => any,
        onRemove: (x: IDeviceSummary[]) => any,
    ) {
        this.unsubscribeFromDevices(subscriptionManager);

        if (onAdd) {
            const selectionAddSubscription = this.onDevicesAdd.subscribe(onAdd);
            subscriptionManager.add("selectionDevicesAdd", selectionAddSubscription);
        }

        if (onRemove) {
            const selectionRemoveSubscription = this.onDevicesRemoved.subscribe(onRemove);
            subscriptionManager.add("selectionDevicesRemove", selectionRemoveSubscription);
        }
    }

    unsubscribeFromDevices(subscriptionManager: SubscriptionManager) {
        subscriptionManager.remove("selectionDevicesAdd");
        subscriptionManager.remove("selectionDevicesRemove");
    }

    getSelectedDevices(): IDeviceSummary[] {
        return this.selectedDevices;
    }

    clearDevices() {
        const toRemove = this.selectedDevices.clone();
        if (!toRemove.length) return;

        this.selectedDevices = [];
        this.onDevicesRemovedSubject.next(toRemove);
    }

    setDevices(selection: IDeviceSummary[]) {
        if (this.selectedDevices === selection) return;

        if (!selection || !selection.length) {
            this.clearDevices();
            return;
        }

        const selectedIds = selection.map((x) => x.id);
        const devicesToRemove = this.selectedDevices.filter((x) => !selectedIds.contains(x.id));

        const currentSelectedIds = this.selectedDevices.map((x) => x.id);
        const devicesToAdd = selection.filter((x) => !currentSelectedIds.contains(x.id));

        this.removeDevices(devicesToRemove);
        this.addDevices(devicesToAdd);
    }

    addDevices(data: IDeviceSummary | IDeviceSummary[]) {
        const devicesToAdd = data.toList<IDeviceSummary>().filter((x) => !this.containsDevice(x.id));
        if (!devicesToAdd.length) return;

        this.selectedDevices = this.selectedDevices.concat(devicesToAdd);

        this.onDevicesAddSubject.next(devicesToAdd);
    }

    removeDeviceId(id: number) {
        const summary = this.selectedDevices.find((x) => x.id === id);
        if (!summary) return;

        this.removeDevices(summary);
    }

    removeDevices(data: IDeviceSummary | IDeviceSummary[]) {
        const devicesToRemove = data.toList<IDeviceSummary>();
        if (!devicesToRemove.length) return;

        const devicesToRemoveIds = devicesToRemove.map((x) => x.id);

        const removedDevices = new Array<IDeviceSummary>();

        for (const selectedDevice of this.selectedDevices.clone()) {
            if (!devicesToRemoveIds.contains(selectedDevice.id)) continue;

            removedDevices.push(selectedDevice);
            this.selectedDevices = this.selectedDevices.remove(selectedDevice);
        }

        if (!removedDevices.length) return;

        this.onDevicesRemovedSubject.next(removedDevices);
    }

    containsDevice(id: number): boolean {
        return !!this.selectedDevices.find((x) => x.id === id);
    }

    containsDeviceLocation(id: number): boolean {
        return !!this.selectedDevices.find((x) => x.currentLocationId === id);
    }

    //#endregion Devices

    //#region Groups

    private onGroupsAddSubject = new Subject<IGroup[]>();
    private readonly onGroupsAdd = this.onGroupsAddSubject.asObservable();

    private onGroupsRemovedSubject = new Subject<IGroup[]>();
    private readonly onGroupsRemoved = this.onGroupsRemovedSubject.asObservable();

    private selectedGroups = new Array<IGroup>();

    subscribeToGroups(
        subscriptionManager: SubscriptionManager,
        onAdd: (x: IGroup[]) => any,
        onRemove: (x: IGroup[]) => any,
    ) {
        this.unsubscribeFromGroups(subscriptionManager);

        if (onAdd) {
            const selectionAddSubscription = this.onGroupsAdd.subscribe(onAdd);
            subscriptionManager.add("selectionGroupsAdd", selectionAddSubscription);
        }

        if (onRemove) {
            const selectionRemoveSubscription = this.onGroupsRemoved.subscribe(onRemove);
            subscriptionManager.add("selectionGroupsRemove", selectionRemoveSubscription);
        }
    }

    unsubscribeFromGroups(subscriptionManager: SubscriptionManager) {
        subscriptionManager.remove("selectionGroupsAdd");
        subscriptionManager.remove("selectionGroupsRemove");
    }

    getSelectedGroups(): IGroup[] {
        return this.selectedGroups;
    }

    clearGroups() {
        const toRemove = this.selectedGroups.clone();
        if (!toRemove.length) return;

        this.selectedGroups = [];
        this.onGroupsRemovedSubject.next(toRemove);
    }

    setGroups(selection: IGroup[]) {
        if (this.selectedGroups === selection) return;

        if (!selection || !selection.length) {
            this.clearGroups();
            return;
        }

        const selectedIds = selection.map((x) => x.id);
        const groupsToRemove = this.selectedGroups.filter((x) => !selectedIds.contains(x.id));

        const currentSelectedIds = this.selectedGroups.map((x) => x.id);
        const groupsToAdd = selection.filter((x) => !currentSelectedIds.contains(x.id));

        this.removeGroups(groupsToRemove);
        this.addGroups(groupsToAdd);
    }

    addGroups(data: IGroup | IGroup[]) {
        const groupsToAdd = data.toList<IGroup>().filter((x) => !this.containsGroup(x.id));
        if (!groupsToAdd.length) return;

        this.selectedGroups = this.selectedGroups.concat(groupsToAdd);

        this.onGroupsAddSubject.next(groupsToAdd);
    }

    removeGroups(data: IGroup | IGroup[]) {
        const groupsToRemove = data.toList<IGroup>();
        if (!groupsToRemove.length) return;

        const groupsToRemoveIds = groupsToRemove.map((x) => x.id);

        const removedGroups = new Array<IGroup>();

        for (const selectedGroup of this.selectedGroups.clone()) {
            if (!groupsToRemoveIds.contains(selectedGroup.id)) continue;

            removedGroups.push(selectedGroup);
            this.selectedGroups = this.selectedGroups.remove(selectedGroup);
        }

        if (!removedGroups.length) return;

        this.onGroupsRemovedSubject.next(removedGroups);
    }

    containsGroup(id: number): boolean {
        return !!this.selectedGroups.find((x) => x.id === id);
    }

    //#endregion Groups

    //#region GroupMeasuringPoints

    private onGroupMeasuringPointsAddSubject = new Subject<IGroupMeasuringPoint[]>();
    private readonly onGroupMeasuringPointsAdd = this.onGroupMeasuringPointsAddSubject.asObservable();

    private onGroupMeasuringPointsRemovedSubject = new Subject<IGroupMeasuringPoint[]>();
    private readonly onGroupMeasuringPointsRemoved = this.onGroupMeasuringPointsRemovedSubject.asObservable();

    private selectedGroupMeasuringPoints = new Array<IGroupMeasuringPoint>();

    subscribeToGroupMeasuringPoints(
        subscriptionManager: SubscriptionManager,
        onAdd: (x: IGroupMeasuringPoint[]) => any,
        onRemove: (x: IGroupMeasuringPoint[]) => any,
    ) {
        this.unsubscribeFromGroupMeasuringPoints(subscriptionManager);

        if (onAdd) {
            const selectionAddSubscription = this.onGroupMeasuringPointsAdd.subscribe(onAdd);
            subscriptionManager.add("selectionGroupMeasuringPointsAdd", selectionAddSubscription);
        }

        if (onRemove) {
            const selectionRemoveSubscription = this.onGroupMeasuringPointsRemoved.subscribe(onRemove);
            subscriptionManager.add("selectionGroupMeasuringPointsRemove", selectionRemoveSubscription);
        }
    }

    unsubscribeFromGroupMeasuringPoints(subscriptionManager: SubscriptionManager) {
        subscriptionManager.remove("selectionGroupMeasuringPointsAdd");
        subscriptionManager.remove("selectionGroupMeasuringPointsRemove");
    }

    getSelectedGroupMeasuringPoints(): IGroupMeasuringPoint[] {
        return this.selectedGroupMeasuringPoints;
    }

    clearGroupMeasuringPoints() {
        const toRemove = this.selectedGroupMeasuringPoints.clone();
        if (!toRemove.length) return;

        this.selectedGroupMeasuringPoints = [];
        this.onGroupMeasuringPointsRemovedSubject.next(toRemove);
    }

    setGroupMeasuringPoints(selection: IGroupMeasuringPoint[]) {
        if (this.selectedGroupMeasuringPoints === selection) return;

        if (!selection || !selection.length) {
            this.clearGroupMeasuringPoints();
            return;
        }

        const groupMeasuringPointsToRemove = this.selectedGroupMeasuringPoints.filter((x) => !selection.contains(x));
        const groupMeasuringPointsToAdd = selection.filter((x) => !this.selectedGroupMeasuringPoints.contains(x));

        this.removeGroupMeasuringPoints(groupMeasuringPointsToRemove);
        this.addGroupMeasuringPoints(groupMeasuringPointsToAdd);
    }

    addGroupMeasuringPoints(data: IGroupMeasuringPoint | IGroupMeasuringPoint[]) {
        const groupMeasuringPointsToAdd = data
            .toList<IGroupMeasuringPoint>()
            .filter((x) => !this.selectedGroupMeasuringPoints.contains(x));
        if (!groupMeasuringPointsToAdd.length) return;

        this.selectedGroupMeasuringPoints = this.selectedGroupMeasuringPoints.concat(groupMeasuringPointsToAdd);

        this.onGroupMeasuringPointsAddSubject.next(groupMeasuringPointsToAdd);
    }

    removeGroupMeasuringPoints(data: IGroupMeasuringPoint | IGroupMeasuringPoint[]) {
        const groupMeasuringPointsToRemove = data.toList<IGroupMeasuringPoint>();
        if (!groupMeasuringPointsToRemove.length) return;

        const removedGroupMeasuringPoints = new Array<IGroupMeasuringPoint>();

        for (const selectedGroupMeasuringPoint of this.selectedGroupMeasuringPoints.clone()) {
            if (!groupMeasuringPointsToRemove.contains(selectedGroupMeasuringPoint)) continue;

            removedGroupMeasuringPoints.push(selectedGroupMeasuringPoint);
            this.selectedGroupMeasuringPoints = this.selectedGroupMeasuringPoints.remove(selectedGroupMeasuringPoint);
        }

        if (!removedGroupMeasuringPoints.length) return;

        this.onGroupMeasuringPointsRemovedSubject.next(removedGroupMeasuringPoints);
    }

    //#endregion GroupMeasuringPoints

    //#region Organizations

    private onOrganizationsAddSubject = new Subject<IOrganization[]>();
    private readonly onOrganizationsAdd = this.onOrganizationsAddSubject.asObservable();

    private onOrganizationsRemovedSubject = new Subject<IOrganization[]>();
    private readonly onOrganizationsRemoved = this.onOrganizationsRemovedSubject.asObservable();

    private selectedOrganizations = new Array<IOrganization>();

    subscribeToOrganizations(
        subscriptionManager: SubscriptionManager,
        onAdd: (x: IOrganization[]) => any,
        onRemove: (x: IOrganization[]) => any,
    ) {
        this.unsubscribeFromOrganizations(subscriptionManager);

        if (onAdd) {
            const selectionAddSubscription = this.onOrganizationsAdd.subscribe(onAdd);
            subscriptionManager.add("selectionOrganizationsAdd", selectionAddSubscription);
        }

        if (onRemove) {
            const selectionRemoveSubscription = this.onOrganizationsRemoved.subscribe(onRemove);
            subscriptionManager.add("selectionOrganizationsRemove", selectionRemoveSubscription);
        }
    }

    unsubscribeFromOrganizations(subscriptionManager: SubscriptionManager) {
        subscriptionManager.remove("selectionOrganizationsAdd");
        subscriptionManager.remove("selectionOrganizationsRemove");
    }

    getSelectedOrganizations(): IOrganization[] {
        return this.selectedOrganizations;
    }

    clearOrganizations() {
        const toRemove = this.selectedOrganizations.clone();
        if (!toRemove.length) return;

        this.selectedOrganizations = [];
        this.onOrganizationsRemovedSubject.next(toRemove);
    }

    setOrganizations(selection: IOrganization[]) {
        if (this.selectedOrganizations === selection) return;

        if (!selection || !selection.length) {
            this.clearOrganizations();
            return;
        }

        const selectedIds = selection.map((x) => x.id);
        const organizationsToRemove = this.selectedOrganizations.filter((x) => !selectedIds.contains(x.id));

        const currentSelectedIds = this.selectedOrganizations.map((x) => x.id);
        const organizationsToAdd = selection.filter((x) => !currentSelectedIds.contains(x.id));

        this.removeOrganizations(organizationsToRemove);
        this.addOrganizations(organizationsToAdd);
    }

    addOrganizations(data: IOrganization | IOrganization[]) {
        const organizationsToAdd = data.toList<IOrganization>().filter((x) => !this.selectedOrganizations.contains(x));
        if (!organizationsToAdd.length) return;

        this.selectedOrganizations = this.selectedOrganizations.concat(organizationsToAdd);

        this.onOrganizationsAddSubject.next(organizationsToAdd);
    }

    removeOrganizations(data: IOrganization | IOrganization[]) {
        const organizationsToRemove = data.toList<IOrganization>();

        const removedOrganizations = new Array<IOrganization>();

        for (const selectedOrganization of this.selectedOrganizations.clone()) {
            if (!organizationsToRemove.contains(selectedOrganization)) continue;

            removedOrganizations.push(selectedOrganization);
            this.selectedOrganizations = this.selectedOrganizations.remove(selectedOrganization);
        }

        if (!removedOrganizations.length) return;

        this.onOrganizationsRemovedSubject.next(removedOrganizations);
    }

    containsOrganization(id: number): boolean {
        return !!this.selectedOrganizations.find((x) => x.id === id);
    }

    //#endregion Organizations

    //#region Assignments

    private onAssignmentsAddSubject = new Subject<IAssignment[]>();
    private readonly onAssignmentsAdd = this.onAssignmentsAddSubject.asObservable();

    private onAssignmentsRemovedSubject = new Subject<IAssignment[]>();
    private readonly onAssignmentsRemoved = this.onAssignmentsRemovedSubject.asObservable();

    private selectedAssignmets = new Array<IAssignment>();

    subscribeToAssignments(
        subscriptionManager: SubscriptionManager,
        onAdd: (x: IAssignment[]) => any,
        onRemove: (x: IAssignment[]) => any,
    ) {
        this.unsubscribeFromAssignments(subscriptionManager);

        if (onAdd) {
            const selectionAddSubscription = this.onAssignmentsAdd.subscribe(onAdd);
            subscriptionManager.add("selectionAssignmentsAdd", selectionAddSubscription);
        }

        if (onRemove) {
            const selectionRemoveSubscription = this.onAssignmentsRemoved.subscribe(onRemove);
            subscriptionManager.add("selectionAssignmentsRemove", selectionRemoveSubscription);
        }
    }

    unsubscribeFromAssignments(subscriptionManager: SubscriptionManager) {
        subscriptionManager.remove("selectionAssignmentsAdd");
        subscriptionManager.remove("selectionAssignmentsRemove");
    }

    getSelectedAssignments(): IAssignment[] {
        return this.selectedAssignmets;
    }

    clearAssignments() {
        const toRemove = this.selectedAssignmets.clone();
        if (!toRemove.length) return;

        this.selectedAssignmets = [];
        this.onAssignmentsRemovedSubject.next(toRemove);
    }

    addAssignments(data: IAssignment | IAssignment[]) {
        const assignmentsToAdd = data.toList<IAssignment>().filter((x) => !this.selectedAssignmets.contains(x));
        if (!assignmentsToAdd.length) return;

        this.selectedAssignmets = this.selectedAssignmets.concat(assignmentsToAdd);
        this.onAssignmentsAddSubject.next(assignmentsToAdd);
    }

    removeAssignments(data: IAssignment | IAssignment[]) {
        const assignmentsToRemove = data.toList<IAssignment>();
        const filteredAssignments = this.selectedAssignmets.filter(
            (x) => assignmentsToRemove.findIndex((toRemove) => toRemove.id === x.id) < 0,
        );

        if (filteredAssignments.length === this.selectedAssignmets.length) return;

        const removedAssignments = this.selectedAssignmets.filter((a) => !filteredAssignments.contains(a));
        this.selectedAssignmets = filteredAssignments;

        this.onAssignmentsRemovedSubject.next(removedAssignments);
    }

    containsAssignment(id: number): boolean {
        return !!this.selectedAssignmets.find((x) => x.id === id);
    }

    //#endregion Assignments

    //#region Selected

    private onSelectSubject = new Subject<LocationMarker>();
    private readonly onSelect = this.onSelectSubject.asObservable();

    private selected: LocationMarker;

    subscribeToSelectedLocationMarker(subscriptionManager: SubscriptionManager, onSelect: (x: LocationMarker) => any) {
        subscriptionManager.remove("selectionOnSelect");

        if (onSelect) {
            const onSelectSubscription = this.onSelect.subscribe(onSelect);
            subscriptionManager.add("selectionOnSelect", onSelectSubscription);
        }
    }

    getSelected(): LocationMarker {
        return this.selected;
    }

    clearSelected() {
        this.setSelected(null);
    }

    setSelected(selected: LocationMarker): boolean {
        if (this.selected === selected) {
            return false;
        }

        this.selected = selected;
        this.onSelectSubject.next(this.selected);
        return true;
    }

    //#endregion Selected

    //#region Projects
    private onProjectsAddSubject = new Subject<IProject[]>();
    private readonly onProjectsAdd = this.onProjectsAddSubject.asObservable();

    private onProjectsRemovedSubject = new Subject<IProject[]>();
    private readonly onProjectsRemoved = this.onProjectsRemovedSubject.asObservable();

    private selectedProjects = new Array<IProject>();

    subscribeToProjects(
        subscriptionManager: SubscriptionManager,
        onAdd: (x: IProject[]) => any,
        onRemove: (x: IProject[]) => any,
    ) {
        this.unsubscribeFromProjects(subscriptionManager);

        if (onAdd) {
            const selectionAddSubscription = this.onProjectsAdd.subscribe(onAdd);
            subscriptionManager.add("selectionProjectsAdd", selectionAddSubscription);
        }

        if (onRemove) {
            const selectionRemoveSubscription = this.onProjectsRemoved.subscribe(onRemove);
            subscriptionManager.add("selectionProjectsRemove", selectionRemoveSubscription);
        }
    }

    unsubscribeFromProjects(subscriptionManager: SubscriptionManager) {
        subscriptionManager.remove("selectionProjectsAdd");
        subscriptionManager.remove("selectionProjectsRemove");
    }

    getSelectedProjects(): IProject[] {
        return this.selectedProjects;
    }

    clearProjects() {
        const toRemove = this.selectedProjects.clone();
        if (!toRemove.length) return;

        this.selectedProjects = [];
        this.onProjectsRemovedSubject.next(toRemove);
    }

    setProjects(selection: IProject[]) {
        if (this.selectedProjects === selection) return;

        if (!selection || !selection.length) {
            this.clearProjects();
            return;
        }

        const selectedIds = selection.map((x) => x.id);
        const projectsToRemove = this.selectedProjects.filter((x) => !selectedIds.contains(x.id));

        const currentSelectedIds = this.selectedProjects.map((x) => x.id);
        const projectsToAdd = selection.filter((x) => !currentSelectedIds.contains(x.id));

        this.removeProjects(projectsToRemove);
        this.addProjects(projectsToAdd);
    }

    addProjects(data: IProject | IProject[]) {
        const projectsToAdd = data.toList<IProject>().filter((x) => !this.selectedProjects.contains(x));
        if (!projectsToAdd.length) return;

        this.selectedProjects = this.selectedProjects.concat(projectsToAdd);

        this.onProjectsAddSubject.next(projectsToAdd);
    }

    removeProjects(data: IProject | IProject[]) {
        const projectsToRemove = data.toList<IProject>().map((x) => x.id);

        const removedProjects = new Array<IProject>();

        for (const selectedProject of this.selectedProjects.clone()) {
            if (!projectsToRemove.contains(selectedProject.id)) continue;

            removedProjects.push(selectedProject);
            this.selectedProjects = this.selectedProjects.remove(selectedProject);
        }

        if (!removedProjects.length) return;

        this.onProjectsRemovedSubject.next(removedProjects);
    }

    containsProject(id: number): boolean {
        return !!this.selectedProjects.find((x) => x.id === id);
    }

    //#endregion Projects

    //#region Scenarios
    private onScenariosAddSubject = new Subject<IScenario[]>();
    private readonly onScenariosAdd = this.onScenariosAddSubject.asObservable();

    private onScenariosRemovedSubject = new Subject<IScenario[]>();
    private readonly onScenariosRemoved = this.onScenariosRemovedSubject.asObservable();

    private selectedScenarios = new Array<IScenario>();

    subscribeToScenarios(
        subscriptionManager: SubscriptionManager,
        onAdd: (x: IScenario[]) => any,
        onRemove: (x: IScenario[]) => any,
    ) {
        this.unsubscribeFromScenarios(subscriptionManager);

        if (onAdd) {
            const selectionAddSubscription = this.onScenariosAdd.subscribe(onAdd);
            subscriptionManager.add("selectionScenariosAdd", selectionAddSubscription);
        }

        if (onRemove) {
            const selectionRemoveSubscription = this.onScenariosRemoved.subscribe(onRemove);
            subscriptionManager.add("selectionScenariosRemove", selectionRemoveSubscription);
        }
    }

    unsubscribeFromScenarios(subscriptionManager: SubscriptionManager) {
        subscriptionManager.remove("selectionScenariosAdd");
        subscriptionManager.remove("selectionScenariosRemove");
    }

    getSelectedScenarios(): IScenario[] {
        return this.selectedScenarios;
    }

    clearScenarios() {
        const toRemove = this.selectedScenarios.clone();
        if (!toRemove.length) return;

        this.selectedScenarios = [];
        this.onScenariosRemovedSubject.next(toRemove);
    }

    setScenarios(selection: IScenario[]) {
        if (this.selectedScenarios === selection) return;

        if (!selection || !selection.length) {
            this.clearScenarios();
            return;
        }

        const selectedIds = selection.map((x) => x.id);
        const scenariosToRemove = this.selectedScenarios.filter((x) => !selectedIds.contains(x.id));

        const currentSelectedIds = this.selectedScenarios.map((x) => x.id);
        const scenariosToAdd = selection.filter((x) => !currentSelectedIds.contains(x.id));

        this.removeScenarios(scenariosToRemove);
        this.addScenarios(scenariosToAdd);
    }

    addScenarios(data: IScenario | IScenario[]) {
        const scenariosToAdd = data.toList<IScenario>().filter((x) => !this.selectedScenarios.contains(x));
        if (!scenariosToAdd.length) return;

        this.selectedScenarios = this.selectedScenarios.concat(scenariosToAdd);

        this.onScenariosAddSubject.next(scenariosToAdd);
    }

    removeScenarios(data: IScenario | IScenario[]) {
        const scenariosToRemove = data.toList<IScenario>().map((x) => x.id);

        const removedScenarios = new Array<IScenario>();

        for (const selectedScenario of this.selectedScenarios.clone()) {
            if (!scenariosToRemove.contains(selectedScenario.id)) continue;

            removedScenarios.push(selectedScenario);
            this.selectedScenarios = this.selectedScenarios.remove(selectedScenario);
        }

        if (!removedScenarios.length) return;

        this.onScenariosRemovedSubject.next(removedScenarios);
    }

    containsScenario(id: number): boolean {
        return !!this.selectedScenarios.find((x) => x.id === id);
    }

    //#endregion Scenarios

    //#region Helpers

    private reset() {
        this.clearSelected();
        this.clearMeasuringPoints();
        this.clearGroupMeasuringPoints();
        this.clearGroups();
        this.clearDevices();
        this.clearOrganizations();
        this.clearProjects();
        this.clearAssignments();
    }

    //#endregion Helpers
}
