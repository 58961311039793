import { Directive, Input, Output, EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { distinctUntilChanged, debounceTime, takeUntil } from "rxjs/operators";
import { NgControl } from "@angular/forms";
import { Subject } from "rxjs";

@Directive({
    selector: "[ngModel][appDebounce]"
})
export class DebounceDirective implements OnInit, OnDestroy {
    @Input() debounceTime = 500;

    @Output() appDebounce = new EventEmitter<any>();

    private blockUntil: Date;
    private wasDisabled = false;

    private previousValue: string;
    private ngUnsubscribe = new Subject<void>();

    constructor(public model: NgControl) {
    }

    ngOnInit() {
        const updateBlockNextChange = () => {
            const disabledChanged = this.wasDisabled && !this.model.disabled;
            this.wasDisabled = this.model.disabled;

            if (disabledChanged) {
                this.previousValue = null;
                this.setBlockUntil();
            }

            // Disabled value is 'undefined', else it's whatever filled in value
            // This triggers a modelValue change
            // That's why we block the next debounce
        };

        this.model.statusChanges.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe(() => {
            updateBlockNextChange();
        });

        this.model.valueChanges.pipe(
            takeUntil(this.ngUnsubscribe),
            debounceTime(this.debounceTime),
            distinctUntilChanged())
            .subscribe(modelValue => {
                if (this.model.disabled) return;
                if (this.model.value === this.previousValue) return;

                // When using `isDirty` I often delete properties with value ""
                // to make them "undefined" again
                // Because "undefined" matches the data from server
                // This triggers debounce.
                // I can't think of a reason this will ever be needed
                // (Until the bug presents itself, of course)
                if (this.model.value === undefined && this.previousValue === "") return;
                if (this.model.value === "" && this.previousValue === undefined) return;

                if (this.blockUntil && this.blockUntil > new Date()) {
                    this.blockUntil = null;
                    return;
                }

                this.blockUntil = null;
                this.previousValue = this.model.value;
                this.appDebounce.emit(modelValue);
            });

        this.setBlockUntil(2);
    }

    private setBlockUntil(factor = 1) {
        this.blockUntil = new Date().addMilliseconds(this.debounceTime * factor + 100);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}