<p-table #tableRef
         (onLazyLoad)="loadTableRows($event)"
         (onPage)="onPage($event)"
         (onRowSelect)="onRowSelect($event)"
         (onRowUnselect)="onRowDeselect($event)"
         [columns]="relevantColumns"
         [lazy]="isLazy"
         [loading]="loading"
         [pageLinks]="pageLinks"
         [paginator]="paginator"
         [rows]="internalRowCount"
         [scrollHeight]="scrollHeight"
         [scrollable]="scrollable"
         [selectionMode]="selectionMode"
         [stateKey]="stateKey"
         [styleClass]="styleClass"
         [totalRecords]="total"
         [value]="data"
         dataKey="id"
         id="users_table">
    <ng-template pTemplate="loadingicon">
        <app-loading-spinner [id]="'loaderUsersTable'" />
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template *ngIf="footer && !paginator"
                 pTemplate="summary">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template *ngIf="headers"
                 pTemplate="header">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumnDisabled]="!sortable || !column.sortable"
                [pSortableColumn]="column.field">

                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="sortable && column.sortable"
                                    [field]="column.field"></p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          [column]="column"
                                          [table]="table"
                                          class="m-column-filter">
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template let-user
                 pTemplate="body">
        <tr [pSelectableRow]="user"
            id="{{user.lastName}}">

            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="{'width': ['commands', 'expand'].includes(column.field) ? '0' : null}"
                [ngSwitch]="column.field">

                <ng-container *ngSwitchCase="'organizations'">
                    <ul *ngIf="user.userOrganizations">
                        <li *ngFor="let userOrganization of user.userOrganizations"
                            style="font-size: smaller;">
                            <app-navigator [organizationId]="userOrganization.organization.id"></app-navigator>
                        </li>
                    </ul>
                </ng-container>

                <ng-container *ngSwitchCase="'roles'">
                    <ng-container *ngIf="user.userRoles">
                        <ng-container *ngFor="let role of user.userRoles; index as i">
                            <ng-container *ngIf="i > 0"> - </ng-container>
                            {{role | translateEnum:"roles" }}
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'lastLogin'">
                    <ng-container *ngIf="user.lastLogin">
                        {{user.lastLogin | momentDateTime}}
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'commands'">
                    <div class="flex">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button (click)="command.click(user)"
                                             *ngIf="command.rowValidFunc(user)"
                                             [disabled]="command.disabledFunc()"
                                             [icon]="command.icon"
                                             [text]="command.text">
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'isObsolete'">
                    <app-svg-icon [fill]="user.isObsolete ? 'red' : '#26990f'"
                                  [icon]="user.isObsolete ? 'circle-xmark' : 'circle-check'">
                    </app-svg-icon>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{user[column.field]}}
                </ng-container>
            </td>
        </tr>

    </ng-template>
</p-table>

<app-manage-user-dialog></app-manage-user-dialog>