<ng-container *ngIf="list">
    <ng-container *ngFor="let item of list; let i = index">
        <li *ngIf="i < limit || showAll">
            <ng-template [ngTemplateOutlet]="template"
                         [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
        </li>
    </ng-container>

    <a *ngIf="list.length > limit"
       (click)="toggle()"
       [innerHTML]="(showAll ? 'showMore.less' : 'showMore.more') | translate:{more: list.length - limit}">
    </a>
</ng-container>