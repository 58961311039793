import { Component, Input, OnInit } from "@angular/core";
import { ResizeService } from "@ramudden/services";

@Component({
    selector: "app-expando",
    templateUrl: "./expando.component.html",
})
export class ExpandoComponent implements OnInit {
    @Input() target: HTMLElement;
    @Input() expanded = false;
    @Input() iconCollapsed = "triangle-up";
    @Input() iconExpanded = "triangle-down";

    icon: string;

    constructor(private readonly resizeService: ResizeService) {}

    ngOnInit() {
        this.updateTarget();
    }

    toggle() {
        this.expanded = !this.expanded;
        this.updateTarget();
        // setTimeout(() => {
        this.resizeService.trigger();
        // });
    }

    expand() {
        if (this.expanded) return;
        this.toggle();
    }

    collapse() {
        if (!this.expanded) return;
        this.toggle();
    }

    private updateTarget() {
        if (this.target) {
            this.target.hidden = !this.expanded;
        }

        this.icon = this.expanded ? this.iconExpanded : this.iconCollapsed;
    }
}
