import { HttpParams } from "@angular/common/http";
import { FilterDescriptor, FilterOperator, SearchParameters } from "../search";
import { AnalysisType } from "../measuring-point";
import { AlertLevel } from "../alert";

export class MeasuringPointSearchParameters {
    skip?: number;
    take?: number;
    search?: string;
    code?: string;
    hasLinkedDevice?: boolean;
    locationId?: number;
    projectIds?: number[];
    ownerIds?: number[];
    analysisTypeIds?: AnalysisType[];
    alertLevelIds?: AlertLevel[];
    includeAdditionalData?: boolean;
    projectsForUserId?: number;

    // get HttpParams that can be used in an HttpClient
    getHttpParams(): HttpParams {
        let result = new HttpParams();
        if (this.skip) result = result.set("skip", this.skip);
        if (this.take) result = result.set("take", this.take);
        if (this.search) result = result.set("search", this.search);
        if (this.code) result = result.set("code", this.code);
        if (this.hasLinkedDevice) result = result.set("hasLinkedDevice", this.hasLinkedDevice);
        if (this.locationId) result = result.set("locationId", this.locationId);
        if (this.hasLinkedDevice === true) result = result.set("hasLinkedDevice", true);
        if (this.hasLinkedDevice === false) result = result.set("hasLinkedDevice", false);
        if (this.includeAdditionalData === true) result = result.set("includeAdditionalData", true);
        if (this.projectsForUserId) result = result.set("projectsForUserId", this.projectsForUserId);

        result = this.addList(result, "analysisTypeIds", this.analysisTypeIds);
        result = this.addList(result, "ownerIds", this.ownerIds);
        result = this.addList(result, "projectIds", this.projectIds);
        result = this.addList(result, "alertLevelIds", this.alertLevelIds);
        return result;
    }

    private addList<T>(params: HttpParams, parameterName: string, parameterValues: T[]): HttpParams {
        // Our server expects arrays to be formatted as projectsIds[0]=10&projectIds[1]=20
        if (!parameterValues?.length) return params;
        let result = params;
        for (let i = 0; i < parameterValues.length; i++) {
            result = result.set(`${parameterName}[${i}]`, parameterValues[i].toString());
        }
        return result;
    }

    // A lot of our code uses the untyped SearchParameters, so we provide a conversion
    convertToUntyped(): SearchParameters {
        const result = new SearchParameters();
        result.take = this.take;
        result.skip = this.skip;
        result.filter = [];

        result.search = this.search;

        if (this.projectIds) {
            const value = this.projectIds.join("|");
            result.filter.push(new FilterDescriptor("projectIds", value, FilterOperator.in));
        }

        if (this.ownerIds) {
            const value = this.ownerIds.join("|");
            result.filter.push(new FilterDescriptor("ownerIds", value, FilterOperator.in));
        }

        if (this.analysisTypeIds) {
            const value = this.analysisTypeIds.join("|");
            result.filter.push(new FilterDescriptor("analysisTypeIds", value, FilterOperator.in));
        }

        if (this.hasLinkedDevice === true) {
            result.filter.push(new FilterDescriptor("hasLinkedDevice", "true"));
        } else if (this.hasLinkedDevice === false) {
            result.filter.push(new FilterDescriptor("hasLinkedDevice", "false"));
        }

        return result;
    }

    // Clone the object
    shallowClone(): MeasuringPointSearchParameters {
        const clonedObject = new MeasuringPointSearchParameters();
        Object.assign(clonedObject, this);
        return clonedObject;
    }
}
