<div class="flex flex-wrap"
     [ngClass]="direction !== 'row' ? 'flex-column gap-4' : 'flex-row gap-8'">
    <div class="flex align-center gap-4"
         *ngFor="let row of configuration">
        <div [ngStyle]="{'backgroundColor': row.color,
                         'width':'10px',
                         'height':'10px',
                         'borderRadius':'50%'}">
        </div>

        <div>{{row.translationKey | translate}}</div>
    </div>
</div>