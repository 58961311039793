<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{(existingApplication ? "applications.edit" : "applications.create") | translate}}
    </p-header>

    <form *ngIf="dialogForm"
          class="p-16"
          [formGroup]="dialogForm"
          autocomplete="off"
          novalidate
          id="manage_application_form">

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': dialogForm.get('clientId').showErrors }"
             *ngIf="existingApplication">

            <label class="col-2"
                   for="clientId">
                {{"applications.clientId" | translate}}
            </label>

            <div class="col-10">
                <input type="text"
                       id="clientId"
                       class="p-inputtext"
                       formControlName="clientId"
                       #clientIdInput />

                <app-form-validation [control]="dialogForm.get('clientId')"
                                     [input]="clientIdInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             *ngIf="dialogForm.get('userId')"
             [ngClass]="{ 'has-error': dialogForm.get('userId').showErrors }">

            <label class="col-2"
                   for="userId">
                {{"general.user" | translate}}
            </label>

            <div class="col-10">
                <p-dropdown formControlName="userId"
                            id="userId"
                            [style]="{'width':'100%'}"
                            [panelStyle]="{'width':'100%'}"
                            [options]="users"
                            [filter]="true"
                            [resetFilterOnHide]="true"
                            [autoDisplayFirst]="false"
                            #userIdInput>
                </p-dropdown>

                <app-form-validation [control]="dialogForm.get('userId')"
                                     [input]="userIdInput">
                </app-form-validation>
            </div>
        </div>

        <div class="m-form-group row"
             [ngClass]="{ 'has-error': dialogForm.get('description').showErrors }">

            <label class="col-2"
                   for="description">
                {{"general.description" | translate}}
            </label>

            <div class="col-10">
                <input type="text"
                       id="description"
                       class="p-inputtext"
                       formControlName="description"
                       appAutofocus
                       #descriptionInput />

                <app-form-validation [control]="dialogForm.get('description')"
                                     [input]="descriptionInput">
                </app-form-validation>
            </div>
        </div>

        <div class="row"
             [formGroup]="rightsForm">
            <div class="col-4 mt-16"
                 *ngFor="let groupedRight of groupedRights; trackBy: groupedRightTrackByFn">
                <h3 (click)="selectGroup(groupedRight)"
                    class="mb-8">{{groupedRight.label}}</h3>
                <div>
                    <ul>
                        <li *ngFor="let right of groupedRight.rights; trackBy: rightTrackByFn">
                            <label class="m-input-checkbox">
                                <input type="checkbox"
                                       formControlName="{{right.value}}"> {{right.label}}
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-link"
                [disabled]="submitting"
                (click)="close()"
                type="button"
                id="cancel_button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                id="save_button"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.save" | translate}}
        </button>
    </p-footer>
</p-dialog>