import { IAssignmentStatusHistory } from "../assignment";

export interface IAssignmentPlanningSummary {
    id: number;
    name: string;
    parentAssignment?: IAssignmentPlanningSummary;
    project?: IAssignmentPlanningProjectSummary;
    currentStatus: IAssignmentStatusHistory;
}
export interface IAssignmentPlanningProjectSummary {
    checkInAtWorkEnabled: boolean;
    checkInAtWorkNumber: string;
}