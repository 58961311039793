<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{'scannerDialog.title' | translate}}
    </p-header>

    <div style="width:100%;">
        <video id="qrVideo"
               style="width:100%;
        height:100%;
        object-fit: cover; "
               #qrVideo></video>
    </div>

    <p-footer>
        <input type="button"
               class="m-btn m-btn-primary"
               value="{{'form.cancel' | translate}}"
               (click)="cancel()">
    </p-footer>
</p-dialog>