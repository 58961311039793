import { Injectable } from "@angular/core";
import { SearchParameters } from "@ramudden/data-access/models/search";
import { Observable } from "rxjs";
import { IVehicleRow, VehicleDayOverview } from "../models/vehicle-overview";
import { ApiService, CacheOptions } from "./api";

export class VehicleSearchParameters extends SearchParameters {
    measuringPointId: number;
    group: string;
    orderDescending = true;
}

export class VehicleDayOverviewSearchParameters extends SearchParameters {
    measuringPointId: number;
    from: Date;
    to: Date;
}

@Injectable({
    providedIn: "root",
})
export class VehicleApi extends ApiService<IVehicleRow, IVehicleRow, IVehicleRow> {
    getRoute(): string {
        return "Vehicles";
    }

    getVehicleDayOverviews$(
        parameters: VehicleDayOverviewSearchParameters,
        useCache: boolean | CacheOptions = true,
    ): Observable<VehicleDayOverview[]> {
        return this.getOther$<VehicleDayOverview[]>("/DayOverviews", parameters, null, useCache);
    }
}
