<div style="text-align: center"
     [style.width]="responsive && !width ? null : width"
     [style.height]="responsive && !height ? null : height"
     [style.cursor]="canPan ? 'move' : 'default'">
    <canvas #canvas
            class="m-chart"
            [attr.width]="responsive && !width ? null : width"
            [attr.height]="responsive && !height ? null : height"
            (click)="handleCanvasClick($event)">
    </canvas>

    <app-loading-spinner *ngIf="showLoadingSpinner && !configuration">
    </app-loading-spinner>
</div>