import { Injectable } from "@angular/core";
import { ApiService } from "./api";
import { IPlannedEvent, PlannedEventCreator } from "../models/planned-event";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class PlannedEventApi extends ApiService<IPlannedEvent, PlannedEventCreator, PlannedEventCreator> {

    getRoute(): string {
        return "PlannedEvents";
    }

    copyWeek$(startingDateOfWeekWhichNeedsToBeCopied: Date): Observable<void> {
        return this.http.post<void>(`${this.getUrl()}/CopyWeek?startOfTheWeekWhichNeedsToBeCopied=${startingDateOfWeekWhichNeedsToBeCopied.toISOString()}`, null);
    }

    endWorkdayFromWebsite$(plannedEventId: number): Observable<void> {
        return this.http.post<void>(`${this.getUrl()}/EndWorkdayFromWebsite/${plannedEventId}`, null);
    }
}