import { Component, ElementRef, Input } from "@angular/core";
import { IScenariosFilter } from "@ramudden/data-access/models/report-type";
import { IScenario } from "@ramudden/data-access/models/scenario";
import { ISearchResult, ServiceRequestOptions } from "@ramudden/data-access/models/search";
import { ScenarioApi } from "@ramudden/data-access/resource/scenario.api";
import { MapSelectionService } from "@ramudden/services";
import { CustomTableComponent, TableColumn, TableService } from "../table/table.component";

@Component({
    selector: "app-select-scenarios",
    templateUrl: "./select-scenarios.component.html",
})
export class SelectScenariosComponent extends CustomTableComponent<IScenario> {
    @Input() deleteCommand = true;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly selectionService: MapSelectionService,
        private readonly scenarioApi: ScenarioApi,
    ) {
        super("select-scenarios.component", elementRef, tableService);

        this.selectionMode = null;
        this.paginator = false;
        this.footer = false;
        this.filter = false;
        this.sortable = false;
        this.stretchHeight = true;

        this.addColumn(new TableColumn("code", "general.code", { sortable: false }));

        this.selectionService.subscribeToScenarios(
            this.subscriptionManager,
            (x) => this.addData(x),
            (x) => this.removeData(x),
        );

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (scenario) => this.removeSelectedRow(scenario),
            validFunc: () => this.deleteCommand,
        });
    }

    onSetData() {
        this.selectionService.setScenarios(this.data);
    }

    removeSelectedRow(row: IScenario) {
        this.removeData([row]);
        this.selectionService.removeScenarios([row]);
    }

    //#region Reporting

    async setFromFilter(scenariosFilter: IScenariosFilter): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!scenariosFilter || !scenariosFilter.ids || scenariosFilter.ids.length === 0) {
                this.setData([]);
                resolve();
                return;
            }

            const onSuccess = (result: ISearchResult<IScenario>) => {
                const data = result.data;

                const scenarios: IScenario[] = [];

                for (const scenarioId of scenariosFilter.ids) {
                    const scenario = data.filter((x) => x.id === scenarioId).takeFirstOrDefault();
                    if (!scenario) continue;

                    scenarios.push(scenario);
                }

                this.setData(scenarios);
            };

            const onError = (error) => {
                reject(error);
            };

            const serviceRequestOptions = new ServiceRequestOptions();
            this.scenarioApi.search$(null, serviceRequestOptions).subscribe(onSuccess, onError);
        });
    }

    //#endregion Reporting
}
