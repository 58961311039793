import { ConfigurationService } from "./configuration.service";
import { TranslateService } from "@ngx-translate/core";
import { inject, Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { WebsiteService } from "./website.service";

@Injectable({
    providedIn: "root"
})
export class TitleService {
    private currentTitleKey: string;

    private readonly title = inject(Title);
    private readonly translateService = inject(TranslateService);
    private readonly configurationService = inject(ConfigurationService);
    private readonly websiteService = inject(WebsiteService);
    private params: object;


    constructor(
    ) {
        this.params = { productName: this.websiteService.getProductName(), companyName: this.websiteService.getCompanyName() };

        this.translateService.onLangChange.subscribe(() => {
            if (!this.currentTitleKey) return;

            this.setTitle(this.currentTitleKey);
        });
    }

    setTitle(titleKey: string) {
        this.currentTitleKey = titleKey;

        const baseTitle = this.configurationService.configuration.title;
        this.translateService.get(titleKey, this.params).subscribe(subTitle => {
            const title = baseTitle && subTitle ? `${baseTitle} - ${subTitle}` : subTitle || baseTitle;
            this.title.setTitle(title);
        });
    }

    getTitle(): string {
        return this.title.getTitle();
    }
}