import { AlertLevel } from "../alert";
import { IAddress } from "../location";
import { AnalysisType } from "../measuring-point";
import { IProjectNavigator } from "../project";
import { Roles } from "../user";

export interface ILocationSummary {
    id: number;
    ownerId: number;
    lat: number;
    lng: number;
    code: string;
    iconStateId: number;
    address: IAddress;
    analysisTypes: AnalysisType[];
    iconStateIds: IRoleIconState[];
    projects: IProjectNavigator[];
}

export interface IRoleIconState {
    roleId: Roles;
    icId?: number;
    al?: AlertLevel;
}

export interface ILocationWithDevicesSummary extends ILocationSummary {
    alarmSeverityId: string;
}

export interface ILocationWithAssignmentsSummary extends ILocationSummary {
    assignmentIds: number[];
}