<ng-container *ngIf="context?.attachments">
    <div class="m-attachments">
        <ng-container *ngFor="let attachment of context.attachments">
            <div class="m-attachment">
                <div class="m-attachment-image-wrapper">
                    <div class="m-attachment-image-actions">
                        <app-icon-button *ngIf="attachment.url"
                                         icon="download"
                                         text="general.download"
                                         (click)="downloadAttachment(attachment)">
                        </app-icon-button>
                    </div>

                    <img class="m-attachment-image"
                         [attr.src]="attachment.preview | safeHtml"
                         (click)="openGallery(attachment.id); $event.stop();">
                </div>

                <span class="m-attachment-name mt-8">
                    {{attachment.name}}
                </span>
            </div>
        </ng-container>
    </div>
</ng-container>