<ng-template #loadingTemplate>
    <app-loading-spinner *ngIf="!hasError && !imageNotPresentMessageVisible"></app-loading-spinner>
    <p *ngIf="imageNotPresentMessageVisible">
        {{"cameraConfiguration.noPersistedImages" | translate}}
    </p>
</ng-template>

<div class="m-camera"
     *ngIf="image || livestream || historyImages; else loadingTemplate">

    <div *ngIf="image || galleryImage"
         class="flex align-center mb-8">
        <span class="m-camera-timestamp mr-8">
            {{(galleryImage?.timestamp ?? image.timestamp) | momentDateTime:true:true:translateService.currentLang }}
        </span>

        <label class="m-input-checkbox ml-auto">
            <input type="checkbox"
                   [(ngModel)]="refreshing"
                   (change)="refreshIfToggled()">
            {{'cameraConfiguration.liveView' | translate}}
        </label>
    </div>

    <ng-container *ngIf="refreshing; else history">
        <div class="m-camera-image">

            <div [ngResizable]="true"
                 [rzAspectRatio]="true"
                 rzMinWidth="176"
                 rzHandles="se"
                 style="width:200px;">
                <img *ngIf="image"
                     [src]="image.src | safeHtml">

                <img *ngIf="livestream"
                     [src]="livestreamUrl">
            </div>

            <div class="m-camera-controls mt-8"
                 *ngIf="showControls">
                <div class="m-camera-presets"
                     *ngIf="presets">
                    <ng-template #presetTemplate
                                 let-preset>
                        <div class="flex align-center"
                             style="width: 100%;">
                            <span class="mr-16">
                                {{preset.label}}
                            </span>

                            <app-icon-button *ngIf="editPresets"
                                             icon="delete"
                                             class="ml-auto"
                                             (click)="deletePreset(preset.value)">
                            </app-icon-button>
                        </div>
                    </ng-template>

                    <div class="flex">
                        <div class="flex flex-column"
                             style="width: 100%;">
                            <div>
                                <p-dropdown [options]="presets"
                                            [(ngModel)]="selectedPreset"
                                            (onChange)="onPresetSelect()"
                                            [autoDisplayFirst]="false"
                                            [panelStyle]="{'width':'100%'}"
                                            appendTo="body"
                                            placeholder="{{'cameraConfiguration.choosePreset' | translate}}">
                                    <ng-template let-preset
                                                 pTemplate="selectedItem">
                                        <ng-template [ngTemplateOutlet]="presetTemplate"
                                                     [ngTemplateOutletContext]="{$implicit: preset}">
                                        </ng-template>
                                    </ng-template>
                                    <ng-template let-preset
                                                 pTemplate="item">
                                        <ng-template [ngTemplateOutlet]="presetTemplate"
                                                     [ngTemplateOutletContext]="{$implicit: preset}">
                                        </ng-template>
                                    </ng-template>
                                </p-dropdown>
                            </div>

                            <div class="mt-8"
                                 *ngIf="selectedPreset && !selectedPreset.id">
                                <input type="text"
                                       class="p-inputtext"
                                       [(ngModel)]="selectedPreset.name"
                                       placeholder="{{'general.name' | translate}}"
                                       required>
                            </div>
                        </div>

                        <app-icon-button *ngIf="editPresets"
                                         icon="add"
                                         class="ml-8"
                                         (click)="addPreset()">
                        </app-icon-button>
                    </div>

                    <div class="m-btn-group mt-8"
                         *ngIf="editPresets">
                        <button class="m-btn m-btn-primary"
                                (click)="loadPreset()"
                                *ngIf="selectedPreset && selectedPreset?.id">
                            {{"form.select" | translate}}
                        </button>

                        <button class="m-btn m-btn-primary"
                                (click)="savePreset()"
                                *ngIf="selectedPreset && !selectedPreset?.id">
                            {{"form.save" | translate}}
                        </button>
                    </div>
                </div>

                <div class="m-camera-control-pad">
                    <button class="m-camera-control-pad-btn"
                            (click)="move('upleft')">🡬</button>

                    <button class="m-camera-control-pad-btn"
                            (click)="move('up')">🡩</button>

                    <button class="m-camera-control-pad-btn"
                            (click)="move('upright')">🡭</button>

                    <button class="m-camera-control-pad-btn"
                            (click)="move('left')">🡨</button>

                    <button class="m-camera-control-pad-btn m-camera-control-pad-btn-home"
                            (click)="move('home')">⌂</button>

                    <button class="m-camera-control-pad-btn"
                            (click)="move('right')">🡪</button>

                    <button class="m-camera-control-pad-btn"
                            (click)="move('downleft')">🡯</button>

                    <button class="m-camera-control-pad-btn"
                            (click)="move('down')">🡫</button>

                    <button class="m-camera-control-pad-btn"
                            (click)="move('downright')">🡮</button>
                </div>

                <div class="m-camera-zoom"
                     *ngIf="minCameraZoom && maxCameraZoom">
                    <p-slider id="zoomSlider"
                              [style]="{'width': '100%'}"
                              [(ngModel)]="cameraZoom"
                              [min]="minCameraZoom"
                              [max]="maxCameraZoom"
                              [step]="1"
                              (appDebounce)="zoom()"
                              [debounceTime]="250">
                    </p-slider>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Kind of a galery to go through persisted images -->
    <ng-template #history>
        <ng-container *ngIf="historyImages">
            <ng-container *ngIf="historyImages.length > 0;else galleryEmpty">
                <div class="m-camera-gallery"
                     [ngResizable]="true"
                     [rzAspectRatio]="true"
                     rzMinWidth="176"
                     rzHandles="se">
                    <div class="m-camera-gallery-command"
                         [class.m-camera-gallery-command-hover]="currentGalleryImageIndex !== historyImages.length - 1"
                         [hidden]="galleryImageLoading">
                        <app-icon-button icon="arrow-left"
                                         [disabled]="currentGalleryImageIndex === historyImages.length - 1"
                                         [fill]="currentGalleryImageIndex === historyImages.length - 1 ? 'gray' : 'black'"
                                         (click)="toPreviousImage()"
                                         [ngStyle]="{'height':'100%', 'display': 'flex'}">

                        </app-icon-button>
                    </div>

                    <img [src]="galleryImage.url | safeHtml"
                         (load)="galleryImageLoaded()"
                         [hidden]="galleryImageLoading" />
                    <app-loading-spinner *ngIf="galleryImageLoading"></app-loading-spinner>

                    <div class="m-camera-gallery-command"
                         [class.m-camera-gallery-command-hover]="currentGalleryImageIndex !== 0"
                         [hidden]="galleryImageLoading">
                        <app-icon-button icon="arrow-right"
                                         [disabled]="currentGalleryImageIndex === 0"
                                         [fill]="currentGalleryImageIndex === 0 ? 'gray' : 'black'"
                                         (click)="toNextImage()"
                                         [ngStyle]="{'height':'100%', 'display': 'flex'}">
                        </app-icon-button>
                    </div>
                </div>
            </ng-container>

            <ng-template #galleryEmpty>
                {{"cameraConfiguration.noPersistedImages" | translate}}
            </ng-template>
        </ng-container>
    </ng-template>
</div>