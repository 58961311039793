export class StringUtils {
    static createKey(): string {
        // Creates a guid
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
            const r = Math.random() * 16 | 0, v = c === "x" ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    static equalsIgnoreCase(a: string, b: string): boolean {
        return (a || "").toLowerCase() === (b || "").toLowerCase();
    }

    static normalize(a: string): string {
        return (a || "").replace("\r", "").replace("\n", "").replace(" ", "").trim();
    }

    static compareNormalize(a: string, b: string): boolean {
        a = this.normalize(a);
        b = this.normalize(b);
        return a === b;
    }
}