import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalEventsService } from "./global-events.service";

export enum MapDetail {
    MeasuringPoints = 0,
    Devices = 1,
    Organizations = 2,
    MeasuringPointGroups = 3,
    Assignments = 5,
    Projects = 6,
    Scenarios = 7,
}

@Injectable({
    providedIn: "root",
})
export class MapDetailService {
    get currentMapDetail(): MapDetail {
        const url = window.location.pathname;
        if (url.startsWith("/assignments")) return MapDetail.Assignments;
        if (url.startsWith("/devices")) return MapDetail.Devices;
        if (url.startsWith("/locations")) return MapDetail.MeasuringPoints;
        return null;
    }

    constructor(
        private readonly router: Router,
        private readonly globalEventsService: GlobalEventsService,
    ) {}

    async navigateToMapDetail(mapDetail: MapDetail = null): Promise<boolean> {
        return this.router.navigate([this.getUrlForMapDetail(mapDetail || this.currentMapDetail)]);
    }

    getUrlForMapDetail(mapDetail: MapDetail): string {
        if (!mapDetail) return "/locations";

        if (mapDetail === MapDetail.Organizations && !this.globalEventsService.hasMultipleOrganizations()) {
            mapDetail = MapDetail.MeasuringPoints;
        }

        if (mapDetail === MapDetail.MeasuringPoints) return "/locations";
        if (mapDetail === MapDetail.Devices) return "/devices";
        if (mapDetail === MapDetail.MeasuringPointGroups) return "/groups";
        if (mapDetail === MapDetail.Assignments) return "/assignments";
        return "/locations";
    }
}
