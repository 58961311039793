@if (visible) {
    <div class="m-cookie m-card m-shadow">
        <div class="m-cookie-body p-16">
            <p>
                {{ "cookieNotification.text" | translate }}

                <a class="m-navigator"
                   href="https://wikis.ec.europa.eu/display/WEBGUIDE/04.+Cookies+and+similar+technologies"
                   target="_blank">
                    {{ "cookieNotification.learnMore" | translate }}
                </a>
            </p>
        </div>

        <div class="m-btn-group m-cookie-actions px-16 pb-16">
            <a (click)="acceptCookies()" class="m-btn m-btn-primary" id="acceptCookiesButton">
                {{ "cookieNotification.accept" | translate }}
            </a>
        </div>
    </div>
}
