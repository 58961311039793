import { ValidatorFn, AbstractControl } from "@angular/forms";

export class OneOfManyRequiredValidator {
    static loopingOverControls: boolean;

    static create(controls: AbstractControl[], includeDisabled = false): ValidatorFn {
        return (c: AbstractControl) => {

            const hasValue = !!(includeDisabled ? controls.find(x => x.value) : controls.find(x => x.enabled && x.value));

            if (!this.loopingOverControls) {
                this.loopingOverControls = true;
                controls.filter(x => x !== c).forEach(x => x.updateValueAndValidity());
                this.loopingOverControls = false;
            }

            return !hasValue ? { required: true } : null;
        };
    }
}