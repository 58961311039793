import { Injectable } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { environment } from "@env/environment";
import { TranslateService } from "@ngx-translate/core";
import { SigncoFormControl, SigncoFormGroup } from "@ramudden/data-access/models/form";

@Injectable({
    providedIn: "root",
})
export class FormValidationService {
    constructor(private readonly translateService: TranslateService) {}

    private getValidationMessage(error: string, param: any): string {
        return this.translateService.instant(`validation.${error}`, param);
    }

    async checkValidity(form: SigncoFormGroup): Promise<boolean> {
        form.submitted = true;
        form.validating = true;

        this.setErrorMessagesForGroup(form);

        const isValid = form.valid;

        if (!isValid && !form.validating) {
            // Give async validators some time; they might just be lagging behind
            await new Promise((resolve) => setTimeout(resolve, 500));
            return this.checkValidity(form);
        }

        form.validating = false;

        return isValid;
    }

    private setErrorMessagesForGroup(formGroup: SigncoFormGroup) {
        for (const key in formGroup.controls) {
            if (formGroup.controls.hasOwnProperty(key)) {
                const abstractControl = formGroup.controls[key];
                if (abstractControl instanceof UntypedFormGroup) {
                    // Not checking SigncoFormGroup, most often it's FormGroup, we just cast it
                    this.setErrorMessagesForGroup(abstractControl as SigncoFormGroup);
                } else if (abstractControl instanceof UntypedFormArray) {
                    this.setErrorMessagesForArray(abstractControl);
                } else {
                    abstractControl.markAsTouched();
                    abstractControl.markAsDirty();
                    this.setErrorMessages(abstractControl as SigncoFormControl);
                }
            }
        }
    }

    private setErrorMessagesForArray(formArray: UntypedFormArray) {
        for (const formArrayControl of formArray.controls) {
            if (formArrayControl instanceof UntypedFormGroup) {
                this.setErrorMessagesForGroup(formArrayControl as SigncoFormGroup);
            } else if (formArrayControl instanceof UntypedFormArray) {
                this.setErrorMessagesForArray(formArrayControl);
            } else {
                formArrayControl.markAsTouched();
                formArrayControl.markAsDirty();
                this.setErrorMessages(formArrayControl as SigncoFormControl);
            }
        }
    }

    setErrorMessages(c: SigncoFormControl) {
        const errorMessages = new Array<string>();

        if (c.errors) {
            for (const error in c.errors) {
                if (c.errors.hasOwnProperty(error)) {
                    errorMessages.push(this.getValidationMessage(error, c.errors[error]));
                }
            }
        }

        if (errorMessages.length !== 0) {
            // To help with debugging, we log which control caused the error.
            // Retrieving the name of the control is a bit cumbersome
            const parent = c.parent;
            for (const key in parent.controls) {
                if (parent.get(key) === c) {
                    if (!environment.production) {
                        console.warn("Validation error on control " + key + ": " + errorMessages[0]);
                    }
                }
            }
        }

        c.errorMessages = errorMessages;

        this.setShowError(c);
    }

    setShowError(c: SigncoFormControl) {
        if (!c) return;

        let parent = c.parent as SigncoFormGroup;
        while (parent.parent && !parent.submitted) {
            parent = parent.parent as SigncoFormGroup;
        }

        // This allows us to re-use showErrors in *ngIf and [ngClass]
        setTimeout(() => {
            c.showErrors = c.errors && parent.submitted && !c.disabled ? true : undefined;
        });
    }
}
