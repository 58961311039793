import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Observable, of } from "rxjs";

export class PhoneNumberValidator {
    static validate(control: AbstractControl): ValidationErrors | null {
        if (control.value && !control.value.match("^\\+[0-9 ]+$")) {
            // Emit an object with a validation error.
            return { phoneNumber: true };
        }
        // Emit null, to indicate no error occurred.
        return null;
    }

}