import { Pipe, PipeTransform } from "@angular/core";
import { LocaleService } from "@ramudden/services";

@Pipe({ name: "thousandSeperator" })
export class ThousandSeperatorPipe implements PipeTransform {
    constructor(private readonly localeService: LocaleService) {}

    transform(longNumber: number) {
        if (!longNumber || Number.isNaN(longNumber)) return longNumber;

        const numberLocale = this.localeService.getLocale();
        return longNumber.toLocaleString(numberLocale);

        // let numberString = longNumber.toString();

        // // We're gonna start adding seperators every -3 starting from the beginning of the number
        // // Get index of `.` if exists, this will be our new starting point as we ignore decimals
        // let index = numberString.length - 1;
        // const indexOfDecimalPeriod = numberString.indexOf(".");
        // if (indexOfDecimalPeriod > 0) {
        //     index = indexOfDecimalPeriod - 1;
        // }

        // while (index - 3 > 0) {
        //     index = index - 3;
        //     numberString = `${numberString.substr(0, index)},${numberString.substr(index)}}`;
        // }

        // return indexOfDecimalPeriod >
    }
}
