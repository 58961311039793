<details #notificationsDropdown
         (clickOutside)="closeNotificationDropdown()"
         class="m-dropdown">
    <summary (click)="markNotificationsAsRead()"
             class="m-dropdown-summary">
        <span class="m-account-item">
            <app-svg-icon icon="bell"/>
            <ng-container *ngIf="(unreadNotificationsCount + unreadStickyNotificationsCount) > 0">
                <span class="m-alert-badge">
                    <small>{{ unreadNotificationsCount + unreadStickyNotificationsCount }}</small>
                </span>
            </ng-container>
        </span>
    </summary>

    <div class="m-dropdown-content"
         style="right: 0px;">
        <div class="notification">
            <div class="notification__content">
                @for (stickyNotification of stickyNotifications; let last = $last; track stickyNotification.id) {
                    <div [ngClass]="{'is-unread': !stickyNotification.seenOn}"
                         class="m-alert p-16">
                        <div class="m-alert-level">
                            <app-svg-icon *ngIf="stickyNotification.levelId === 'information'"
                                          [ngStyle]="{'fill': '#1387F1'}"
                                          divClass="icon-16"
                                          icon="circle-info"/>
                            <app-svg-icon *ngIf="stickyNotification.levelId === 'warning'"
                                          [ngStyle]="{'fill': '#F1C013'}"
                                          divClass="icon-16"
                                          icon="circle-exclamation"/>
                            <app-svg-icon *ngIf="stickyNotification.levelId === 'error'"
                                          [ngStyle]="{'fill': '#EE3535'}"
                                          divClass="icon-16"
                                          icon="circle-xmark"/>
                        </div>

                        <div>
                            <p class="m-alert-date">
                                <small>{{ stickyNotification.createDate | momentDateTime:true:false:translate.currentLang }}</small>
                            </p>

                            <ng-container *ngIf="stickyNotification.message">
                                <p [innerHtml]="stickyNotification.message| translateMessage:stickyNotification.categoryId"
                                   class="m-alert-message">
                                </p>

                                <p>
                                    <a [queryParams]="addExtraQueryParameters(stickyNotification.message.parsedQueryParameters)"
                                       [routerLink]="[stickyNotification.message.relativeUrl]"
                                       class="m-navigator">
                                        <small>{{ "notifications.viewOnMap"|translate }}</small>
                                    </a>
                                </p>
                            </ng-container>
                        </div>
                    </div>

                    @if (!last) {
                        <hr class="my-0"/>
                    }
                }
                @for (notification of notifications; let last = $last; track notification.id) {
                    <div [ngClass]="{'is-unread': !notification.seenOn}"
                         class="m-alert p-16">
                        <div class="m-alert-level">
                            <app-svg-icon *ngIf="notification.levelId === 'information'"
                                          [ngStyle]="{'fill': '#1387F1'}"
                                          divClass="icon-16"
                                          icon="circle-info"/>
                            <app-svg-icon *ngIf="notification.levelId === 'warning'"
                                          [ngStyle]="{'fill': '#F1C013'}"
                                          divClass="icon-16"
                                          icon="circle-exclamation"/>
                            <app-svg-icon *ngIf="notification.levelId === 'error'"
                                          [ngStyle]="{'fill': '#EE3535'}"
                                          divClass="icon-16"
                                          icon="circle-x"/>
                        </div>

                        <div>
                            <p class="m-alert-date">
                                <small>{{ notification.createDate | momentDateTime:true:false:translate.currentLang }}</small>
                            </p>

                            @if (notification.message) {
                                <p [innerHtml]="notification.message| translateMessage"
                                   class="m-alert-message">
                                </p>

                                <p>
                                    <a [queryParams]="addExtraQueryParameters(notification.message.parsedQueryParameters)"
                                       [routerLink]="[notification.message.relativeUrl]"
                                       class="m-navigator">
                                        <small>{{ "notifications.readMore"|translate }}</small>
                                    </a>
                                </p>
                            }
                        </div>
                    </div>

                    @if (!last) {
                        <hr class="my-0"/>
                    }
                }
            </div>

            <div class="notification__footer">
                <a (click)="closeNotificationDropdown()"
                   [routerLink]="['/notifications']"
                   class="m-alert-showall m-navigator">
                    {{ "notifications.viewAll"|translate }}</a>

                <a (click)="createMockNotification()"
                   *ngIf="showMock"
                   class="m-alert-showall m-navigator">
                    Create Mock Notification
                </a>
            </div>
        </div>
    </div>
</details>
