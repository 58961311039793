import { ToastService } from "./toast.service";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class AccessibilityService {

    constructor(
        private readonly toastService: ToastService) {
    }

    copyToClipboard(value: string, successTranslationKey = "general.copiedToClipboard", errorTranslationKey = "general.errorWhileCopyingToClipboard") {
        navigator.clipboard.writeText(value).then(() => {
            this.toastService.success(successTranslationKey);
        }, (error) => {
            this.toastService.error(errorTranslationKey);
        });
    }
}