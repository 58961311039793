import { Injectable } from "@angular/core";
import { AbstractControlOptions, AsyncValidatorFn, ValidatorFn } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { LegendRow } from "@portal/shared/components/legend/legend.component";
import { JsonUtils } from "@ramudden/core/utils";
import { SigncoFormControl } from "@ramudden/data-access/models/form";
import { PrimeNGConfig, SelectItem } from "primeng/api";
import { Calendar } from "primeng/calendar";
import { BehaviorSubject } from "rxjs";

export class CalendarSettings {
    constructor(
        public locale: string,
        public dateFormat: string,
        public monthFormat: string,
        public hourFormat: "12" | "24",
        public firstDayOfWeek: number,
    ) {
        const curYear = new Date().getFullYear();
        this.currentYearRange = `${curYear}:${curYear + 100}`;
    }

    currentYearRange: string;
}

class DateMeta {
    constructor(
        public day: number,
        public month: number,
        public year: number,
        public otherMonth: boolean,
    ) {}
}

@Injectable({
    providedIn: "root",
})
export class PrimeComponentService {
    private cachedCalendarSettings: { [key: string]: CalendarSettings } = {};
    private calendarSettingsSubject = new BehaviorSubject<CalendarSettings>(
        new CalendarSettings("nl-BE", "dd/mm/yy", "mm/yy", "24", 1),
    );

    constructor(
        private primeNGConfig: PrimeNGConfig,
        private readonly translate: TranslateService,
    ) {
        this.translate.onLangChange.subscribe(() => {
            this.updateCalendarSettings();
        });
    }

    get calendarYearRange(): string {
        const curYear = new Date().getFullYear();
        return `2010:${curYear}`;
    }

    get calendarYearRangeFuture(): string {
        const curYear = new Date().getFullYear();
        return `2010:${curYear + 10}`;
    }

    calendarYearRangeFutureAmountOfYears(numberOfYears: number): string {
        const curYear = new Date().getFullYear();
        return `2010:${curYear + numberOfYears}`;
    }

    calendarFooterConfiguration(): LegendRow[] {
        return [
            { color: "#26990f", translationKey: "calendar.MeasuredCorrectly" },
            { color: "#fec32d", translationKey: "calendar.notRepresentative" },
            { color: "#fb5555", translationKey: "calendar.excluded" },
            { color: "#453d55", translationKey: "calendar.predicted" },
        ];
    }

    //#region Dropdown

    createDropdownList<T>(
        dataList: T[],
        getData: (data: T) => any,
        getLabel: (data: T) => string,
        includeEmpty = true,
        emptyLabel = "form.empty",
        getStyleClass: (data: T) => string = null,
    ): SelectItem[] {
        const selectItems = new Array<SelectItem>();
        if (!dataList) return selectItems;

        if (includeEmpty) {
            const emptyOption = this.createEmptyOption(emptyLabel);
            selectItems.push(emptyOption);
        }

        for (const data of dataList) {
            const selectItem = this.createDropdownItem(data, getData, getLabel, getStyleClass);

            selectItems.push(selectItem);
        }

        return selectItems;
    }

    createDropdownItem<T>(
        data: T,
        getData: (data: T) => any,
        getLabel: (data: T) => string,
        getStyleClass: (data: T) => string = null,
    ): SelectItem {
        const selectItem = {
            label: getLabel(data),
            value: getData(data),
        } as SelectItem;

        if (getStyleClass) {
            selectItem.styleClass = getStyleClass(data);
        }

        return selectItem;
    }

    createEmptyOption(label: string, emptyOptionValue = null): SelectItem {
        const emptyOption = { label: "", value: emptyOptionValue } as SelectItem;

        if (label && label !== "") {
            this.setEmptyOptionLabel(emptyOption, label);

            this.translate.onLangChange.subscribe(() => {
                this.setEmptyOptionLabel(emptyOption, label);
            });
        }

        return emptyOption;
    }

    private setEmptyOptionLabel(emptyOption: SelectItem, label: string): string | void {
        if (!label || label === "") return label;

        this.translate.get(label).subscribe((emptyLabel) => {
            emptyOption.label = emptyLabel;
        });
    }

    //#endregion Dropdown

    //#region Calendar Localization

    calendarSettings() {
        return this.calendarSettingsSubject;
    }

    get locale(): string {
        const currentLang = this.translate.currentLang;
        const calendarSettings = this.cachedCalendarSettings[currentLang];
        if (!calendarSettings) return null;

        return calendarSettings.locale;
    }

    private updateCalendarSettings() {
        const currentLang = this.translate.currentLang;

        // const cachedCalendarSettings = this.cachedCalendarSettings[currentLang];
        // if (cachedCalendarSettings) {
        //     this.calendarSettingsSubject.next(cachedCalendarSettings);
        //     return;
        // }

        this.translate.get("calendar").subscribe((translations: { [key: string]: any }) => {
            const useNlFormat = currentLang === "nl" || currentLang === "de";
            // const isEn = currentLang === "en";

            const firstDayOfWeekIsMonday = useNlFormat;

            const locale = useNlFormat ? "nl-BE" : "en-GB";
            const dateFormat = "dd/mm/yy";
            const hourFormat = "24";
            const firstDayOfWeek = firstDayOfWeekIsMonday ? 1 : 0;
            const monthFormat = "mm/yy";

            const calendarSettings = new CalendarSettings(locale, dateFormat, monthFormat, hourFormat, firstDayOfWeek);

            this.cachedCalendarSettings[currentLang] = calendarSettings;

            this.calendarSettingsSubject.next(calendarSettings);

            translations = JsonUtils.deepClone(translations);
            for (const translationListKey of [
                "dayNames",
                "dayNamesShort",
                "dayNamesMin",
                "monthNames",
                "monthNamesShort",
            ]) {
                translations[translationListKey] = Object.values(translations[translationListKey]);
            }

            this.primeNGConfig.setTranslation(translations);
        });
    }

    //#endregion Calendar Localization
}

export class DateFormControl extends SigncoFormControl {
    selectionMode: "single" | "multiple" | "range" | undefined = "multiple";
    private shiftDown: boolean;
    private shiftDate: DateMeta;

    constructor(
        public calendar?: Calendar,
        formState?: any,
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    ) {
        super(formState, validatorOrOpts, asyncValidator);
        this.setCalendar(calendar);
    }

    setCalendar(calendar: Calendar) {
        if (this.calendar === calendar) return;

        if (calendar) {
            calendar.selectionMode = this.calendar ? this.calendar.selectionMode : this.selectionMode;
        }

        setTimeout(() => {
            this.calendar = calendar;

            if (this.calendar) {
                const originalOnDateSelect = this.calendar.onDateSelect;
                this.calendar.onDateSelect = (event: MouseEvent, dateMeta: DateMeta) => {
                    if (!this.calendar) return;

                    this.shiftDown = event.shiftKey;

                    // When deselecting the only selected month from the month view,
                    // the original function would set the value to an empty array.
                    // When that happens other functions like updateUI would throw an error because
                    // there is no check if the array is empty.
                    if (
                        this.calendar.view === "month" &&
                        this.calendar.isMultipleSelection() &&
                        this.calendar.isSelected(dateMeta) &&
                        this.value &&
                        this.value.length <= 1
                    ) {
                        this.calendar.inputFieldValue = undefined;
                        this.calendar.updateModel(undefined);
                        this.calendar.updateInputfield();
                        return;
                    }

                    if (!this.shiftDate || !this.shiftDown || !this.calendar.isMultipleSelection()) {
                        this.shiftDate = dateMeta;
                        originalOnDateSelect.call(this.calendar, event, dateMeta);
                        return;
                    }

                    const fromDate = this.dateMetaToDate(this.shiftDate);
                    const toDate = this.dateMetaToDate(dateMeta);

                    const lowestDate = fromDate < toDate ? fromDate : toDate;
                    const highestDate = lowestDate === fromDate ? toDate : fromDate;

                    const curMonth = lowestDate.getMonth();

                    const curDates = this.getValueAsMultiple();

                    for (let date = lowestDate; date <= highestDate; date = date.addDays(1)) {
                        if (curDates && !!curDates.find((x) => x.getTime() === date.getTime())) continue;

                        const meta = new DateMeta(
                            date.getDate(),
                            date.getMonth(),
                            date.getFullYear(),
                            date.getMonth() !== curMonth,
                        );

                        if (this.calendar.shouldSelectDate(meta)) {
                            this.calendar.selectDate(meta);
                        }
                    }

                    this.calendar.updateInputfield();

                    // this.shiftDate = null;
                };

                const originalUpdateInputfield = this.calendar.updateInputfield;
                this.calendar.updateInputfield = () => {
                    if (!this.calendar) return;

                    if (this.calendar.selectionMode === "multiple") {
                        // Check if the date is a perfect range (from-to with all dates inbetwene)
                        // If so we print it as [from] - [to]

                        const range = this.getValueAsRange();
                        if (!range || !range.to || !range.to.getTime || !range.from || !range.from.getTime) {
                            originalUpdateInputfield.call(this.calendar);
                            return;
                        }

                        if (Array.isArray(this.calendar.value)) {
                            const selectedDates = this.calendar.value as Date[];

                            // Take the difference between the dates and divide by milliseconds per day.
                            const datesBetweenFromTo = Math.round(
                                (range.to.getTime() - range.from.getTime()) / (1000 * 60 * 60 * 24),
                            );

                            // Do this only if
                            // 1) from !== to
                            // 2) calendar.value as Date[]'s length === amount of days between from - to + 1
                            if (
                                range.from.getTime() !== range.to.getTime() &&
                                selectedDates.length === datesBetweenFromTo + 1
                            ) {
                                const formattedValue =
                                    this.calendar.formatDateTime(range.from) +
                                    " - " +
                                    this.calendar.formatDateTime(range.to);

                                this.calendar.inputFieldValue = formattedValue;
                                this.calendar.updateFilledState();
                                if (
                                    this.calendar.inputfieldViewChild &&
                                    this.calendar.inputfieldViewChild.nativeElement
                                ) {
                                    this.calendar.inputfieldViewChild.nativeElement.value =
                                        this.calendar.inputFieldValue;
                                }

                                return;
                            }
                        }
                    }

                    originalUpdateInputfield.call(this.calendar);
                };

                const originalIsMonthSelected = this.calendar.isMonthSelected;
                const compareMonth = (date: Date, month: number, year: number) =>
                    date.getFullYear() === year && date.getMonth() === month;

                this.calendar.isMonthSelected = (month) => {
                    if (!this.calendar) {
                        return;
                    }

                    // When checking if a month is selected in a month view no need to compare day.
                    if (this.calendar.view === "month") {
                        if (!this.calendar.isMultipleSelection()) {
                            return this.value && compareMonth(this.value, month, this.calendar.currentYear);
                        }

                        return (
                            this.value &&
                            this.value.some((v: Date) => compareMonth(v, month, this.calendar.currentYear))
                        );
                    }

                    return originalIsMonthSelected.call(this.calendar, month);
                };

                this.calendar.updateInputfield();
            }
        });
    }

    dateMetaToDate(dateMeta: DateMeta): Date {
        return new Date(dateMeta.year, dateMeta.month, dateMeta.day);
    }

    toggleDateSelectionMode() {
        this.setDateSelectionMode(this.selectionMode === "range" ? "multiple" : "range");
    }

    setDateSelectionMode(selectionMode: "single" | "multiple" | "range" | undefined) {
        this.selectionMode = selectionMode;

        if (!this.calendar || this.calendar.selectionMode === selectionMode) return;

        this.calendar.selectionMode = selectionMode;

        this.updateInput();
    }

    private updateInput() {
        let dates: Date[] = null;

        if (this.selectionMode === "range") {
            if (Array.isArray(this.value) && this.value.length > 0) {
                dates = new Array<Date>();

                const datesAsTime = this.value.map((x) => (x as Date).getTime());
                const minDate = new Date(Math.min.apply(Math, datesAsTime));
                let maxDate = new Date(Math.max.apply(Math, datesAsTime));

                if (minDate === maxDate) {
                    maxDate = new Date(maxDate);
                }

                dates.push(minDate);
                dates.push(maxDate);
            }
        }

        if (this.selectionMode === "multiple") {
            if (Array.isArray(this.value)) {
                const from = this.value.length > 0 ? (this.value[0] as Date) : null;

                if (from) {
                    dates = new Array<Date>();
                    const to = this.value.length === 2 ? (this.value[1] as Date) || new Date(from) : new Date(from);

                    for (let date = from; date <= to; date = date.addDays(1)) {
                        dates.push(new Date(date));
                    }
                }
            }
        }

        this.patchValue(dates);

        // https://github.com/primefaces/primeng/issues/5933
        if (this.calendar) {
            setTimeout(() => {
                this.calendar.updateInputfield();
            });
        }
    }

    getValueAsRange(): { from: Date; to: Date } {
        if (!this.value) return null;

        let from: Date;
        let to: Date;

        if (this.selectionMode === "range") {
            from = this.value[0] as Date;
            to = this.value[1] as Date;
        }

        if (this.selectionMode === "single") {
            from = this.value as Date;
            to = this.value as Date;
        }

        if (this.selectionMode === "multiple") {
            const sortedDates = (this.value as Date | Date[]).toList<Date>().sortBy((x) => x.getTime());
            from = sortedDates.takeFirstOrDefault();
            to = sortedDates.takeLastOrDefault();
        }

        if (from === to) {
            to = new Date(to);
        }

        return {
            from: from,
            to: to || new Date(from),
        };
    }

    getValueAsMultiple(): Date[] {
        if (!this.value) return null;

        let dates: Date[];

        if (this.calendar.selectionMode === "multiple") dates = this.value as Date[];
        if (this.calendar.selectionMode === "single") dates = [this.value as Date];

        if (this.calendar.selectionMode === "range") {
            const range = this.getValueAsRange();

            dates = new Array<Date>();
            for (let date = range.from; date <= range.to; date = date.addDays(1)) {
                dates.push(new Date(date));
            }
        }

        return dates;
    }
}
