import { Injectable } from "@angular/core";
import { SubscriptionManager } from "@ramudden/core/utils";
import { IProgress, IProgressCreated } from "@ramudden/data-access/models/progress";
import { ProgressApi } from "@ramudden/data-access/resource/progress.api";
import { Observable, Subject } from "rxjs";
import { ErrorService } from "./error.service";

export class ProgressAction {
    constructor(
        public id: string,
        public name: string,
    ) {}

    lastProgress: IProgress;
    onComplete?: (action: ProgressAction) => void;
    onError?: (error: Response) => void;
}

@Injectable({
    providedIn: "root",
})
export class ProgressService {
    private readonly subscriptionManager = new SubscriptionManager();

    private actions: ProgressAction[] = [];
    private readonly actionsSubject = new Subject<ProgressAction[]>();
    private readonly progressSubject = new Subject<ProgressAction>();
    private readonly pendingSubject = new Subject<ProgressAction>();
    private readonly errorSubject = new Subject<ProgressAction>();

    subscribeActions(key: string, callback?: (res: ProgressAction[]) => void) {
        const subscription = this.actionsSubject.asObservable().subscribe(callback);
        this.subscriptionManager.add(`${key}actions`, subscription);
    }

    subscribeProgress(key: string, callback?: (res: ProgressAction) => void) {
        const subscription = this.progressSubject.asObservable().subscribe(callback);
        this.subscriptionManager.add(`${key}progress`, subscription);
    }

    subscribePendingAction(key: string, callback?: (res: ProgressAction) => void) {
        const subscription = this.pendingSubject.asObservable().subscribe(callback);
        this.subscriptionManager.add(`${key}pending`, subscription);
    }

    subscribeError(key: string, callback?: (res: ProgressAction) => void) {
        const subscription = this.errorSubject.asObservable().subscribe(callback);
        this.subscriptionManager.add(`${key}error`, subscription);
    }

    unsubscribe(key: string) {
        this.subscriptionManager.remove(key);
    }

    constructor(
        private readonly progressApi: ProgressApi,
        private readonly errorService: ErrorService,
    ) {}

    mock() {
        const createProgress = (progress: number) => {
            return {
                progress: progress,
                isDone: progress >= 100,
            } as IProgress;
        };

        const file1 = new ProgressAction("1", "Mock Render 1");
        file1.lastProgress = createProgress(100);
        this.actions.push(file1);

        const file2 = new ProgressAction("2", "Mock Render 2");
        file2.lastProgress = createProgress(50);
        file2.lastProgress.hasError = true;
        file2.lastProgress.message = "Foutmelding";
        this.actions.push(file2);

        const file3 = new ProgressAction("3", "Mock Render 3");
        file3.lastProgress = createProgress(99);
        this.actions.push(file3);

        const file4 = new ProgressAction("4", "Mock Render 4");
        file4.lastProgress = createProgress(0);
        file4.lastProgress.isPending = true;
        this.actions.push(file4);

        this.actionsSubject.next(this.actions);
    }

    isWorking(): boolean {
        return !!this.actions.find((x) => !x.lastProgress || (!x.lastProgress.isDone && !x.lastProgress.hasError));
    }

    addProgressCreatedObservable(
        observable: Observable<IProgressCreated>,
        onComplete: (action: ProgressAction) => void,
        onError: (error: Response) => void,
    ) {
        const onProgressCreated = (progressCreated: IProgressCreated) => {
            this.addProgressCreated(progressCreated, onComplete, onError);
        };

        const handleError = (error: Response) => {
            this.errorService.handleError(error);

            onError(error);
        };

        observable.subscribe(onProgressCreated, handleError);
    }

    addProgressCreated(
        progressCreated: IProgressCreated,
        onComplete: (action: ProgressAction) => void,
        onError: (error: Response) => void,
    ) {
        const action = new ProgressAction(progressCreated.progressId, progressCreated.name);
        action.onComplete = onComplete;
        action.onError = onError;

        this.addAction(action);
    }

    addAction(action: ProgressAction) {
        this.actions.push(action);
        this.actionsSubject.next(this.actions);

        this.startPolling(action);
    }

    private startPolling(action: ProgressAction) {
        const onProgress = (progress: IProgress) => {
            // if this is the first progress update, notify if it will be pending for a while
            if (!action.lastProgress && progress.isPending) {
                this.pendingSubject.next(action);
            }
            action.lastProgress = progress;

            this.progressSubject.next(action);
        };

        const onComplete = () => {
            if (action.onComplete) {
                action.onComplete(action);
            }

            this.clearCompletedActions();
        };

        const onError = (error: Response) => {
            if (action.onError) {
                action.onError(error);
            }

            this.errorService.handleError(error);

            this.errorSubject.next(action);
        };

        this.progressApi.pollProgress(action.id, onProgress).then(onComplete, onError);
    }

    removeAction(action: ProgressAction) {
        this.actions = this.actions.remove(action);
        this.actionsSubject.next(this.actions);
    }

    cancelAction(action: ProgressAction) {
        const onSuccess = () => {
            this.removeAction(action);
        };

        const onError = () => {
            // Happens when the task is actually completed, or when the action does not exist anymore on the server (e.g. after server restart)
            this.removeAction(action);
        };

        this.progressApi.cancel$(action.id).subscribe(onSuccess, onError);
    }

    clearCompletedActions() {
        this.actions = this.actions.filter(
            (x) => !x.lastProgress || (!x.lastProgress.isDone && !x.lastProgress.hasError),
        );
        this.actionsSubject.next(this.actions);
    }
}
