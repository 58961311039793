export {};

declare global {
    interface Object {
        toList<T>(): T[];
    }
}

const toList = function () {
    return Array.isArray(this) ? this : [this];
};
Object.defineProperty(Object.prototype, "toList", { value: toList });
