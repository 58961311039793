import { INotification } from "../models/notification";
import { ApiService } from "./api";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class NotificationApi extends ApiService<INotification, INotification, INotification> {
    getRoute(): string {
        return "Notifications";
    }

    markAllAsRead$() {
        return this.http.put(`${this.getUrl()}/MarkAllAsRead`, {});
    }
}