import { Constants } from "@ramudden/core/constants";

export class ColorUtils {
    private static cached: { [key: string]: number } = {};

    static GetLumaHex(color: string): number {
        color = color.replace("#", "");

        if (!this.cached[color]) {
            let c = color.substring(1); // strip #
            if (c.length === 3) c = c + c;

            const rgb = parseInt(c, 16); // convert rrggbb to decimal
            const r = (rgb >> 16) & 0xff; // extract red
            const g = (rgb >> 8) & 0xff; // extract green
            const b = (rgb >> 0) & 0xff; // extract conste

            // https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
            const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BTconst9

            this.cached[color] = luma;
        }

        return this.cached[color];
    }

    static isDark(luma: number) {
        return luma < 131;
    }

    static isDarkHex(color: string): boolean {
        const luma = this.GetLumaHex(color);
        return this.isDark(luma);
    }

    static getMarkerTextColorHex(labelColor: string): string {
        return ColorUtils.isDarkHex(labelColor) ? Constants.markerTextColorDark : Constants.markerTextColorLight;
    }
}
