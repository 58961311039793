import { Observable, Subject } from "rxjs";
import { EventManager } from "@angular/platform-browser";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class ResizeService {

    private readonly resizeSubject = new Subject<Window>();

    get onResize(): Observable<Window> {
        return this.resizeSubject.asObservable();
    }

    constructor(private readonly eventManager: EventManager) {

        // https://github.com/kryops/ng2-events/compare/v4.2.2...v5.0.0
        this.eventManager.addEventListener(document.defaultView || window as any, "resize", this.onResizeInternal.bind(this));
    }

    private onResizeInternal(event: UIEvent) {
        this.resizeSubject.next(event.target as Window);
    }

    get isMobile(): boolean {
        return screen && screen.width < 768;
    }

    get isDesktop(): boolean {
        return screen && screen.width > 1024;
    }

    get isFullscreen(): boolean {
        return document && (document.fullscreenElement || (document as any).mozFullScreenElement ||
            (document as any).webkitFullscreenElement || (document as any).msFullscreenElement);
    }

    trigger() {
        window.dispatchEvent(new Event("resize"));
    }

    openFullscreen(htmlElement: HTMLElement = null) {
        if (!htmlElement) {
            htmlElement = document.documentElement;
        }

        htmlElement?.requestFullscreen({
            navigationUI: "hide"
        });
    }
}