import { ILocation, ICoordinate, IAddress } from "./location";
import { IOfficialLocationId } from "./official-location";
import { IServiceModelBase } from "./servicemodelbase";
import { IDatePeriodSet } from "./date-period-set";
import { BackendRights } from "./backend-rights";
import { IUserProject } from "./user-project";

export enum Roles {
    DomainAdministrator = "domainAdministrator",
    InstallationManager = "installationManager",
    InstallationTechnicalManager = "installationTechnicalManager",
    PlanningManager = "planningManager",
    PlanningViewer = "planningViewer",
    InstallationRealtimeViewer = "installationRealtimeViewer",
    InstallationViewer = "installationViewer",
    AssignmentsManager = "assignmentsManager",
    AssignmentsViewer = "assignmentsViewer"
}

export enum RoleCategory {
    Installations = "installations",
    Administration = "administration",
    Assignments = "assignments",
    Planning = "planning"
}

export interface IRole extends IServiceModelBase {
    name: Roles;
}

export interface IRoleWithCategory {
    roleId: Roles;
    categoryId: RoleCategory;
}

export class AuthorizationInfo {
    user: IUser;
    isDomainAdministrator: boolean;
    isActualDomainAdministrator: boolean;
}

export interface IOrganization extends IServiceModelBase {
    id: number;
    name: string;
    logoUrl: string;
    location: ILocation;
    users: IUser[];
    meteoFactorCalculationTypeBikeId: string;
    meteoFactorCalculationTypeCarId: string;
    datePeriodSet: IDatePeriodSet;
    reportsToUsersAvailableFrom?: Date;
    parentOrganization?: IOrganization;
    parentOrganizationId?: number;
    level: number; // calculated at frontend
}

export interface IUserSummary extends IServiceModelBase {
    firstName: string;
    lastName: string;
    email: string;
    organizationIds: number[];
}

export interface IUserOrganization {
    organization: IOrganization;

}

export interface IUser extends IServiceModelBase {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    gsm: string;
    userOrganizations: IUserOrganization[];
    userProjects: IUserProject[];
    languageId?: Language;
    userRoles: Roles[];
    rights: BackendRights[];
    linkedOrganizationIds: number[];
}

export class UserOrganizationCreator {
    organizationId: number;
}

export class UserCreator {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    gsm: string;
    languageId?: Language;
    organizations: number[];
    roles: Roles[];
}

export class UserUpdater extends UserCreator {
    constructor(existingUser: IUser = null) {
        super();

        this.id = existingUser.id;
        this.organizations = existingUser.userOrganizations.map(x => {
            return x.organization.id;
        });
        this.roles = existingUser.userRoles;
        this.firstName = existingUser.firstName;
        this.lastName = existingUser.lastName;
        this.email = existingUser.email;
        this.phoneNumber = existingUser.phoneNumber;
        this.gsm = existingUser.gsm;
    }

    id: number;
    isObsolete?: boolean;
}

export class OrganizationCreator {
    name: string;
    coordinate: ICoordinate;
    address: IAddress;
    officialId: IOfficialLocationId;
    meteoFactorCalculationTypeBikeId: string;
    meteoFactorCalculationTypeCarId: string;
    datePeriodSetId: number;
    reportsToUsersAvailableFrom?: Date;
    parentOrganizationId?: number;
}

export enum Language {
    English = "en",
    Dutch = "nl",
    French = "fr",
    German = "de",
    Swedish = "sv",
}


export class OrganizationUpdater extends OrganizationCreator {
    id: number;

    constructor(existingOrganization: IOrganization = null) {
        super();
        if (!existingOrganization) return;

        this.id = existingOrganization.id;
        this.name = existingOrganization.name;
        this.reportsToUsersAvailableFrom = existingOrganization.reportsToUsersAvailableFrom;

        if (existingOrganization.location) {
            this.coordinate = existingOrganization.location.coordinate;
            this.address = existingOrganization.location.address;
            this.officialId = existingOrganization.location.officialId;
        }

        if (existingOrganization.datePeriodSet) {
            this.datePeriodSetId = existingOrganization.datePeriodSet.id;
        }

        this.meteoFactorCalculationTypeBikeId = existingOrganization.meteoFactorCalculationTypeBikeId;
        this.meteoFactorCalculationTypeCarId = existingOrganization.meteoFactorCalculationTypeCarId;
    }
}
