import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "./modal.service";
import { Injectable } from "@angular/core";

export interface IChangeGuard {
    canDeactivateCheck: () => boolean;
    onDeactivate: () => void;
}

@Injectable({
    providedIn: "root"
})
export class ChangeGuardService {

    private component: IChangeGuard;

    constructor(
        private readonly translate: TranslateService,
        private readonly modalService: ModalService) {
    }

    setComponent(component: IChangeGuard) {
        this.component = component;
    }

    clearComponent() {
        this.setComponent(null);
    }

    canDeactivateCheck(component?: IChangeGuard): boolean {
        component = component || this.component;
        if (!component) return true;

        return component.canDeactivateCheck();
    }

    canDeactivate(component?: IChangeGuard): Promise<boolean> {

        component = component || this.component;

        return new Promise<boolean>((resolve) => {
            if (!component) {
                resolve(true);
                return;
            }

            if (!component.canDeactivateCheck()) {
                const onOkay = () => {
                    component.onDeactivate();
                    resolve(true);
                };

                const onCancel = () => {
                    resolve(false);
                };

                this.modalService.confirm(this.translate.instant("form.unsavedWarning"), onOkay, onCancel);
            } else {
                resolve(true);
            }
        });
    }
}