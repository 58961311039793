import { IDrivingLane, DrivingLaneCreator } from "../models/driving-lane";
import { ApiService } from "./api";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class DrivingLaneApi extends ApiService<IDrivingLane, DrivingLaneCreator, DrivingLaneCreator> {
    getRoute(): string {
        return "DrivingLanes";
    }

    restore$(id: number): Observable<void> {
        const url = `${super.getUrl()}/${id}/Restore`;
        return this.http.patch<void>(url, null);
    }
}