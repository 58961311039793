import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

class ByteTier {
    label: string;
    size: number;
    decimals: number;
}

@Pipe({ name: "byte" })
export class BytePipe implements PipeTransform {
    private tiers: ByteTier[] = [
        {
            label: "measurements.byte",
            size: 1024,
            decimals: 0
        },
        {
            label: "measurements.kb",
            size: 1024,
            decimals: 0
        },
        {
            label: "measurements.mb",
            size: 1024,
            decimals: 2
        },
        {
            label: "measurements.gb",
            size: 1024,
            decimals: 0
        }
    ];

    constructor(private readonly translateService: TranslateService) {

    }

    transform(bytes: number, includeLabel = true) {
        if (!bytes) return "";

        let curTier = this.tiers[0];
        let nextTier = this.tiers[1];
        let curValue = bytes;

        while (nextTier && curValue > curTier.size) {
            curValue /= curTier.size;

            curTier = nextTier;

            const curIndex = this.tiers.indexOf(curTier);
            if (this.tiers.indexOf(curTier) === this.tiers.length - 1) {
                nextTier = null;
            } else {
                nextTier = this.tiers[curIndex + 1];
            }
        }

        return `${curValue.toFixed(curTier.decimals)}${includeLabel ? ` ${this.translateService.instant(curTier.label)}` : ""}`;
    }
}