import { BasicMarkerOptions, createBasicMarker, IBasicMarker } from "@portal/components/map-basic";
import { ColorUtils, TimerUtils } from "@ramudden/core/utils";
import { MapIconStateViewModel } from "@ramudden/services";
import { GroupMarkerComponent } from "../components/group-marker/group-marker.component";
import { MarkerContext } from "./marker-context";

/**
 * A marker on the map that represents a Signco Serv location.
 */
export class LocationMarker {
    public constructor(
        public context: MarkerContext,
        public iconModel?: MapIconStateViewModel,
        partialOptions?: Partial<BasicMarkerOptions>,
    ) {
        const defaultOptions = new BasicMarkerOptions(context.latLng);
        defaultOptions.labelContent = context.code;

        const options = Object.assign(defaultOptions, partialOptions);

        if (options.labelColor) options.textColor = ColorUtils.getMarkerTextColorHex(options.labelColor);

        if (iconModel) {
            const yAnchor = 20 + iconModel.defaultIcon.anchor?.y; // 20 is the height of the label
            options.labelAnchor = new google.maps.Point(-6, yAnchor);
            options.icon = iconModel.defaultIcon;
        }

        TimerUtils.time("createBasicMarker", () => {
            this.marker = createBasicMarker(options);
        });
    }

    marker: IBasicMarker;
    groupMarker: GroupMarkerComponent;
    isSelected?: boolean; // Enables us to show the icon as selected, even if the user didn't click on it

    /**
     * There are two modes to display a selected marker:
     * 1. Change the state of the icon
     * 2. Set focus state
     */
    setSelected(selected: boolean, changeIcon = true) {
        if (this.isSelected === selected) return;
        this.isSelected = selected;

        if (selected === false) {
            this.marker.setIcon(this.iconModel.defaultIcon);
            this.marker.setFocusState(false);
            // this.marker.closeBalloonPopup();
            // this.marker.setLabelVisible(true);
            return;
        }

        if (changeIcon) {
            this.marker.setIcon(this.iconModel.selectedIcon);
        }

        this.marker.setFocusState(true);
    }

    public updateIcon(icon: MapIconStateViewModel) {
        this.iconModel = icon;
    }
}
