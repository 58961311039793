import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class WebsiteService {

    private productName = "Fero Group - Signco"; // Default website name
    private companyName = "Signco"; // Default company name
    constructor() {
        // Set website name based on the current URL
        const host = window.location.hostname;
        if (host.includes("berghaus")) {
            this.productName = "BERGHAUS-CONNECT";
            this.companyName = "Peter Berghaus GmbH";
        }
    }

    getProductName(): string {
        return this.productName;
    }

    getCompanyName(): string {
        return this.companyName;
    }

    isBerghausConnect(): boolean {
        return this.productName === "BERGHAUS-CONNECT";
    }

    getB2cEnvironment(): string {
        return this.isBerghausConnect() ? "Berghaus" : "";
    }
}