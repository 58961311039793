import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

// Transforms validation errors to human readable messages
// Very similar to FormValidationService, but also works outside of reactive forms
@Pipe({ name: "translateValidation" })
export class TranslateValidationPipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {
    }

    transform(errors: any): string {
        const errorMessages = new Array<string>();

        for (const error in errors) {
            if (errors.hasOwnProperty(error)) {
                errorMessages.push(this.getValidationMessage(error, errors[error]));
            }
        }
        const result = errorMessages.join(" - ");
        return result;
    }

    private getValidationMessage(error: string, param: any): string {
        return this.translateService.instant(`validation.${error}`, param);
    }
}