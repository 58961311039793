import { Component, ElementRef, HostListener, Input, ViewChild } from "@angular/core";
import { DateRangePipe } from "../../pipes/date-range.pipe";

@Component({
    selector: "app-timeline-activity",
    templateUrl: "./timeline-activity.component.html",
    styleUrls: ["./timeline-activity.component.scss"],
})
export class TimelineActivityComponent {
    @ViewChild("timelineBody", { static: false }) timelineBody: ElementRef<HTMLDivElement>;

    private _config: TimelineConfig;
    @Input() set config(config: TimelineConfig) {
        this.setConfig(config);
    }

    get config() {
        return this._config;
    }

    protected days: Date[];
    protected activities: { key: string; data: TimelineData[] }[];
    protected zoom = 0.11;

    private setConfig(config: TimelineConfig) {
        this._config = config;

        if (this.config) {
            const earliestDay = new Date(Math.min(...this.config.data.map((x) => x.startDate.getTime()))).toMidnight();
            const lastDay = new Date(
                Math.max(...this.config.data.map((x) => (x.endDate ? x.endDate.getTime() : new Date().getTime()))),
            ).toMidnight();

            const days = new Array<Date>();
            let curDay = earliestDay;
            const earliestTime = curDay.getTime();

            while (curDay <= lastDay) {
                days.push(curDay);
                curDay = curDay.addDays(1);
            }

            const previousMinute: { [key: string]: number } = {};
            for (const data of this.config.data) {
                data.startHour ??= Math.floor(Math.abs(data.startDate.getTime() - earliestTime) / 3600000);
                let minuteStarted = Math.floor(Math.abs(data.startDate.getTime() - earliestTime) / 60000);

                let offsetMinutes = 0;
                if (minuteStarted <= previousMinute[data.description]) {
                    offsetMinutes = 1;
                    minuteStarted = previousMinute[data.description] + offsetMinutes;
                }
                previousMinute[data.description] = minuteStarted;

                data.startMinute ??= minuteStarted;
                // seconds = Math.floor(difference % 60000 / 1000);

                const hasEndDate = !!data.endDate;

                if (!hasEndDate) {
                    data.endDate = new Date(); //lastDay.addDays(1);
                }

                data.endHour ??= Math.round(Math.abs(data.endDate.getTime() - earliestTime) / 3600000);
                const minuteEnded = Math.floor(Math.abs(data.endDate.getTime() - earliestTime) / 60000) + offsetMinutes;

                data.endMinute ??= minuteEnded;

                const dateRangePipeOutput = DateRangePipe.transform({
                    from: data.startDate,
                    until: hasEndDate ? data.endDate : null,
                });
                data.tooltip ??= `${data.description} (${dateRangePipeOutput})`;
                data.totalHours ??= data.endHour - data.startHour;
            }

            this.days = days;

            const activities: { key: string; data: TimelineData[] }[] = [];
            for (const activity of this.config.data.groupByFunc((x) => x.groupField ?? x.description)) {
                activities.push({ key: activity.key, data: activity.members });
            }
            this.activities = activities;
        } else {
            this.days.length = 0;
            this.activities.length = 0;
        }

        setTimeout(() => {
            this.timelineBody.nativeElement.scrollLeft = this.timelineBody.nativeElement.scrollWidth;
        });
    }

    // appScrollDrag already handles the left-and-right, with ctrl we'd like to zoom
    @HostListener("wheel", ["$event"])
    protected handleScroll(event: WheelEvent) {
        if (!event.ctrlKey) return;

        const viewportX = this.timelineBody.nativeElement.scrollLeft;
        const eventPositionInPercentage =
            event.clientX / (this.timelineBody.nativeElement.offsetLeft + this.timelineBody.nativeElement.clientWidth);
        const newCenterRelativePosition =
            (viewportX + this.timelineBody.nativeElement.clientWidth * eventPositionInPercentage) /
            this.timelineBody.nativeElement.scrollWidth;

        const isZoomingIn = event.deltaY < 0;

        let factor = 16;
        if (this.zoom < factor) {
            factor = 1;
            if (this.zoom < factor) {
                factor = 0.1;
            }
        }

        const zoomChange = factor * (isZoomingIn ? 1 : -1);
        const calculatedZoom = this.zoom + zoomChange;
        this.zoom = Number(Math.min(256, Math.max(0.11, calculatedZoom)));
        event.stop();

        setTimeout(() => {
            this.timelineBody.nativeElement.scrollLeft =
                this.timelineBody.nativeElement.scrollWidth * newCenterRelativePosition -
                this.timelineBody.nativeElement.clientWidth / 2;
        });
    }

    protected shouldShowHour(index: number): boolean {
        if (this.zoom >= 1.5) return true;

        const moduloFactor =
            this.zoom >= 1
                ? 2
                : this.zoom >= 0.8
                  ? 3
                  : this.zoom >= 0.6
                    ? 4
                    : this.zoom >= 0.4
                      ? 6
                      : this.zoom >= 0.2
                        ? 12
                        : 12;

        return index % moduloFactor == 0;
    }
}

export class TimelineConfig {
    title: string;
    data: TimelineData[];
}

export class TimelineData {
    groupField?: string;
    description: string;
    startDate: Date;
    endDate?: Date;
    color?: string;
    icon?: string;
    startHour?: number;
    startMinute?: number;
    endHour?: number;
    endMinute?: number;
    totalHours?: number;
    tooltip?: string;
}
