import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { BackendRights } from "@ramudden/data-access/models/backend-rights";
import { GlobalEventsService } from "@ramudden/services";
import { Observable, map } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class PlanningGuard {
    constructor(
        private readonly globalEventsService: GlobalEventsService,
        private readonly router: Router,
    ) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.globalEventsService.authorizationInfo$.pipe(
            map((e) => {
                if (e != null) {
                    const rights = this.globalEventsService.getCurrentRights();
                    if (!rights) return false;
                    if (rights.backendRights && rights.backendRights.includes(BackendRights.EditPlanning)) {
                        return true;
                    }
                    if (rights.backendRights && rights.backendRights.includes(BackendRights.ViewPlanning)) {
                        // return new url tree navigating to external user planning
                        return this.router.createUrlTree(["/planning/external-user-planning"]);
                    }

                    // No Edit or View Right => No access
                    return false;
                }
                return false;
            }),
        );
    }
}
