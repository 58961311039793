import { ValidatorFn, AbstractControl } from "@angular/forms";

class MaxLineLengthValidationResult {
    maxLines: { max: number };
    maxLineLength: { max: number };

    setMaxLines(max: number) {
        this.maxLines = { max: max };
    }

    setMaxLineLength(max: number) {
        this.maxLineLength = { max: max };
    }

    hasError(): boolean {
        return !!(this.maxLines || this.maxLineLength);
    }
}

export class MaxLineLengthValidator {
    static loopingOverControls: boolean;

    static create(maxLineLength: number, getLineLength: (input: string) => number = null, maxLines: number = null): ValidatorFn {
        return (c: AbstractControl) => {
            const validationResult = new MaxLineLengthValidationResult();

            const lines = (c.value as string).split("\n");

            if (maxLines && lines.length > maxLines) {
                validationResult.setMaxLines(maxLines);
            }

            if (maxLineLength) {
                for (const line of lines) {
                    const lineLength = !getLineLength ? line.length : getLineLength(line);

                    if (lineLength > maxLineLength) {
                        validationResult.setMaxLineLength(maxLineLength);
                        break;
                    }
                }
            }

            return validationResult.hasError() ? validationResult : null;
        };
    }
}