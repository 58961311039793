import { ILocation } from "./location";
import { IOrganization, IUser } from "./user";
import { IDevice } from "./device";
import { IMeasuringPointNavigator } from "./measuring-point";
import { IAssignment } from "./assignment";
import { IParkingBan } from "./parking-ban";
import { IProject } from "./project";
import { IReportHistory } from "./reports";
import { ITask } from "./task";

export interface IAuditTrail {
    id: number;
    timeStamp: Date;
    eventType: string;
    relatedObjectId: string;
    relatedObjectType: string;
    details: any;
    creatorId: number;
    creator: IUser;
    ownerId: number;
    owner: IOrganization;
    // Related objects
    assignmentId?: number;
    assignment?: IAssignment;
    deviceId?: number;
    device?: IDevice;
    locationId?: number;
    location?: ILocation;
    measuringPointId?: number;
    measuringPoint?: IMeasuringPointNavigator;
    organizationId?: number;
    organization?: IOrganization;
    parkingBanId?: number;
    parkingBan: IParkingBan;
    projectId?: number;
    project?: IProject;
    reportHistoryId?: number;
    reportHistory?: IReportHistory;
    taskId?: number;
    task?: ITask;
    userId?: number;
    user?: IUser;
}

export class AuditTrailCreator {
    comments: string;
    eventType: string;
    auditTrailIds: number[];
    relatedObjectId: number;
    relatedObjectType: string;
}

export class AuditTrailExportOptions {
    objectId: number;
    type: AuditTrailRelatedObjectType;
}

export enum AuditTrailRelatedObjectType {
    Device = "device",
    MeasuringPoint = "measuringPoint",
    Report = "report",
    User = "user",
    Location = "location",
    Organization = "organization",
    Project = "project",
    Assignment = "assignment",
    SafetyQuestion = "safetyQuestion",
    ParkingBan = "parkingBan",
    Task = "task"
}
