import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
    selector: "[appFileDragAndDrop]"
})
export class FileDragAndDropDirective {

    @Output() fileDropped = new EventEmitter<FileList>();
    private counter = 0;

    constructor(private readonly el: ElementRef) {

    }

    @HostListener("dragenter", ["$event"]) dragEnter(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.counter++;
        (<HTMLElement>this.el.nativeElement).classList.add("drag-over");
    }

    @HostListener("dragleave", ["$event"]) dragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.counter--;
        if (this.counter === 0) {
            (<HTMLElement>this.el.nativeElement).classList.remove("drag-over");
        }
    }

    @HostListener("dragover", ["$event"]) dragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener("drop", ["$event"]) drop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (!event.dataTransfer?.files || event.dataTransfer.files.length === 0) {
            return;
        }

        this.fileDropped.emit(event.dataTransfer.files);
        this.counter = 0;
        (<HTMLElement>this.el.nativeElement).classList.remove("drag-over");
    }
}
