import { Injectable } from "@angular/core";

export class ClickAction {
    constructor(
        public readonly key: string,
        public readonly element: HTMLElement,
        public readonly action: () => void) {

    }
}

export class KeyPressAction {
    constructor(
        public readonly key,
        public readonly action: () => void,
        public readonly persist = false) {
    }
}

@Injectable({
    providedIn: "root"
})
export class DocumentEventService {

    private onClicks = new Array<ClickAction>();
    private onKeyPresses = new Array<KeyPressAction>();

    constructor() {
        document.onclick = (event: MouseEvent) => {
            if (!(event.target instanceof HTMLElement) || !this.onClicks.length) return;

            const target = event.target as HTMLElement;

            for (let i = this.onClicks.length - 1; i >= 0; i--) {
                const onClick = this.onClicks[i];

                if (onClick && !onClick.element.contains(target)) {
                    onClick.action();
                    this.onClicks = this.onClicks.remove(onClick);
                }
            }
        };

        document.onkeyup = (event: KeyboardEvent) => {
            if (!this.onKeyPresses.length) return;

            const keyPressActions = this.onKeyPresses.filter(x => x.key === event.key);

            for (const keyPressAction of keyPressActions.clone()) {
                keyPressAction.action();

                if (!keyPressAction.persist) {
                    this.onKeyPresses = this.onKeyPresses.remove(keyPressAction);
                }
            }
        };
    }

    addOnClick(key: string, element: HTMLElement, action: () => void) {
        if (!!this.onClicks.find(x => x.key === key)) return;

        setTimeout(() => {
            this.onClicks.push(new ClickAction(key, element, action));
        });
    }

    removeOnClick(key: string) {
        this.onClicks = this.onClicks.filter(x => x.key !== key);
    }

    addOnKeyPress(key: string, action: () => void, persist = false) {
        if (!!this.onKeyPresses.find(x => x.key === key)) return;

        setTimeout(() => {
            this.onKeyPresses.push(new KeyPressAction(key, action, persist));
        });
    }

    removeOnKeyPress(key: string) {
        this.onKeyPresses = this.onKeyPresses.filter(x => x.key !== key);
    }

}