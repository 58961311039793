
export enum VehicleType {
    Unknown = "unknown",
    Car = "car",
    Bike = "bike",
    Pedestrian = "pedestrian"
}

export enum VehicleSubType {
    bike_Children = "bike_Children",
    bike_Youth = "bike_Youth",
    bike_Adult = "bike_Adult",
    bike_Moped = "bike_Moped",
    bike_Extended = "bike_Extended",
    bike_Unknown = "bike_Unknown",
    bike_Wheely = "bike_Wheely",
    light_I__I = "light_I__I",
    light_I__IPI__I = "light_I__IPI__I",
    light_I__I__I = "light_I__I__I",
    light_Car = "light_Car",
    light_Van = "light_Van",
    medium_I_____I = "medium_I_____I",
    medium_I_____II = "medium_I_____II",
    medium_II_____II = "medium_II_____II",
    medium_IIII = "medium_IIII",
    medium_IIIII = "medium_IIIII",
    medium_IIIIII = "medium_IIIIII",
    medium_IIIIIII = "medium_IIIIIII",
    medium_Bus = "medium_Bus",
    medium_Truck = "medium_Truck",
    heavy_I__I_____I = "heavy_I__I_____I",
    heavy_I_II___I = "heavy_I_II___I",
    heavy_I__I___II = "heavy_I__I___II",
    heavy_I__I_I__I = "heavy_I__I_I__I",
    heavy_I__II__II = "heavy_I__II__II",
    heavy_I__I__III = "heavy_I__I__III",
    heavy_I_II_I__I = "heavy_I_II_I__I",
    heavy_I__I_I_II = "heavy_I__I_I_II",
    heavy_I_II__III = "heavy_I_II__III",
    heavy_I_II_I_II = "heavy_I_II_I_II",
    heavy_Truck = "heavy_Truck",
    car_Unknown = "car_Unknown",
    motorcycle = "motorcycle",
    pedestrian = "pedestrian",
    unknown = "unknown"
}

export enum VehicleCategory {
    Unknown = "unknown",
    Light = "light",
    Medium = "medium",
    Heavy = "heavy",
    Bike = "bike",
    Moped = "moped",
    Motorcycle = "motorcycle",
    Pedestrian = "pedestrian"
}

// Represents a vehicle that was detected
export class Vehicle {
    index = 0;
    timestamp: Date;
    sensorId: string;
    speed?: number;
    isReverse: boolean;
    category?: VehicleCategory;

    static categoryMap = {
        L: VehicleCategory.Light,
        M: VehicleCategory.Medium,
        H: VehicleCategory.Heavy,
        B: VehicleCategory.Bike,
        MO: VehicleCategory.Moped,
        MC: VehicleCategory.Moped,
        P: VehicleCategory.Pedestrian
    };

    // #region serialize

    // Serializes to our compact representation
    public serializeToSignco(): string {
        const direction = this.isReverse ? 1 : 0;
        const timestampString = this.formatTimestamp(this.timestamp);
        const keyValues = this.getKeyValuePairs();

        return `V <${this.index}> ${timestampString} ${this.sensorId ?? "?"} ${direction} ${keyValues}`;
    }

    private formatTimestamp(timestamp: Date): string {
        const year = timestamp.getFullYear();
        const month = this.padZero(timestamp.getMonth() + 1);
        const day = this.padZero(timestamp.getDate());
        const hours = this.padZero(timestamp.getHours());
        const minutes = this.padZero(timestamp.getMinutes());
        const seconds = this.padZero(timestamp.getSeconds());
        const milliseconds = this.padZero(timestamp.getMilliseconds(), 3);

        return `${year}${month}${day}T${hours}${minutes}${seconds}.${milliseconds}`;
    }

    private padZero(num: number, width: number = 2): string {
        return num.toString().padStart(width, "0");
    }

    private getKeyValuePairs(): string {
        const keyValuePairs = [];

        if (this.speed) {
            keyValuePairs.push(`s=${this.speed}`);
        }

        if (this.category) {
            const categoryCode = Object.keys(Vehicle.categoryMap).find(key => Vehicle.categoryMap[key] === this.category);
            if (categoryCode) {
                keyValuePairs.push(`c=${categoryCode}`);
            }
        }

        return keyValuePairs.join(" ");
    }
    //#endregion serialize

    // #region deserialize
    // Deserializes from the compact representation
    public static deserializeFromSignco(input: string): Vehicle {
        const parts = input.split(" ");

        if (parts.length < 6 || parts[0] !== "V") {
            throw new Error("Invalid input string");
        }

        const vehicle = new Vehicle();
        vehicle.index = parseInt(parts[1].slice(1, -1), 10);
        vehicle.timestamp = Vehicle.parseTimestamp(parts[2]);
        vehicle.sensorId = parts[3];
        vehicle.isReverse = parseInt(parts[4], 10) === 1;

        for (let i = 5; i < parts.length; i++) {
            const keyValue = parts[i].split("=");
            if (keyValue.length === 2) {
                const key = keyValue[0];
                const value = keyValue[1];

                switch (key) {
                    case "s":
                        vehicle.speed = parseFloat(value);
                        break;
                    case "c":
                        vehicle.category = Vehicle.getCategoryFromCode(value);
                        break;
                    // Handle additional key-value pairs if needed
                    default:
                        break;
                }
            }
        }

        return vehicle;
    }

    private static parseTimestamp(timestampString: string): Date {
        const year = parseInt(timestampString.slice(0, 4), 10);
        const month = parseInt(timestampString.slice(4, 6), 10) - 1;
        const day = parseInt(timestampString.slice(6, 8), 10);
        const hours = parseInt(timestampString.slice(9, 11), 10);
        const minutes = parseInt(timestampString.slice(11, 13), 10);
        const seconds = parseInt(timestampString.slice(13, 15), 10);
        const milliseconds = parseInt(timestampString.slice(16), 10);

        return new Date(year, month, day, hours, minutes, seconds, milliseconds);
    }

    private static getCategoryFromCode(code: string): VehicleCategory {
        return Vehicle.categoryMap[code] || null;
    }
    // #endregion deserialize
}