import { IServiceModelBase } from "./servicemodelbase";
import { IUser } from "./user";
import { IProject } from "./project";
import { ICoordinate, ILocation } from "./location";
import { TaskType, SignMaterial, ITask } from "./task";
import { IParkingBan } from "./parking-ban";
import { IWorkerBase } from "./worker";

export interface IAssignment extends IServiceModelBase {
    name: string;
    description: string;
    receivalDate: Date;
    start?: Date;
    end?: Date;
    isRecurring: boolean;
    recurrencePattern: string;
    isValid: boolean;
    needParkingBans?: boolean;
    taskTypeId: TaskType;
    signMaterialId: SignMaterial;
    isMachineWork: boolean;

    parentAssignmentId?: number;
    parentAssignment: IAssignment;
    subAssignments: IAssignment[];
    project: IProject;
    administrativeResponsible: IUser;
    executiveResponsible: IUser;
    responsibleClient: IUser;
    currentStatus: IAssignmentStatusHistory;
    priority: IAssignmentPriority;
    tasks: ITask[];
    parkingBans: IParkingBan[];
    location: ILocation;
    locationId: number;
}

export class AssignmentUpdater {
    id: number;
    name: string;
    description?: string;
    start?: Date;
    end?: Date;
    isRecurring: boolean;
    recurrencePattern: string;
    isValid: boolean;
    projectId?: number;
    parentAssignmentId?: number;
    administrativeResponsibleId: number;
    executiveResponsibleId: number;
    responsibleClientId: number;
    currentStatus: IAssignmentStatusHistory;
    currentStatusTimestamp: Date;
    priorityId: AssignmentPriorityValue;
    taskTypeId: TaskType;
    signMaterialId: SignMaterial;
    isMachineWork: boolean;
    needParkingBans: boolean;

    subAssignments: AssignmentUpdater[];

    init(existingAssignment: IAssignment): AssignmentUpdater {
        this.id = existingAssignment.id;
        this.name = existingAssignment.name;
        this.description = existingAssignment.description;
        this.start = existingAssignment.start;
        this.end = existingAssignment.end;
        this.isRecurring = existingAssignment.isRecurring;
        this.recurrencePattern = existingAssignment.recurrencePattern;
        this.isValid = existingAssignment.isValid;
        this.projectId = existingAssignment
            ? existingAssignment.project.id
            : undefined;
        this.needParkingBans = existingAssignment.needParkingBans;
        this.isMachineWork = existingAssignment.isMachineWork;
        this.signMaterialId = existingAssignment.signMaterialId;
        this.taskTypeId = existingAssignment.taskTypeId;
        this.executiveResponsibleId = existingAssignment.executiveResponsible ? existingAssignment.executiveResponsible.id : undefined;
        this.responsibleClientId = existingAssignment.responsibleClient ? existingAssignment.responsibleClient.id : undefined;
        this.administrativeResponsibleId = existingAssignment.administrativeResponsible ? existingAssignment.administrativeResponsible.id : undefined;
        this.parentAssignmentId = existingAssignment.parentAssignmentId ? existingAssignment.parentAssignmentId : undefined;

        return this;
    }
}

export class AssignmentCreator extends AssignmentUpdater {
    coordinate: ICoordinate;
}

export interface IAssignmentStatusHistory extends IServiceModelBase {
    timestamp: Date;
    assignment: IAssignment;
    statusId: AssignmentStatus;
    worker: IWorkerBase;
}

export class AssignmentStatusHistoryCreator { }

export interface IAssignmentPriority {
    priorityId: AssignmentPriorityValue;
    color: string;
}

export enum AssignmentPriorityValue {
    Low = "low",
    Medium = "medium",
    High = "high",
}

export enum AssignmentStatus {
    Active = "active",
    NotStarted = "notStarted",
    Finished = "finished",
    InProgress = "inProgress",
    Cancelled = "cancelled",
    Reopened = "reopened"
}

export interface IAssignmentSubtask {
    id: number;
    name: string;
    assignmentName: string;
    assignmentId: number;
    locationCode: string;
    type: AssignmentSubtaskType;
}

export enum AssignmentSubtaskType {
    Task = "task",
    ParkingBan = "parkingBan",
}

export interface AssignmentSubtaskSearchParameters {
    locationId?: number;
    assignmentIds?: number[];
}
