<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [breakpoints]="{'960px': '600px', '640px': 'calc(100% - 40px)'}"
          [appendTo]="appendTo"
          [closable]="false"
          (onShow)="onShow()"
          (onHide)="onHide()"
          styleClass="general-dialog">

    <p-header>
        <ng-container *ngIf="visible && options">
            <div class="flex align-center">
                <div *ngIf="options.icon">
                    <app-svg-icon [icon]="options.icon">
                    </app-svg-icon>
                </div>

                <div class="ml-8">
                    {{options.title | translate}}
                </div>
            </div>
        </ng-container>
    </p-header>

    <ng-container *ngIf="visible">
        <div class="p-16">
            <p [innerHTML]="options.body | translate | safeHtml"></p>

            <img *ngIf="options.image"
                 [src]="options.image | safeHtml">
        </div>
    </ng-container>

    <p-footer>
        <ng-container *ngIf="visible && options">
            <label *ngIf="options.doNotShowAgainVisible"
                   class="label-checkbox m-input-checkbox mr-8">
                <input type="checkbox"
                       id="dialog-doNotShowAgain"
                       [(ngModel)]="options.doNotShowAgain" />
                {{"form.doNotShowAgain" | translate}}
            </label>

            <button *ngIf="options.rejectVisible"
                    id="buttonDialogReject"
                    class="m-btn m-btn-default"
                    (click)="reject()"
                    type="button">
                {{"form.cancel" | translate}}
            </button>

            <button *ngIf="options.acceptVisible"
                    id="buttonDialogAccept"
                    class="m-btn m-btn-primary"
                    (click)="accept()"
                    type="submit">
                {{options.acceptLabel | translate}}
            </button>
        </ng-container>
    </p-footer>
</p-dialog>