<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{'uploadDetailsDialog.title' | translate}}
    </p-header>

    <form *ngIf="dialogForm"
          [formGroup]="dialogForm">
        <div class="p-16 m-border-bottom">
            <div class="m-form-group">
                <div class="flex flex-wrap">
                    <a *ngIf="canDownloadPulsesFile"
                       class="m-btn m-btn-default"
                       href="{{upload.url}}">
                        <app-svg-icon icon="download"></app-svg-icon>
                        {{'uploadDetailsDialog.downloadPulsesFile' | translate}}
                    </a>

                    <app-icon-button *ngIf="canDownloadAnalysisFile"
                                     styleClass="m-btn m-btn-default"
                                     class="ml-8"
                                     icon="download"
                                     label="uploadDetailsDialog.downloadAnalysisFile"
                                     [download]="true"
                                     [showSpinner]="downloadingAnalysis"
                                     (click)="downloadAnalysis()"></app-icon-button>
                </div>
            </div>
        </div>

        <div class="alert alert-error"
             *ngIf="upload?.processMessage">{{upload.processMessage}}</div>
    </form>

    <p-footer>
        <input type="button"
               class="m-btn m-btn-primary"
               value="{{'form.cancel' | translate}}"
               (click)="close()">
    </p-footer>
</p-dialog>