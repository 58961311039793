import { HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { DownloadedFile, DownloadFileService } from "@ramudden/services";
import { AuditTrailCreator, AuditTrailExportOptions, IAuditTrail } from "../models/audit-trail";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class AuditTrailsApi extends ApiService<IAuditTrail, AuditTrailCreator, AuditTrailCreator> {
    private readonly downloadFileService = inject(DownloadFileService);

    override getRoute(): string {
        return "{?type}/{?id}/AuditTrails/";
    }

    public getAuditTrailsExportToExcel(auditTrailExportOptions: AuditTrailExportOptions): Promise<DownloadedFile> {
        const url = `${super.getUrl()}ExportToExcel`;

        const routeParams: { [key: string]: string } = {};
        routeParams["objectId"] = auditTrailExportOptions.objectId.toString();
        routeParams["type"] = auditTrailExportOptions.type.toString();

        const httpParams = new HttpParams({ fromObject: routeParams });
        return this.downloadFileService.downloadBlob(url, null, null, httpParams);
    }
}
