import { ExistsResult, ExistsValue } from "../models/search";
import { ApiService } from "./api";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { GroupCreator, GroupUpdater, IGroup } from "../models/group";
import { IDateInfoCollection } from "../models/date-info";

@Injectable({
    providedIn: "root"
})
export class GroupApi extends ApiService<IGroup, GroupCreator, GroupUpdater> {

    getRoute(): string {
        return "Groups";
    }

    exists$(existsValue: ExistsValue): Observable<ExistsResult> {
        const url = `${super.getUrl()}/Exists`;

        if (!existsValue.ownerId) return this.http.get<ExistsResult>(url, { params: { code: existsValue.value } });

        return this.http.get<ExistsResult>(url, { params: { code: existsValue.value, ownerId: existsValue.ownerId.toString() } });
    }

    getDataDates$(groupId: number): Observable<IDateInfoCollection> {
        const url = `${super.getUrl()}/${groupId}/Dates`;
        return this.handleCaching(url);
    }
}