import { ValidatorFn, AbstractControl } from "@angular/forms";

export class OneOrNothingRequiredValidator {
    static loopingOverControls: boolean;

    static create(controls: AbstractControl[], includeDisabled = false): ValidatorFn {
        return (c: AbstractControl) => {

            const relevantControls = (includeDisabled ? controls.filter(x => x.value) : controls.filter(x => x.enabled && x.value));

            if (!this.loopingOverControls) {
                this.loopingOverControls = true;
                relevantControls.filter(x => x !== c).forEach(x => x.updateValueAndValidity());
                this.loopingOverControls = false;
            }

            // Nothing or 1 filled in => no error
            if (relevantControls.length === 0 || relevantControls.length === 1) return null;

            return { chooseOne: true };
        };
    }
}