<p-dialog [(visible)]="visible"
          [modal]="blockBackground"
          [draggable]="draggable"
          [dismissableMask]="dismissOnClickOutside"
          [minX]="minX"
          [minY]="minY"
          [contentStyle]="contentStyle"
          [appendTo]="appendTo"
          (onShow)="onShow()"
          (onHide)="onHide()">

    <p-header>
        {{"measuringPointDataDeleteDialog.title" | translate:measuringPoint}}
    </p-header>

    <form *ngIf="dataDeleteForm"
          class="p-16"
          [formGroup]="dataDeleteForm"
          autocomplete="off"
          novalidate>

        <div class="m-form-group row mb-16"
             formGroupName="rangeDeleteForm">

            <div class="col-12 mb-16">
                <label (click)="setDateForm(rangeDeleteForm)">
                    <input type="radio"
                           class="m-input-radio"
                           [checked]="rangeDeleteForm.enabled">
                    {{"measuringPointDataDeleteDialog.rangeTitle" | translate}}
                </label>
            </div>

            <div class="col-6">
                <div class="row"
                     [ngClass]="{ 'has-error': rangeDeleteForm.get('from')?.showErrors }">

                    <label for="from"
                           class="col-4">
                        {{"general.from" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar id="from"
                                    formControlName="from"
                                    autoWidth="false"
                                    [showIcon]="true"
                                    [maxDate]="rangeDeleteForm.get('to')?.value"
                                    [hourFormat]="calendarSettings.hourFormat"
                                    [dateFormat]="calendarSettings.dateFormat"
                                    [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                    [showTime]="true"
                                    [monthNavigator]="false"
                                    [yearNavigator]="false"
                                    [yearRange]="primeComponentService.calendarYearRange"
                                    #fromInput>
                            <ng-template pTemplate="date"
                                         let-date>
                                <span [ngClass]="dataDaysService.getCssStyle(date)">
                                    {{date.day}}
                                </span>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <app-legend [configuration]="primeComponentService.calendarFooterConfiguration()"></app-legend>
                            </ng-template>
                        </p-calendar>

                        <app-form-validation [control]="rangeDeleteForm.get('from')"
                                             [input]="fromInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="row"
                     [ngClass]="{ 'has-error': rangeDeleteForm.get('to')?.showErrors }">

                    <label for="to"
                           class="col-4">
                        {{"general.until" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar id="to"
                                    formControlName="to"
                                    autoWidth="false"
                                    [showIcon]="true"
                                    [minDate]="rangeDeleteForm.get('from')?.value"
                                    [hourFormat]="calendarSettings.hourFormat"
                                    [dateFormat]="calendarSettings.dateFormat"
                                    [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                    [showTime]="true"
                                    [monthNavigator]="false"
                                    [yearNavigator]="false"
                                    [yearRange]="primeComponentService.calendarYearRange"
                                    #toInput>
                            <ng-template pTemplate="date"
                                         let-date>
                                <span [ngClass]="dataDaysService.getCssStyle(date)">
                                    {{date.day}}
                                </span>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <app-legend [configuration]="primeComponentService.calendarFooterConfiguration()"></app-legend>
                            </ng-template>
                        </p-calendar>

                        <app-form-validation [control]="rangeDeleteForm.get('to')"
                                             [input]="toInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>
        </div>

        <div class="m-form-group row"
             formGroupName="timeDeleteForm">
            <div class="col-12 mb-16">
                <label (click)="setDateForm(timeDeleteForm)">
                    <input type="radio"
                           class="m-input-radio"
                           [checked]="timeDeleteForm.enabled">
                    {{"measuringPointDataDeleteDialog.timeTitle" | translate}}
                </label>
            </div>

            <div class="col-3">
                <div class="row"
                     [ngClass]="{ 'has-error': timeDeleteForm.get('from')?.showErrors }">

                    <label class="col-4"
                           for="from">
                        {{"general.from" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar id="from"
                                    formControlName="from"
                                    autoWidth="false"
                                    [showIcon]="true"
                                    [maxDate]="timeDeleteForm.get('to')?.value"
                                    [dateFormat]="calendarSettings.dateFormat"
                                    [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                    [monthNavigator]="false"
                                    [yearNavigator]="false"
                                    [yearRange]="primeComponentService.calendarYearRange"
                                    #fromInput>
                            <ng-template pTemplate="date"
                                         let-date>
                                <span [ngClass]="dataDaysService.getCssStyle(date)">
                                    {{date.day}}
                                </span>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <app-legend [configuration]="primeComponentService.calendarFooterConfiguration()"></app-legend>
                            </ng-template>
                        </p-calendar>

                        <app-form-validation [control]="timeDeleteForm.get('from')"
                                             [input]="fromInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-3">
                <div class="m-form-group row"
                     [ngClass]="{ 'has-error': timeDeleteForm.get('to')?.showErrors }">

                    <label class="col-4"
                           for="to">
                        {{"general.until" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar id="to"
                                    formControlName="to"
                                    autoWidth="false"
                                    [showIcon]="true"
                                    [minDate]="timeDeleteForm.get('from')?.value"
                                    [dateFormat]="calendarSettings.dateFormat"
                                    [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                    [monthNavigator]="false"
                                    [yearNavigator]="false"
                                    [yearRange]="primeComponentService.calendarYearRange"
                                    #toInput>
                            <ng-template pTemplate="date"
                                         let-date>
                                <span [ngClass]="dataDaysService.getCssStyle(date)">
                                    {{date.day}}
                                </span>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <app-legend [configuration]="primeComponentService.calendarFooterConfiguration()"></app-legend>
                            </ng-template>
                        </p-calendar>

                        <app-form-validation [control]="timeDeleteForm.get('to')"
                                             [input]="toInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-3">
                <div class="row"
                     [ngClass]="{ 'has-error': timeDeleteForm.get('start')?.showErrors }">

                    <label class="col-4"
                           for="start">
                        {{"general.from" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar id="start"
                                    formControlName="start"
                                    autoWidth="false"
                                    [showIcon]="true"
                                    [hourFormat]="calendarSettings.hourFormat"
                                    [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                    [timeOnly]="true"
                                    #startInput>
                        </p-calendar>

                        <app-form-validation [control]="timeDeleteForm.get('start')"
                                             [input]="startInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-3">
                <div class="row"
                     [ngClass]="{ 'has-error': timeDeleteForm.get('end')?.showErrors }">

                    <label class="col-4"
                           for="end">
                        {{"general.until" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar id="end"
                                    formControlName="end"
                                    autoWidth="false"
                                    [showIcon]="true"
                                    [hourFormat]="calendarSettings.hourFormat"
                                    [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                                    [timeOnly]="true"
                                    #endInput>
                        </p-calendar>

                        <app-form-validation [control]="timeDeleteForm.get('end')"
                                             [input]="endInput">
                        </app-form-validation>
                    </div>
                </div>
            </div>

        </div>
    </form>

    <p-footer>
        <button class="m-btn m-btn-secondary"
                [disabled]="submitting"
                (click)="close()"
                type="button">
            {{"form.cancel" | translate}}
        </button>

        <button [disabled]="submitting"
                class="m-btn m-btn-primary"
                (click)="submit()"
                type="submit">
            <app-loading-spinner *ngIf="submitting">
            </app-loading-spinner>
            {{"form.delete" | translate}}
        </button>
    </p-footer>
</p-dialog>