import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Observable, of } from "rxjs";

export class NoWhiteSpaceValidator {
    static validate(control: AbstractControl): Observable<ValidationErrors | null> {
        const isWhitespace = (control.value || "").trim().length === 0;
        const isValid = !isWhitespace;
        if (!isValid) {
            // Emit an object with a validation error.
            return of({ "whitespace": true });
        }
        // Emit null, to indicate no error occurred.
        return of(null);
    }

}