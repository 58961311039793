<div #dropDown
     [ngStyle]="ngStyle"
     class="p-element p-inputwrapper m-2lvl-select">
    <div (click)="!disabled && toggleExpanded()"
         class="p-component p-dropdown m-2lvl-select-button"
         #header
         [ngStyle]="ngStyleHeader">
        <span class="p-dropdown-label p-inputtext">
            {{selectedFirstLevel?.label}} {{wordBetweenOptionsTranslation | translate}} {{selectedSecondLevel?.label | lowercase}}
        </span>

        <div class="p-dropdown-trigger"
             style="width:2rem;">
            <span class="p-dropdown-trigger-icon pi pi-chevron-down"></span>
        </div>
    </div>
</div>
<div #dropDownPart
     style="display: none;"
     [ngStyle]="ngStyleExpandablePart"
     class="p-dropdown-panel p-component m-2lvl-select-dropdown">

    <div class="p-dropdown-items-wrapper m-2lvl-select-dropdown-wrapper flex">
        <!-- First level -->
        <ul class="m-2lvl-select-lvl1 p-dropdown-items"
            style="width: 100%;"
            *ngIf="firstLevelOptions">
            <li *ngFor="let firstLevelOption of firstLevelOptions"
                (click)="!disabled && handleFirstLevelSelect(firstLevelOption); $event.stopPropagation();"
                class="p-element p-dropdown-item"
                [ngClass]="firstLevelOption === selectedFirstLevel ? ['p-highlight'] : ['']">
                {{firstLevelOption.label}}
            </li>
        </ul>

        <!-- Second Level -->
        <ul class="m-2lvl-select-lvl2 p-dropdown-items"
            style="width: 100%;"
            *ngIf="secondLevelOptions">
            <li *ngFor="let secondLevelOption of secondLevelOptions"
                class="p-element p-dropdown-item"
                [ngClass]="secondLevelOption === selectedSecondLevel ? ['p-highlight'] : (secondLevelOption.disabled ? ['p-disabled'] : ['item-with-cursor-pointer','not-selected-item'])"
                (click)="!disabled && handleSecondLevelSelect(secondLevelOption); $event.stopPropagation();">
                {{secondLevelOption.label}}
            </li>
        </ul>
    </div>
</div>