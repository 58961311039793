import { IAttachment } from "@ramudden/data-access/models/attachment";
import { FileUtils } from "./file-utils";

export class AttachmentUtils {
    static processLoadedAttachments(attachments: IAttachment[]) {
        if (!attachments) return;

        for (const attachment of attachments) {
            const isPhoto = FileUtils.isPhotoUrl(attachment.url);
            attachment.preview = isPhoto ? attachment.url : FileUtils.getPreviewFromFilename(attachment.url);
        }
    }
}
