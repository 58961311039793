import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-json-editor",
    templateUrl: "./json-editor.component.html"
})
export class JsonEditorComponent {
    @Input() model: any;
    @Output() modelChange = new EventEmitter<any>();

    jsonData: string;
    isValidJson = true;
    isEditing = false;

    onInputChange(value: string): void {
        try {
            JSON.parse(value);
            this.isValidJson = true;
        } catch (error) {
            this.isValidJson = false;
        }
    }

    editObject(): void {
        this.jsonData = JSON.stringify(this.model, null, 2);
        this.isEditing = true;
    }

    updateObject(): void {
        if (this.isValidJson) {
            this.model = JSON.parse(this.jsonData);
            this.isEditing = false;
            this.modelChange.emit(this.model);
        }
    }

    getLineCount(): number {
        const lines = this.jsonData.split(/\r\n|\r|\n/);
        return lines.length;
    }
}