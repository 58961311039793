<div class="m-snackbar"
     #headerHandle
     [ngClass]="{'is-expanded': expanded}"
     ngDraggable
     [handle]="headerHandle"
     [preventDefaultEvent]="true"
     *ngIf="visible">
  <div class="m-snackbar-header">
    <div class="px-16 py-8 row align-center">
      <div class="col-3">
        <h4>{{"progress.all" | translate}} ({{files.length + actions.length}})</h4>

        <!-- <span>{{uploadFileService.currentFile?.name}}</span>

                    <ng-container *ngIf="progressService.isWorking()">
                        <ng-container *ngFor="let action of getActiveActions(); trackBy: progressActionTrackByFn">
                            <span>{{getActionLabel(action)}} {{action.lastProgress?.isPending ? '('+("reports.progress.pending" | translate)+')' : ''}}</span>
                        </ng-container>
                    </ng-container> -->
      </div>

      <div class="col-6">
        <p-progressBar [value]="totalProgress"></p-progressBar>
      </div>

      <div class="col-3">
        <div class="flex align-center">
          <div class="m-btn-group m-btn-group-right align-center ml-auto">
            <a (click)="toggleExpand()"
               class="m-btn m-btn-square">
              <app-svg-icon [icon]="expanded ? 'triangle-up' : 'triangle-down'"
                            [fill]="hasError && !expanded ? 'red' : null">
              </app-svg-icon>
            </a>

            <a (click)="close()"
               *ngIf="canClose()"
               class="ml-8">
              <app-svg-icon icon="close"
                            tooltip="{{'form.close' | translate}}">
              </app-svg-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="m-snackbar-body px-16 py-8"
       *ngIf="expanded">
    <div class="m-progressbar-item row align-center"
         *ngFor="let file of files">
      <span class="col-3">
        {{file.name}}
      </span>

      <div class="col-6">
        <p-progressBar [value]="file.progress"></p-progressBar>
      </div>

      <div class="col-3">
        <div class="flex align-center">
          <app-loading-spinner *ngIf="!file.completed && !file.error && file === uploadFileService.currentFile"
                               class="mr-8">
          </app-loading-spinner>

          <a (click)="removeFile(file)"
             *ngIf="file.error || file.completed">
            <app-svg-icon icon="delete"
                          tooltip="{{'form.delete' | translate}}">
            </app-svg-icon>
          </a>

          <app-svg-icon *ngIf="file.completed"
                        icon="checkmark"
                        fill="green"
                        class="ml-8">
          </app-svg-icon>

          <app-svg-icon *ngIf="file.error"
                        icon="error"
                        fill="red"
                        [tooltip]="file.errorString"
                        class="ml-8">
          </app-svg-icon>

          <a (click)="retryUpload(file)"
             *ngIf="file.error"
             class="ml-8">
            <app-svg-icon *ngIf="file.error"
                          icon="undo"
                          tooltip="{{'general.retry' | translate}}">
            </app-svg-icon>
          </a>
        </div>
      </div>

    </div>

    <div class="m-progressbar-item row align-center"
         *ngFor="let action of actions; trackBy: progressActionTrackByFn">

      <span class="col-3">
        {{getActionLabel(action)}} {{action.lastProgress?.isPending ? '('+("reports.progress.pending" | translate)+')' : ''}}
      </span>

      <div class="col-6">
        <p-progressBar [value]="action.lastProgress?.progress"></p-progressBar>
      </div>

      <div class="col-3">
        <div class="flex align-center">
          <app-loading-spinner *ngIf="isActionWorking(action)"
                               tooltip="{{action.lastProgress?.message | translate}}"
                               class="mr-8">
          </app-loading-spinner>

          <a (click)="cancelAction(action)"
             *ngIf="!action.lastProgress?.isDone && !action.lastProgress?.hasError">
            <app-svg-icon icon="cancel"
                          tooltip="{{'form.cancel' | translate}}">
            </app-svg-icon>
          </a>

          <a (click)="removeAction(action)"
             *ngIf="action.lastProgress?.hasError || action.lastProgress?.isDone">
            <app-svg-icon icon="delete"
                          tooltip="{{'form.delete' | translate}}">
            </app-svg-icon>
          </a>

          <app-svg-icon *ngIf="action.lastProgress?.isDone"
                        icon="checkmark"
                        fill="green"
                        class="ml-8">
          </app-svg-icon>

          <app-svg-icon *ngIf="action.lastProgress?.hasError"
                        icon="error"
                        fill="red"
                        class="ml-8"
                        [tooltip]="action.lastProgress?.message">
          </app-svg-icon>
        </div>
      </div>
    </div>
    <div class="m-anchor"
         [innerHTML]="('reports.historyReportsMessage' | translate:{ historyReportsUrl: historyReportsUrl}) | safeHtml"></div>
  </div>
</div>