<p-table id="audit_table"
         [value]="data"
         [columns]="relevantColumns"
         [rows]="internalRowCount"
         [styleClass]="styleClass"
         [loading]="loading"
         [totalRecords]="total"
         [paginator]="paginator"
         [pageLinks]="pageLinks"
         [scrollable]="scrollable"
         scrollHeight="flex"
         [lazy]="isLazy"
         (onLazyLoad)="loadTableRows($event)"
         [sortOrder]="-1"
         [selectionMode]="selectionMode"
         [metaKeySelection]="true"
         dataKey="id"
         sortField="timeStamp"
         #tableRef>

    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td [attr.colspan]="relevantColumns.length"
                class="m-table-empty-message">
                {{"table.empty" | translate}}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="paginatorright">
        <app-loading-spinner *ngIf="isExportInProgress"></app-loading-spinner>
        <button type="button"
                class="m-btn m-btn-secondary"
                style="background-color: transparent;"
                (click)="exportToExcel()"
                *ngIf="measuringPoint || device || organization || project">
            <app-svg-icon icon="excel">
            </app-svg-icon>
            {{"table.exportExcel" | translate}}
        </button>
        <app-icon-button icon="refresh"
                         (click)="reload()"></app-icon-button>
    </ng-template>

    <ng-template pTemplate="summary"
                 *ngIf="footer && !paginator">
        <ng-container *ngIf="total">
            {{"table.total" | translate}}: {{total}}
        </ng-container>
    </ng-template>

    <ng-template pTemplate="header"
                 *ngIf="headers">
        <tr>
            <th *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!column.sortable"
                [pTooltip]="(column.tooltip ? (column.tooltip | translate) : '')">

                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{column.header | translate}}
                        </span>

                        <p-sortIcon *ngIf="column.sortable"
                                    [field]="column.field"></p-sortIcon>

                        <app-table-filter *ngIf="filter"
                                          class="m-column-filter"
                                          [column]="column"
                                          [table]="table"></app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body"
                 let-auditTrail
                 let-rowIndex="rowIndex"
                 let-expanded="expanded">

        <tr [pSelectableRow]="auditTrail"
            [pSelectableRowIndex]="rowIndex">

            <td *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="{'width': ['commands', 'expand'].includes(column.field) ? '0' : null}"
                [ngSwitch]="column.field">

                <ng-container *ngSwitchCase="'expand'">
                    <a [pRowToggler]="auditTrail">
                        <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                    </a>
                </ng-container>

                <ng-container *ngSwitchCase="'userId'">
                    {{ auditTrail?.creator?.email ?? '' }}
                </ng-container>

                <ng-container *ngSwitchCase="'organization'">
                    <app-navigator *ngIf="auditTrail.ownerId"
                                   [organizationId]="auditTrail.ownerId">
                    </app-navigator>
                </ng-container>

                <ng-container *ngSwitchCase="'relatedObjectType'">
                    <app-domain-data-field [enumKey]="getRelatedObject(auditTrail)"
                                           domainDataType="auditTrailRelatedObjectType">
                    </app-domain-data-field>
                </ng-container>

                <ng-container *ngSwitchCase="'relatedObjectId'">
                    <ng-template #emptyTemplate>
                        {{ auditTrail?.relatedObjectId ?? '' }}
                    </ng-template>
                    <ng-container *ngIf="auditTrail.device || auditTrail.measuringPoint || auditTrail.location || auditTrail.user || auditTrail.organizationId; else emptyTemplate">
                        <app-navigator [device]="auditTrail.device"
                                       [measuringPoint]="auditTrail.measuringPoint"
                                       [location]="auditTrail.location"
                                       [user]="auditTrail.user"
                                       [organizationId]="auditTrail.organizationId">
                        </app-navigator>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'timeStamp'">
                    {{ auditTrail.timeStamp | momentDateTime:true:true:translateService.currentLang }}
                </ng-container>

                <ng-container *ngSwitchCase="'eventType'">
                    <app-domain-data-field [enumKey]="auditTrail.eventType"
                                           domainDataType="auditTrailEventType">
                    </app-domain-data-field>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{auditTrail[column.field]}}
                </ng-container>
            </td>
        </tr>
    </ng-template>

    <!-- Row Details -->
    <ng-template pTemplate="rowexpansion"
                 let-auditTrail
                 let-columns="columns">

        <tr>
            <td [attr.colspan]="columns.length">
                <app-audit-trail-details [auditTrail]="auditTrail"
                                         style="width:100%;"></app-audit-trail-details>
            </td>
        </tr>
    </ng-template>
</p-table>