import { Duration } from "moment";
import { IMeasuringPointSummary } from "./web/measuring-point-summary";

export enum ReportDirection {
    Forward = "forward",
    Reverse = "reverse",
    Sum = "sum"
}

export interface IReportingFilter {
    allowMultiple: boolean;
    isRequired: boolean;
}

export interface IMeasuringPointsFilter extends IReportingFilter {
    list: Array<IMeasuringPointFilter>;
    groupIds: Array<number>;
    hideDirectionFilters: boolean;
    allowedDirections?: ReportDirection[];
    useOnlyGroups: boolean;
    useProjectsFilter: boolean;
    projectIds: number[];
}

export interface IScenariosFilter extends IReportingFilter {
    ids: number[];
}

export interface IOrganizationsFilter extends IReportingFilter {
    ids: Array<number>;
}

export interface IDevicesFilter extends IReportingFilter {
    ids: Array<number>;
}

export interface IGroupsFilter extends IReportingFilter {
    ids: Array<number>;
}

export interface IProjectsFilter extends IReportingFilter {
    ids: Array<number>;
}

export interface IDateRange {
    from: Date | string; // We work with Date but send string to server
    until: Date | string;
}

export interface IYearRange {
    from: number;
    until?: number;
}

export interface IMonthsFilter {
    months: Array<Date | string>;
}

export interface IIncludeDays {
    range: IDateRange;
    yearRange: IYearRange;
    monthsFilter: IMonthsFilter;
    dates: Array<Date | string>;
    calculated: string;
    showCalculated: boolean;
    showDates: boolean;
}

export interface IExcludeDays {
    excludeMondays: boolean;
    excludeTuesdays: boolean;
    excludeWednesdays: boolean;
    excludeThursdays: boolean;
    excludeFridays: boolean;
    excludeSaturdays: boolean;
    excludeSundays: boolean;

    excludeHolidays: boolean;
    excludeVacations: boolean;
    excludeBridgeDays: boolean;
    excludeExceptionalDays: boolean;
    excludePredictedDays: boolean;

    excludeNormalDays: boolean;
}

export interface IDateFilter extends IReportingFilter {
    include: IIncludeDays;
    exclude: IExcludeDays;
}

export interface IReportingFormat {
    fileFormats: string[];
    supportedFileFormats: string[];
}

export interface ITimeAggregation {
    typeId: string;
}

export interface IDayAverage {
    includeAverageDay: boolean;
    includeAllDays: boolean;
    includeTotalDay: boolean;

    canIncludeAverageDay: boolean;
    canIncludeAllDays: boolean;
    canIncludeTotalDay: boolean;
}

export interface ISpeedOptions {
    v50T16: boolean;
    v85T16: boolean;
    v95T16: boolean;

    v50T24: boolean;
    v85T24: boolean;
    v95T24: boolean;
}

export interface IVehiclesAmountOptions {
    t16: boolean;
    t24: boolean;
}

export interface IPeakHourOptions {
    morning: boolean;
    afternoon: boolean;
    totalDay: boolean;
}


export interface IXPosOptions {
    // TODO (TBD) not used
    maxXCm: number;
}

export interface IJournalOptions {
    includeJournals: boolean;
}

export interface IReportingOptions {
    xPos: IXPosOptions;
    timeAggregation: ITimeAggregation;
    dayAverage: IDayAverage;
    journals: IJournalOptions;
    device: IDeviceOptions;
    speed: ISpeedOptions;
    vehiclesAmount: IVehiclesAmountOptions;
    peakHour: IPeakHourOptions;
    originDestination: IOriginDestinationOptions;
    vehicleGrouping: IVehicleGroupingOptions;
}

export interface IVehicleCategoryFilter {
    excludeLight: boolean;
    excludeMedium: boolean;
    excludeHeavy: boolean;
    excludeBike: boolean;
    excludeMoped: boolean;
    excludeMotorcycle: boolean;
    excludePedestrian: boolean;
    excludeUnknown: boolean;
}

export interface IDeviceOptions {
    includeHistoricalData: boolean;
}

export enum OriginDestinationDirection {
    Destination = "destination",
    Origin = "origin"
}

export enum MatchMode {
    All = "all",
    OnlyFirst = "onlyFirst",
    OnlyLast = "onlyLast"
}

export interface IOriginDestinationOptions {
    direction: OriginDestinationDirection;
    matchMode: MatchMode;
    showAllDetections: boolean;
    includeNoMatch: boolean;
    travelTime: ITravelTimeOptions;
    trajectory: ITrajectoryOptions;
}

export interface ITravelTimeOptions {
    minimum: Duration;
    maximum: Duration;
    removeOutliers: boolean;
    outlierConfiguration: IOutlierConfiguration[];
}

export interface IOutlierConfiguration {
    median: Duration;
    delta: Duration;
}

export interface ITrajectoryOptions {
    useWaypoints: boolean;
    waypoints: string;
}

export interface IVehicleGroupingOptions {
    showAllGroups: boolean;
    maxTimeBetween?: Duration;
    maxDistanceBetween?: number;
    minVehiclesInGroup: number;
    minSpeedFirstVehicle: number;
    maxSpeedFirstVehicle: number;
}

export interface IAlarmFilter {
    excludeNoAlarm: boolean;
    excludeLow: boolean;
    excludeHigh: boolean;
}

export interface IExpenseStatus {
    excludeNotBilled: boolean;
    excludeBilled: boolean;
    excludePaid: boolean;
}

export interface IMeteoFilter {
    meteoFactorBikeMin?: number;
    meteoFactorBikeMax?: number;

    meteoFactorCarMin?: number;
    meteoFactorCarMax?: number;

    temperatureCelsiusMin?: number;
    temperatureCelsiusMax?: number;

    windchillCelsiusMin?: number;
    windchillCelsiusMax?: number;

    windKilometresPerHourMin?: number;
    windKilometresPerHourMax?: number;

    precipitationMillimetresMin?: number;
    precipitationMillimetresMax?: number;
}

export interface IReportFilters {
    date: IDateFilter;
    measuringPoints: IMeasuringPointsFilter;
    groups: IGroupsFilter;
    organizations: IOrganizationsFilter;
    projects: IProjectsFilter;
    devices: IDevicesFilter;
    vehicleCategory: IVehicleCategoryFilter;
    alarm: IAlarmFilter;
    meteo: IMeteoFilter;
    expenseStatus: IExpenseStatus;
    scenarios: IScenariosFilter;
}

export interface IReportingParameters {
    reportTypeId: string;
    filter: IReportFilters;
    format: IReportingFormat;
    options: IReportingOptions;
}

export interface IReportTypeDescription {
    defaultParameters: IReportingParameters;
    documentationPath: string;
}

export interface IMeasuringPointFilter {
    id: number;
    measuringPoint: IMeasuringPointSummary;
    includeForwardDirection: boolean;
    includeReverseDirection: boolean;
    includeSum: boolean;
}
