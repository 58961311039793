import { Directive, ElementRef, EventEmitter, HostListener, NgZone, OnDestroy, OnInit, Output, Renderer2 } from "@angular/core";

@Directive({
    selector: "[appFileDragAndDropOutsideNgZone]"
})
export class FileDragAndDropOutsideNgZoneDirective implements OnInit, OnDestroy {

    @Output() fileDroppedOutsideNgZone = new EventEmitter<FileList>();
    private counter = 0;

    private dropTeardownLogicFn: Function;
    private dragOverTeardownLogicFn: Function;
    private dragLeaveTeardownLogicFn: Function;
    private dragEnterTeardownLogicFn: Function;


    constructor(
        private el: ElementRef,
        private ngZone: NgZone,
        private renderer: Renderer2) {

    }

    ngOnDestroy(): void {
        this?.dropTeardownLogicFn();
        this?.dragOverTeardownLogicFn();
        this?.dragLeaveTeardownLogicFn();
        this?.dragEnterTeardownLogicFn();
    }
    ngOnInit(): void {
        this.ngZone.runOutsideAngular(() => {
            this.setupListeners();
        });
    }

    setupListeners() {
        this.dragEnterTeardownLogicFn = this.renderer.listen(
            this.el.nativeElement,
            "dragenter",
            (event: DragEvent) => {
                event.preventDefault();
                event.stopPropagation();

                this.counter++;
                (<HTMLElement>this.el.nativeElement).classList.add("drag-over");
            }
        );

        this.dragOverTeardownLogicFn = this.renderer.listen(
            this.el.nativeElement,
            "dragover",
            (event: DragEvent) => {
                event.preventDefault();
                event.stopPropagation();
            }
        );

        this.dragLeaveTeardownLogicFn = this.renderer.listen(
            this.el.nativeElement,
            "dragleave",
            (event: DragEvent) => {
                event.preventDefault();
                event.stopPropagation();

                this.counter--;
                if (this.counter === 0) {
                    (<HTMLElement>this.el.nativeElement).classList.remove("drag-over");
                }
            }
        );


        this.dropTeardownLogicFn = this.renderer.listen(
            this.el.nativeElement,
            "drop",
            (event: DragEvent) => {
                event.preventDefault();
                event.stopPropagation();

                if (!event.dataTransfer?.files || event.dataTransfer.files.length === 0) {
                    return;
                }

                this.fileDroppedOutsideNgZone.emit(event.dataTransfer.files);
                this.counter = 0;
                (<HTMLElement>this.el.nativeElement).classList.remove("drag-over");
            }
        );
    }
}
