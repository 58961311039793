import { AfterContentInit, Component, ContentChild } from "@angular/core";

@Component({
    selector: "app-page",
    standalone: true,
    imports: [],
    templateUrl: "./page.component.html",
    styleUrl: "./page.component.scss"
})

export class PageComponent implements AfterContentInit {
    @ContentChild("header") header: HTMLElement;
    @ContentChild("main") main: HTMLElement;

    hasHeader = false;
    hasMain = false;

    ngAfterContentInit(): void {
        this.hasHeader = !!this.header;
        this.hasMain = !!this.main;
    }
}
