import { IOrganization, IUser } from "./user";
import { IServiceModelBase } from "./servicemodelbase";
import { ViewModelEnum } from "./domain-data";

export interface IApplication extends IServiceModelBase {
    clientId: string;
    b2CClientId: string;
    description: string;
    user: IUser;
    organization: IOrganization;
    rights: string[];
    rightViewModels: ViewModelEnum[];
}

export class ApplicationCreator {
    description: string;
    userId: number;
    rights: string[];
}

export class ApplicationUpdater extends ApplicationCreator {
    constructor(existing: IApplication) {
        super();
        this.id = existing.id;
        this.description = existing.description;
        this.userId = existing.user ? existing.user.id : undefined;
        this.rights = existing.rights;
    }

    id: number;
}

export class GroupedApplicationRights {
    groupedRights: { key: string, rights: string[] }[] =
        [
            {
                key: "attachments",
                rights: [
                    "viewAttachment",
                    "editAttachment",
                    "deleteAttachment"
                ]
            },
            {
                key: "deviceCommands",
                rights: [
                    "viewDeviceCommand",
                    "editDeviceCommand"
                ]
            }
        ];
}

export interface IClientCreatedResult {
    id: string;
    secret: string;
}