import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export class EventMeta {
    constructor(
        public owner: any,
        public func: Function,
        public param?: any,
        public paramOwner?: any) {
    }
}

@Injectable({
    providedIn: "root"
})
export class EventService {
    private bulkUpdating: boolean;
    private throttledEvents = new Array<EventMeta>();

    isBulkUpdating(): boolean {
        return this.bulkUpdating;
    }

    setIsBulkUpdating(value: boolean) {
        if (this.isBulkUpdating() === value) return;

        // On set to false, run through all events
        if (!value) {
            this.processThrottledEvents();
        }

        this.bulkUpdating = value;
    }

    processNext(subject: Subject<any>, param?: any, paramOwner?: any) {
        this.processEvent(subject, subject.next, param, paramOwner);
    }

    processEvent(owner: any, func: Function, param?: any, paramOwner?: any) {
        const eventMeta = new EventMeta(owner, func, param, paramOwner);

        if (this.isBulkUpdating()) {

            const existingEvent = this.throttledEvents.find(
                x => x.owner === owner &&
                    (x.func === func || ("" + x.func) === ("" + func)) // https://stackoverflow.com/questions/9817629/how-do-i-compare-2-functions-in-javascript
                    && x.param === param
            );

            if (!existingEvent) {
                this.throttledEvents.push(eventMeta);
            }
        } else {
            this.fireEvent(eventMeta);
        }
    }

    private processThrottledEvents() {
        while (this.throttledEvents.length) {
            const clonedThrottledEvents = this.throttledEvents.clone();
            this.throttledEvents = [];

            for (const event of clonedThrottledEvents) {
                this.fireEvent(event);
            }
        }
    }

    private fireEvent(throttledEvent: EventMeta) {
        if (throttledEvent.param instanceof Function) {
            throttledEvent.param = (throttledEvent.param as Function).call(throttledEvent.paramOwner);
        }

        throttledEvent.func.call(throttledEvent.owner, throttledEvent.param);
    }
}