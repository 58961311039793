@if (config?.title) {
    <h3 class="mb-8">{{ config.title }}</h3>
}

<div
    class="timeline"
    *ngIf="config"
    [style.--days]="days.length"
    [style.--items]="activities.length"
    [style.--zoom]="zoom + 'px'"
>
    <div class="timeline__head">
        <div class="activity__description"></div>

        <div
            class="activity__description"
            *ngFor="let activity of activities"
        >
            {{ activity.key }}
        </div>
    </div>

    <div
        class="timeline__body"
        #timelineBody
        [disableDrag]="false"
        appScrollDrag
    >
        <div class="units">
            <ng-container *ngFor="let day of [].constructor(days.length); let i = index">
                <div
                    class="unit"
                    *ngFor="let hour of [].constructor(24); let j = index"
                >
                    <h3
                        class="date"
                        *ngIf="j === 0"
                    >
                        {{ days[i] | date: "dd/MM/yyyy" }}
                    </h3>

                    @if (shouldShowHour(j)) {
                        <span class="time"> {{ j }}h </span>
                    }

                    <div class="unit__minutes">
                        <div
                            class="unit__minute"
                            *ngFor="let minute of [].constructor(60); let m = index"
                        ></div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div
            class="activity__grid"
            *ngFor="let activity of activities"
        >
            <div
                class="activity__grid__item"
                *ngFor="let data of activity.data"
                [pTooltip]="data.tooltip"
                [style.--color]="data.color"
                [style.--end]="data.endMinute"
                [style.--start]="data.startMinute"
            >
                <span>{{ data.tooltip }}</span>
            </div>
        </div>
    </div>
</div>
