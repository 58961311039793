<ng-template #navigatorContent>
    <span #content>
        <ng-content></ng-content>
    </span>
    @if (!content.hasChildNodes()) {
        {{ text }}
    }
</ng-template>

<ng-template #navigatorBody>
    @if (showIcon && icon) {
        <div class="flex align-center gap-4">
            <app-svg-icon [icon]="icon"
                          [divStyle]="{'width': '16px', 'height': '16px'}"></app-svg-icon>
            <ng-template [ngTemplateOutlet]="navigatorContent"></ng-template>
        </div>
    } @else {
        <ng-template [ngTemplateOutlet]="navigatorContent"></ng-template>
    }
</ng-template>

@if (enabled && (canNavigate || !targetNotReachable)) {
    <a [routerLink]="navigationUrl"
       [queryParams]="queryParams"
       [target]="newTab ? '_blank' : null"
       class="m-navigator"
       [ngStyle]="ngStyle"
       [ngClass]="{'is-inactive': isInactive}">
        <ng-template [ngTemplateOutlet]="navigatorBody"></ng-template>
    </a>
} @else {
    <span class="m-navigator is-disabled"
          [ngStyle]="ngStyle">
    <ng-template [ngTemplateOutlet]="navigatorBody"></ng-template>
</span>
}
