import { AbstractControl, ValidatorFn } from "@angular/forms";

export class FormUtils {
    static addValidator(control: AbstractControl, validator: ValidatorFn) {
        const curValidator = control.validator;
        if (!curValidator) {
            control.setValidators(validator);
        } else {
            control.setValidators([curValidator].concat(validator));
        }
    }
}